import React, { useEffect } from "react";

interface MessageListProps {
  messages: any[];
  chatEndRef: React.RefObject<HTMLDivElement>;
}

const MessageList: React.FC<MessageListProps> = ({ messages, chatEndRef }) => {
  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, chatEndRef]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "70vh", // Constrain height
        overflowY: "auto", // Enable scrolling
        padding: "16px",
      }}
    >
      {/* Message List */}
      {messages.map((msg) => (
        <div
          key={msg.id}
          style={{
            display: "flex",
            alignItems: "flex-start",
            gap: "12px",
            margin: "16px 0",
            justifyContent: msg.sender === "User" ? "flex-end" : "flex-start",
          }}
        >
          {/* Bot Response */}
          {msg.sender === "CoinCap" && (
            <>
              <img
                src="/external/cclogo.png"
                alt="CoinCap logo"
                style={{ width: "44px", height: "44px" }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                }}
              >
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "800",
                    color: "#190C33",
                    lineHeight: "24px",
                  }}
                >
                  CoinCap
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#190C33",
                    lineHeight: "24px",
                    whiteSpace: "pre-wrap", // Fix for newlines
                  }}
                >
                  {msg.text}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    fontSize: "12px",
                    fontWeight: "300",
                    color: "rgba(24.51, 12.15, 50.78, 0.48)",
                    lineHeight: "18px",
                  }}
                >
                  <div>
                    {new Date(msg.date).toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                    })}
                  </div>
                  <div>|</div>
                  <div>{msg.model}</div>
                  <div>|</div>
                  <div>{(msg.duration / 1000 || 0).toFixed(2)} sec</div>
                </div>
              </div>
            </>
          )}

          {/* User Message */}
          {msg.sender === "User" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                gap: "4px",
                marginBottom: "16px",
                width: "100%",
              }}
            >
              {/* User Message Bubble */}
              <div
                style={{
                  padding: "16px 24px",
                  background:
                    "linear-gradient(69deg, rgba(104.95, 123.76, 206.70, 0.80) 0%, rgba(95.36, 157.82, 231.14, 0.80) 100%)",
                  border: "1px solid rgba(104.95, 123.76, 206.70, 0.44)",
                  borderRadius: "16px",
                  maxWidth: "100%",
                  color: "white",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  wordWrap: "break-word",
                  alignSelf: "flex-end",
                  whiteSpace: "pre-wrap", // Fix for newlines
                }}
              >
                {msg.text}
              </div>

              {/* Date Below Message */}
              <div
                style={{
                  fontSize: "12px",
                  color: "rgba(24, 12, 51, 0.48)",
                  fontWeight: "300",
                  lineHeight: "18px",
                  alignSelf: "flex-end",
                }}
              >
                {new Date(msg.date).toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
                })}
              </div>
            </div>
          )}
        </div>
      ))}
      <div ref={chatEndRef} />
    </div>
  );
};

export default MessageList;
