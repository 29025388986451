import React from "react";
import { useModalState } from "../../helpers/ModalProvider";
import ModalWrapper from "./ModalWrapper";
import { useDisconnect } from "wagmi";

export const CannotConnectWalletModal: React.FC = () => {
  const { disconnect } = useDisconnect();
  // Access the new modal state from our provider:
  const { isCannotConnectWalletModalVisible, closeCannotConnectWalletModal } =
    useModalState();

  // If the modal is not visible, render nothing:
  if (!isCannotConnectWalletModalVisible) {
    return null;
  }

  const handleClose = () => {
    disconnect();
    closeCannotConnectWalletModal();
  };

  return (
    <ModalWrapper onBackdropClick={handleClose}>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1000,
        }}
        onClick={handleClose}
      >
        <div
          style={{
            width: 480,
            maxHeight: "90vh",
            padding: 24,
            overflowY: "auto",
            background: "#FFFFFF",
            boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.2)",
            borderRadius: 24,
            display: "flex",
            flexDirection: "column",
          }}
          // Prevent clicks inside from closing the modal
          onClick={(e) => e.stopPropagation()}
        >
          <div
            style={{
              color: "#190C33",
              fontSize: 24,
              fontWeight: 600,
              lineHeight: "32px",
              marginBottom: 16,
            }}
          >
            Cannot Connect Wallet
          </div>

          <div
            style={{
              color: "#190C33",
              fontSize: 16,
              lineHeight: "24px",
              marginBottom: 24,
            }}
          >
            We could not connect this wallet to your account. This could be
            because it is not the correct type of wallet, or this wallet is
            already associated with another account.
            <br />
            <br />
            Please try another wallet or disconnect this wallet from any
            existing accounts before connecting to this one.
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 16,
            }}
          >
            {/* Close button */}
            <button
              onClick={handleClose}
              style={{
                minWidth: 80,
                height: 42,
                padding: "0 20px",
                background: "#FFFFFF",
                borderRadius: 12,
                border: "1px solid #190C33",
                fontSize: 14,
                fontWeight: 500,
                color: "#190C33",
                cursor: "pointer",
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
