// ModalProvider.tsx
import React, { createContext, useContext, useState } from "react";

interface StakeModalDetails {
  amount: number;
  isUnstake: boolean;
}

// For the "delete auth" logic
type AuthMethodType = "email" | "wallet" | null;

// The shape of our ModalContext
interface ModalContextValue {
  // ============= Stake Modal ============
  isStakeModalVisible: boolean;
  setStakeModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleCloseStakeModal: () => void;
  stakeModalDetails: StakeModalDetails | null;
  setStakeModalDetails: React.Dispatch<
    React.SetStateAction<StakeModalDetails | null>
  >;
  showStakeModal: () => void;

  // ============= Delete Modal (API keys) ============
  isDeleteModalVisible: boolean;
  setDeleteModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  showDeleteModal: (apiKey: string, label: string) => void;

  // ============= Edit Modal (API keys) ============
  isEditModalVisible: boolean;
  setEditModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  showEditModal: (apiKey: string, label: string) => void;
  closeEditModal: () => void;

  // ============= "What Is" Modal ============
  isWhatIsModalVisible: boolean;
  setWhatIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;

  // ============= Common Modal Content (Delete/Edit) ============
  modalContent: { label: string; apiKey: string };
  setModalContent: React.Dispatch<
    React.SetStateAction<{ label: string; apiKey: string }>
  >;

  // ============= Delete Auth Modal ============
  isDeleteAuthModalVisible: boolean;
  deleteAuthType: AuthMethodType;
  deleteAuthIdentifier: string;
  showDeleteAuthModal: (type: "email" | "wallet", identifier: string) => void;
  closeDeleteAuthModal: () => void;

  // ============= Add Email Modal ============
  isAddEmailModalVisible: boolean;
  showAddEmailModal: () => void;
  closeAddEmailModal: () => void;

  // ============= Update Auth Method (email/password) Modal ============
  isUpdateAuthModalVisible: boolean;
  showUpdateAuthModal: () => void;
  closeUpdateAuthModal: () => void;

  // ============= Rename Chat Modal ============
  isRenameChatModalVisible: boolean;
  setIsRenameChatModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  renameChatId: number | null;
  renameChatValue: string;
  showRenameChatModal: (chatId: number, currentName: string) => void;
  closeRenameChatModal: () => void;
  setRenameChatValue: React.Dispatch<React.SetStateAction<string>>;
  renameChat: (chatId: number) => void;

  // ============= Delete Chat Modal ============
  isDeleteChatModalVisible: boolean;
  deleteChatId: number | null;
  deleteChatName: string;
  showDeleteChatModal: (chatId: number, chatName: string) => void;
  closeDeleteChatModal: () => void;

  // ============= Additional Chat States ============
  chatOptionsId: number | null;
  setChatOptionsId: React.Dispatch<React.SetStateAction<number | null>>;
  chatToRenameId: number | null;
  setChatToRenameId: React.Dispatch<React.SetStateAction<number | null>>;
  chatToDeleteId: number | null;
  setChatToDeleteId: React.Dispatch<React.SetStateAction<number | null>>;
  chatToDeleteName: string | null;
  setChatToDeleteName: React.Dispatch<React.SetStateAction<string | null>>;

  isCannotConnectWalletModalVisible: boolean;
  setCannotConnectWalletModalVisible: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  showCannotConnectWalletModal: () => void;
  closeCannotConnectWalletModal: () => void;

  // ============= Error Toast =============
  errorMessage: string | null;
  showErrorMessage: (message: string) => void;

  closeDeleteAccountModal: any
  isDeleteAccountModalVisible: boolean
  showDeleteAccountModal: () => void

  showResetPasswordModal: any
  isResetPasswordModalVisible: any
  closeResetPasswordModal: any

  isChainSelectionModalVisible: boolean
  setChainSelectionModalVisible: any

  isClaimBroadcastModalVisible: boolean
  setClaimBroadcastModalVisible: any

  isAprilMorUpgradeModalVisible: boolean
  setAprilMorUpgradeModalVisible: any
}

const ModalContext = createContext<ModalContextValue | null>(null);

export const ModalProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const value = useProvideModalState();
  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};

export const useModalState = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModalState must be used within a ModalProvider");
  }
  return context;
};

function useProvideModalState(): ModalContextValue {
  // ========== Stake Modal ==========
  const [isStakeModalVisible, setStakeModalVisible] = useState<boolean>(false);
  const [stakeModalDetails, setStakeModalDetails] =
    useState<StakeModalDetails | null>(null);

  const showStakeModal = () => {
    setStakeModalVisible(true);
    setClaimBroadcastModalVisible(false)
  };
  const handleCloseStakeModal = () => setStakeModalVisible(false);

  // ========== Delete Modal (API Keys) ==========
  const [isDeleteModalVisible, setDeleteModalVisible] =
    useState<boolean>(false);
  const showDeleteModal = (apiKey: string, label: string) => {
    setModalContent({ apiKey, label });
    setDeleteModalVisible(true);
  };

  // ========== Edit Modal (API Keys) ==========
  const [isEditModalVisible, setEditModalVisible] = useState<boolean>(false);
  const showEditModal = (apiKey: string, label: string) => {
    setModalContent({ apiKey, label });
    setEditModalVisible(true);
  };
  function closeEditModal() {
    setEditModalVisible(false);
  }
  const [isAprilMorUpgradeModalVisible, setAprilMorUpgradeModalVisible] = useState<boolean>(false);

  const [isChainSelectionModalVisible, setChainSelectionModalVisible] =
    useState<boolean>(false);

    const [isClaimBroadcastModalVisible, setClaimBroadcastModalVisible] =
    useState<boolean>(false);

    // ========== "What Is" Modal ==========
    const [isWhatIsModalVisible, setWhatIsModalVisible] =
    useState<boolean>(false);


  // ========== Common Modal Content ==========
  const [modalContent, setModalContent] = useState<{
    label: string;
    apiKey: string;
  }>({
    label: "Default Label",
    apiKey: "0x1234567890ABCDEF",
  });

  // ========== Delete Auth Modal (Settings) ==========
  const [isDeleteAuthModalVisible, setDeleteAuthModalVisible] =
    useState<boolean>(false);
  const [deleteAuthType, setDeleteAuthType] = useState<AuthMethodType>(null);
  const [deleteAuthIdentifier, setDeleteAuthIdentifier] = useState<string>("");

  const showDeleteAuthModal = (
    type: "email" | "wallet",
    identifier: string,
  ) => {
    setDeleteAuthType(type);
    setDeleteAuthIdentifier(identifier);
    setDeleteAuthModalVisible(true);
  };
  const closeDeleteAuthModal = () => {
    setDeleteAuthModalVisible(false);
  };

  const [isDeleteAccountModalVisible, setDeleteAccountModalVisible] = useState(false)
  const closeDeleteAccountModal = () => {
    setDeleteAccountModalVisible(false);
  };
  const showDeleteAccountModal = () => {
    setDeleteAccountModalVisible(true);
  };

  const [isResetPasswordModalVisible, setResetPasswordModalVisible] = useState(false)
  const closeResetPasswordModal = () => {
    setResetPasswordModalVisible(false);
  };
  const showResetPasswordModal = () => {
    setResetPasswordModalVisible(true);
  };

  // ========== Add Email Modal (Settings) ==========
  const [isAddEmailModalVisible, setAddEmailModalVisible] = useState(false);
  const showAddEmailModal = () => {
    setAddEmailModalVisible(true);
  };
  const closeAddEmailModal = () => {
    setAddEmailModalVisible(false);
  };

  // ========== Update Auth Method (email/password) Modal (Settings) ==========
  const [isUpdateAuthModalVisible, setUpdateAuthModalVisible] = useState(false);
  const showUpdateAuthModal = () => {
    setUpdateAuthModalVisible(true);
  };
  const closeUpdateAuthModal = () => {
    setUpdateAuthModalVisible(false);
  };

  // ========== Rename Chat Modal ==========
  const [isRenameChatModalVisible, setIsRenameChatModalVisible] =
    useState(false);
  const [renameChatId, setRenameChatId] = useState<number | null>(null);
  const [renameChatValue, setRenameChatValue] = useState("");

  const showRenameChatModal = (chatId: number, currentName: string) => {
    setRenameChatId(chatId);
    setRenameChatValue(currentName);
    setIsRenameChatModalVisible(true);
  };

  const closeRenameChatModal = () => {
    setIsRenameChatModalVisible(false);
    setRenameChatId(null);
    setRenameChatValue("");
  };

  const renameChat = (chatId: number) => {
    console.log(`Renaming chat ${chatId} to new name: ${renameChatValue}`);
    // do your real rename logic (e.g. update global chatHistory or call an API)
    closeRenameChatModal();
  };

  // ========== Delete Chat Modal ==========
  const [isDeleteChatModalVisible, setIsDeleteChatModalVisible] =
    useState(false);
  const [deleteChatId, setDeleteChatId] = useState<number | null>(null);
  const [deleteChatName, setDeleteChatName] = useState<string>("");

  function showDeleteChatModal(chatId: number, chatName: string) {
    setDeleteChatId(chatId);
    setDeleteChatName(chatName);
    setIsDeleteChatModalVisible(true);
  }

  function closeDeleteChatModal() {
    setIsDeleteChatModalVisible(false);
    setDeleteChatId(null);
    setDeleteChatName("");
  }

  // ========== Additional Chat State from ChatContainer ==========
  const [chatOptionsId, setChatOptionsId] = useState<number | null>(null);
  const [chatToRenameId, setChatToRenameId] = useState<number | null>(null);
  const [chatToDeleteId, setChatToDeleteId] = useState<number | null>(null);
  const [chatToDeleteName, setChatToDeleteName] = useState<string | null>(null);

  // ========== "Cannot Connect Wallet" Modal ==========
  const [
    isCannotConnectWalletModalVisible,
    setCannotConnectWalletModalVisible,
  ] = useState<boolean>(false);

  const showCannotConnectWalletModal = () => {
    setCannotConnectWalletModalVisible(true);
  };

  const closeCannotConnectWalletModal = () => {
    setCannotConnectWalletModalVisible(false);
  };

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const showErrorMessage = (message: string) => {
    setErrorMessage(message);

    // Automatically clear the error after 5 seconds
    setTimeout(() => {
      setErrorMessage(null);
    }, 5000);
  };

  return {
    // ===== Stake Modal =====
    isStakeModalVisible,
    setStakeModalVisible,
    handleCloseStakeModal,
    stakeModalDetails,
    setStakeModalDetails,
    showStakeModal,

    // ===== Delete Modal (API Keys) =====
    isDeleteModalVisible,
    setDeleteModalVisible,
    showDeleteModal,

    // ===== Edit Modal (API Keys) =====
    isEditModalVisible,
    setEditModalVisible,
    showEditModal,
    closeEditModal,

    // ===== "What Is" Modal =====
    isWhatIsModalVisible,
    setWhatIsModalVisible,

    // ===== Common Content (API keys) =====
    modalContent,
    setModalContent,

    // ===== Delete Auth Modal (Settings) =====
    isDeleteAuthModalVisible,
    deleteAuthType,
    deleteAuthIdentifier,
    showDeleteAuthModal,
    closeDeleteAuthModal,

    // ===== Add Email Modal (Settings) =====
    isAddEmailModalVisible,
    showAddEmailModal,
    closeAddEmailModal,

    // ===== Update Auth Method (Settings) =====
    isUpdateAuthModalVisible,
    showUpdateAuthModal,
    closeUpdateAuthModal,

    // ===== Rename Chat Modal =====
    isRenameChatModalVisible,
    setIsRenameChatModalVisible,
    renameChatId,
    renameChatValue,
    showRenameChatModal,
    closeRenameChatModal,
    setRenameChatValue,
    renameChat,

    // ===== Delete Chat Modal =====
    isDeleteChatModalVisible,
    deleteChatId,
    deleteChatName,
    showDeleteChatModal,
    closeDeleteChatModal,

    // ===== Additional Chat States =====
    chatOptionsId,
    setChatOptionsId,
    chatToRenameId,
    setChatToRenameId,
    chatToDeleteId,
    setChatToDeleteId,
    chatToDeleteName,
    setChatToDeleteName,

    isCannotConnectWalletModalVisible,
    setCannotConnectWalletModalVisible,
    showCannotConnectWalletModal,
    closeCannotConnectWalletModal,

    errorMessage,
    showErrorMessage,

    closeDeleteAccountModal,
    isDeleteAccountModalVisible,
    showDeleteAccountModal,

    closeResetPasswordModal,
    isResetPasswordModalVisible,
    showResetPasswordModal,

    isChainSelectionModalVisible,
    setChainSelectionModalVisible,
    isClaimBroadcastModalVisible,
    setClaimBroadcastModalVisible,

    isAprilMorUpgradeModalVisible,
    setAprilMorUpgradeModalVisible
  };
}

export default useProvideModalState;
