import React, { useState, useEffect } from "react";
import HeaderContainer from "./Header";
import { useNavigate } from "react-router-dom";
import { env, useGlobalState } from "../helpers/GlobalStateProvider"; // Ensure this hook is set up similarly to sign in page
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { apiUrls } from "../config";
import { useModalState } from "../helpers/ModalProvider";
import { useDisconnect, useWalletClient } from "wagmi";
import { migrateLocalChatHistory } from "./chatbot/chatHistoryStorage";

const CreateAccount: React.FC<any> = () => {
  const { registerWallet } = useGlobalState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [waitingForWallet, setWaitingForWallet] = useState(false); // New state
  const navigate = useNavigate();
  const { openConnectModal } = useConnectModal();

  const { showErrorMessage } = useModalState();
  const { disconnect } = useDisconnect();
  const { refreshAccountInfo, setBearerToken } = useGlobalState();

  const { data: walletClient } = useWalletClient();

  useEffect(() => {
    // Check that wallet is not null or undefined
    if (waitingForWallet && walletClient) {
      runWalletRegister();
    }
  }, [waitingForWallet, walletClient]);

  const handleCreateEmailAccount = async () => {
    // Check if the email is valid
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email validation regex
    if (!emailRegex.test(email)) {
      showErrorMessage("Please enter a valid email address.");
      return;
    }
  
    // Check if passwords match
    if (password !== confirmPassword) {
      showErrorMessage("Passwords do not match.");
      return;
    }
  
    try {
      const response = await fetch(`${apiUrls[env]}/v3/apiKey/email_register`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        const message = errorData.message || "Error creating account.";
        showErrorMessage(message);
        return;
      }
  
      const data = await response.json();
      console.log("Got bearer token:", data.bearerToken);
      localStorage.setItem("bearerToken", data.bearerToken);
      setBearerToken(data.bearerToken);
      migrateLocalChatHistory();
      navigate("/dashboard");
      refreshAccountInfo();
    } catch (error) {
      showErrorMessage("An unexpected error occurred. Please try again.");
    }
  };

  const runWalletRegister = async () => {
    setWaitingForWallet(false); // Reset the state now that we're trying to register
    try {
      await registerWallet();
    } catch (e) {
      disconnect();
      showErrorMessage(
        "Current Wallet already registered. You must register with a different wallet",
      );
    }
    const bearerToken = localStorage.getItem("bearerToken");
    if (bearerToken) navigate("/dashboard");
  };

  const handleWalletButtonClick = async () => {
    // If no wallet connected, open the modal and wait for the user to connect
    if (!walletClient) {
      openConnectModal?.();
      setWaitingForWallet(true);
    } else {
      // If already connected, just run the wallet registration
      runWalletRegister();
    }
  };

  useEffect(() => {
      disconnect();
  }, [])

  return (
    <div
      style={{
        minHeight: "100vh",
        width: "100%",
        maxWidth: "1392px",
        margin: "0 auto",
        paddingTop: "24px",
        paddingBottom: "24px",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
      }}
    >
      {/* Header */}
      <HeaderContainer behavior="signin" />

      {/* Main container to center the content */}
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
        }}
      >
        {/* Content wrapper for max width */}
        <div
          style={{
            maxWidth: "500px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "40px",
          }}
        >
          {/* Page title */}
          <h1
            style={{
              margin: 0,
              textAlign: "center",
              color: "#181432CC",
              fontSize: "24px",
              fontWeight: 800,
              lineHeight: "36px",
            }}
          >
            Create an account
          </h1>

          {/* Create Account Card */}
          <div
            style={{
              width: "100%",
              padding: "36px",
              background: "white",
              boxShadow: "0px 6px 12px rgba(24.51, 12.15, 50.78, 0.04)",
              borderRadius: "36px",
              display: "flex",
              flexDirection: "column",
              gap: "36px",
            }}
          >
            {/* Email, Password & Confirm Password Fields */}
            <div
              style={{ display: "flex", flexDirection: "column", gap: "48px" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "24px",
                }}
              >
                {/* Email field */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        color: "#473D5C",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "16.8px",
                      }}
                    >
                      Email address
                    </div>
                  </div>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{
                      width: "100%",
                      height: "42px",
                      padding: "12px",
                      background: "white",
                      borderRadius: "12px",
                      border: "1px solid #D1CED6",
                      fontSize: "14px",
                    }}
                  />
                </div>

                {/* Password field */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        color: "#473D5C",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "16.8px",
                      }}
                    >
                      Password
                    </div>
                  </div>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    style={{
                      width: "100%",
                      height: "42px",
                      padding: "12px",
                      background: "white",
                      borderRadius: "12px",
                      border: "1px solid #D1CED6",
                      fontSize: "14px",
                    }}
                  />
                </div>

                {/* Confirm Password field */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        color: "#473D5C",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "16.8px",
                      }}
                    >
                      Confirm password
                    </div>
                  </div>
                  <input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    style={{
                      width: "100%",
                      height: "42px",
                      padding: "12px",
                      background: "white",
                      borderRadius: "12px",
                      border: "1px solid #D1CED6",
                      fontSize: "14px",
                    }}
                  />
                </div>
              </div>

              {/* Create Account Button */}
              <div
                onClick={handleCreateEmailAccount}
                style={{
                  width: "100%",
                  padding: "12px",
                  background: "#5061AC",
                  borderRadius: "12px",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "inline-flex",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    paddingLeft: "4px",
                    paddingRight: "4px",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      color: "white",
                      fontSize: "14px",
                      fontWeight: 600,
                      lineHeight: "16.8px",
                    }}
                  >
                    Create account
                  </div>
                </div>
              </div>
            </div>

            {/* Connect a Wallet Section */}
            <div
              style={{ display: "flex", flexDirection: "column", gap: "36px" }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  gap: "32px",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    height: 0,
                    borderTop: "1px solid rgba(24.51, 12.15, 50.78, 0.10)",
                  }}
                ></div>
                <div
                  style={{
                    color: "rgba(24.51,12.15,50.78,0.80)",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "21px",
                    letterSpacing: "0.28px",
                  }}
                >
                  Or connect a wallet
                </div>
                <div
                  style={{
                    flex: 1,
                    height: 0,
                    borderTop: "1px solid rgba(24.51, 12.15, 50.78, 0.10)",
                  }}
                ></div>
              </div>
              <div
                onClick={handleWalletButtonClick}
                style={{
                  width: "100%",
                  padding: "12px",
                  borderRadius: "12px",
                  border: "1px solid #5061AC",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "inline-flex",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    paddingLeft: "4px",
                    paddingRight: "4px",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      color: "#5061AC",
                      fontSize: "14px",
                      fontWeight: 600,
                      lineHeight: "16.8px",
                    }}
                  >
                    Connect a wallet
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Bottom link */}
          <div style={{ textAlign: "center" }}>
            <span
              style={{
                color: "rgba(24.51, 12.15, 50.78, 0.40)",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "21px",
              }}
            >
              Already have an account?
            </span>
            <span style={{ color: "white" }}> </span>
            <a
              href="/signin"
              rel="noopener noreferrer"
              style={{
                color: "#5061AC",
                fontSize: "14px",
                fontWeight: 600,
                textDecoration: "underline",
                lineHeight: "21px",
              }}
            >
              Sign in here
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;
