import React from "react";
import { useModalState } from "./ModalProvider";

// A small separate component for rendering the error toast
export const ErrorToast: React.FC = () => {
  const { errorMessage } = useModalState();

  if (!errorMessage) return null;

  return (
    <div
      style={{
        position: "fixed",
        bottom: "16px", // Position 16px from the bottom
        left: "50%",
        transform: "translateX(-50%)", // Horizontally center
        display: "inline-block", // Shrink to fit text
        background: "rgba(255, 0, 0, 0.85)",
        color: "#FFFFFF",
        padding: "24px", // Minimal padding
        borderRadius: "6px",
        fontSize: "18px",
        fontWeight: 800,
        lineHeight: 1.3, // Slightly tighter line spacing
        boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.15)",
        textAlign: "center",
        zIndex: 9999,
      }}
    >
      {errorMessage}
    </div>
  );
};
