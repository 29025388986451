import React, { useEffect, useMemo, useState, useCallback } from "react";
import { apiUrls, siteUrls } from "../config";
import { useNavigate } from "react-router-dom";
import { env, useGlobalState } from "../helpers/GlobalStateProvider";
import axios from "axios";
import { useModalState } from "../helpers/ModalProvider";
import { Button } from "../components/ui/button";
import { Box, Card, Collapsible, Container, Flex, IconButton, Link, MenuItem, MenuItemGroup, MenuTrigger, TagLabel, TagRoot, useDisclosure } from "@chakra-ui/react";
import { LuChevronDown, LuExternalLink, LuLogOut, LuMail, LuMenu, LuSettings, LuWallet } from "react-icons/lu";
import { MenuContent, MenuRoot, MenuSeparator } from "../components/ui/menu";
import { clearChatHistory } from "./chatbot/chatHistoryStorage";

type HeaderContainerProps = {
  behavior?: "signin" | "dashboard" | "chatbot" | "docs";
};

const HeaderContainer: React.FC<HeaderContainerProps> = ({ behavior }) => {
  const [hasBearerToken, setHasBearerToken] = useState(false);
  const navigate = useNavigate();
  const { info, refreshAccountInfo, isSignedIn, setBearerToken } = useGlobalState();
  const { onToggle, open } = useDisclosure()
  const { showErrorMessage } = useModalState();

  // Show either email or wallet address or "Account"
  const accountTitle = info?.email ?? info?.walletAddress ?? "Account";

  // Derive behavior booleans
  const isSignin = behavior === "signin";
  const isDashboardActive = !behavior || behavior === "dashboard";
  const isChatbotActive = behavior === "chatbot";
  const isDocsActive = behavior === "docs";

  useEffect(() => {
    const token = localStorage.getItem("bearerToken");
    setHasBearerToken(!!token);
  }, []);

  const handleLogout = useCallback(async () => {
    try {
      const bearerToken = localStorage.getItem("bearerToken");
      await axios.post(
        `${apiUrls[env]}/v3/apiKey/logout`,
        {},
        { headers: { Authorization: `Bearer ${bearerToken}` } },
      );
    } catch (e) {
//      showErrorMessage("Failed to log out2. Please try again.");
    }
    await refreshAccountInfo();
    localStorage.removeItem("bearerToken");
    setBearerToken(null);
    clearChatHistory()
    setHasBearerToken(false);
    navigate("/signin");
  }, [env, navigate, refreshAccountInfo, showErrorMessage]);

  const mainMenu = useMemo(() => {
    return (
      <>
<Button
  variant="plain"
  asChild
  px={0}
  _focus={{ boxShadow: "none", outline: "none" }}
  _focusVisible={{ boxShadow: "none", outline: "none" }}
  _active={{ boxShadow: "none", outline: "none" }}
>
  <Link
    href={siteUrls[env]}
    target="_blank"
    // Also remove focus/outline from the Link itself
    _focus={{ boxShadow: "none", outline: "none" }}
    _focusVisible={{ boxShadow: "none", outline: "none" }}
    _active={{ boxShadow: "none", outline: "none" }}
  >
    CoinCap <LuExternalLink />
  </Link>
</Button>
        <Button px={0} aria-current={isDashboardActive && !isSignin ? 'page' : undefined} variant='plain' onClick={() => navigate("/dashboard")}>Dashboard</Button>    
        <Button px={0} aria-current={isChatbotActive && !isSignin ? 'page' : undefined} variant='plain' onClick={() => navigate("/chatbot")}>AI Chatbot <TagRoot variant='subtle' colorPalette='blue'><TagLabel>Beta</TagLabel></TagRoot></Button>
        <Button
  variant="plain"
  asChild
  px={0}
  _focus={{ boxShadow: "none", outline: "none" }}
  _focusVisible={{ boxShadow: "none", outline: "none" }}
  _active={{ boxShadow: "none", outline: "none" }}
>
<Button px={0} aria-current={isDocsActive && !isSignin ? 'page' : undefined} variant='plain' onClick={() => navigate("/api-docs")}>Docs</Button> 
</Button>
      </>
    )
  }, [env, isChatbotActive, isDashboardActive, isSignin, navigate])

  const userMenu = useMemo(() => {
    if (isSignedIn()) {
      return (
        <MenuRoot>
          <MenuTrigger disabled={!hasBearerToken}>
            <Button variant='secondary'>My Account <LuChevronDown /></Button>
          </MenuTrigger>
          <MenuContent portalled={true}>
            <MenuItemGroup>
              <MenuItem value={accountTitle} alignSelf='stretch'>
                <Flex width='193px' alignItems='center' gap={2} alignSelf='stretch' minWidth={0} overflow='hidden'>
                  {accountTitle === info?.walletAddress ? <LuWallet style={{flexShrink: 0}} /> : <LuMail style={{flexShrink: 0}} />}
                  <Flex flex={1} overflow='hidden'>
                    <span style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      width: '100%',
                      display: 'block'
                    }}>
                      {accountTitle}
                    </span>
                  </Flex>
                </Flex>
              </MenuItem>
            </MenuItemGroup>
            <MenuSeparator />
            <MenuItemGroup>
              <MenuItem value='settings' asChild>
                <a href='/settings'>
                  <LuSettings />Settings
                </a>
              </MenuItem>
              <MenuItem value='logout' color='fg.error' onClick={handleLogout}>
                <LuLogOut /> Log Out
              </MenuItem>
            </MenuItemGroup>
          </MenuContent>
        </MenuRoot>
      );
    }
    
    return (
      <Button variant='secondary' disabled={isSignin}>
        <a href='/signin'>Sign In</a>
      </Button>
    );
  }, [accountTitle, handleLogout, hasBearerToken, info?.walletAddress, isSignedIn, isSignin]);

  return (
    <Card.Root borderTopRadius={0} borderWidth={0} zIndex={1000} bg={open ? 'bg.surface' : 'transparent'}>
      <Container>
        <Flex py={6} width='full' justifyContent='space-between'>
          <IconButton variant='outline' display={{base: 'flex', md: 'none'}} onClick={onToggle}>
            <LuMenu />
          </IconButton>
          <Box flexShrink={0}>
            <img
              src="/external/Plum.svg"
              alt="Logo"
              style={{
                display: "block",
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </Box>

          <Flex gap={8} ml='auto' display={{base: 'none', md: 'flex'}}>
            {mainMenu}
            {userMenu}
            {/* <ColorModeButton /> */}
          </Flex>

          <Flex display={{base: 'flex', md: 'none'}}>
            {userMenu}
          </Flex>
        </Flex>
      </Container>

      <Collapsible.Root open={open} lazyMount>
          <Collapsible.Content px={4}>
          <Flex flexDir='column' gap={2} justifyContent='flex-start' alignItems='flex-start' pb={4}>
            {mainMenu}
          </Flex>
        </Collapsible.Content>
      </Collapsible.Root>
    </Card.Root>
  );
};

export default HeaderContainer;
