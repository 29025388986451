  // Simple helper to format decimals without floating-point surprises
  export const formatDecimals = (num: number, decimals: number): string => {
    if (typeof num !== 'number' || isNaN(num)) {
      return '';
    }

    const multiplier = Math.pow(10, decimals);
    // Perform a manual round before calling .toFixed()
    const roundedValue = Math.round(num * multiplier) / multiplier;

    // Now format it
    return roundedValue.toFixed(decimals);
  };
