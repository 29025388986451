import React from "react";
import { STRIPE_CUSTOMER_PORTAL, useGlobalState } from "../../helpers/GlobalStateProvider";

type PaymentsSectionProps = {
  cardStyle: React.CSSProperties;
  openPaymentsPortal: () => void;
};

// TODO move this somewhere common
export const getplanForTier = (tier: number) => {
  if(tier === 0) return 'Free';
  else if(tier === 1) return 'Basic';
  else if(tier === 2) return 'Growth';
  else if(tier === 3) return 'Professional';
  else if(tier === 4) return 'Enterprise';
  else return 'Error'
}

// TODO move this somewhere common
const getUsdPriceForTier = (tier?: number) => {
  if(!tier) return `$0.00`;
  if(tier === 0) return `$0.00`;
  else if(tier === 1) return `$25.00`;
  else if(tier === 2) return `$65.00`;
  else if(tier === 3) return `$150.00`;
  else if(tier === 4) return `$300.00`;
  else throw new Error('Couldnt get usd price for tier')
}

const PaymentsSection: React.FC<PaymentsSectionProps> = ({
  cardStyle,
  openPaymentsPortal,
}) => {

    const { stakeMorAndConnectWallet, info } = useGlobalState();

  const handleStake = () => {
    stakeMorAndConnectWallet(1, false);
  };

  // move this into a common utility helper file
  const formatDecimals = (num: number, decimals: number): string => {
    if (typeof num !== 'number' || isNaN(num)) {
      return '';
    }

    const multiplier = Math.pow(10, decimals);
    // Perform a manual round before calling .toFixed()
    const roundedValue = Math.round(num * multiplier) / multiplier;

    // Now format it
    return roundedValue.toFixed(decimals);
  };
  
  return (
    <div
      style={{
        ...cardStyle,
        padding: "16px",
        boxSizing: "border-box",
      }}
    >
      {/* Top: Title, Subtitle, Button */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <h2 style={{ margin: "0 0 8px 0", fontSize: "18px", fontWeight: 700 }}>
            Payments
          </h2>
          <p style={{ margin: 0, fontSize: "14px", color: "#756D85" }}>
            Edit your subscription, manage payment methods, and view payment
            history.
          </p>
        </div>

        {/* Updated button */}
        <button
          onClick={openPaymentsPortal}
          style={{
            display: "inline-flex",
            alignItems: "center",
            gap: "8px",
            padding: "12px 12px",
            borderRadius: "12px",
            background: "#5061AC",
            color: "#FFFFFF",
            border: "none",
            cursor: "pointer",
            fontWeight: 600,
          }}
        >
          <span>Stripe Customer Portal</span>
          <img
            src="/external/arrow-top-right-on-square.svg"
            alt="Open in new tab"
            style={{ width: "16px", height: "16px", filter: "brightness(0) invert(1)"}}
          />
        </button>
      </div>

      {/* Horizontal Divider */}
      <hr
        style={{
          margin: "16px 0",
          border: 0,
          borderTop: "1px solid #E0E0E0",
        }}
      />

      {/* Plan Information Section */}
      <div style={{ fontSize: "14px", color: "#2F2F2F" }}>
        {/* Row 1: MOR Staking */}
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "260px 80px 1fr 100px",
            alignItems: "center",
            columnGap: "16px",
            marginBottom: "8px",
          }}
        >
          <div style={{ fontWeight: 600 }}>Plan earned via MOR staking:</div>
          <div>{getplanForTier(info?.stakedTier)}</div>
          <div style={{ opacity: 0.7 }}>{formatDecimals(info?.stakedBalance ?? 0, 4)} MOR Staked</div>
          <div style={{ textAlign: "right" }}>
            <a
              href="#"
              onClick={handleStake}
              style={{
                textDecoration: "underline",
                color: "#5061AC",
                fontWeight: 600,
              }}
            >
              stake
            </a>
          </div>
        </div>

        {/* Row 2: Monthly Payment */}
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "260px 80px 1fr 140px",
            alignItems: "center",
            columnGap: "16px",
            marginBottom: "16px",
          }}
        >
          <div style={{ fontWeight: 600 }}>Plan earned via monthly payment:</div>
          <div>{getplanForTier(info?.stripeTier)}</div>
          <div style={{ opacity: 0.7 }}>paying {getUsdPriceForTier(info?.stripeTier)}/mo</div>
          <div style={{ textAlign: "right" }}>
            <a
              href={STRIPE_CUSTOMER_PORTAL}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "underline",
                color: "#5061AC",
                fontWeight: 600,
              }}
            >
              edit subscription
            </a>
          </div>
        </div>

        {/* Note Section */}
        <p
          style={{
            fontSize: "12px",
            color: "#756D85",
            margin: 0,
            lineHeight: 1.5,
          }}
        >
          NOTE: you will have access to the highest plan you
          have earned via staking or monthly payments. If your plan earned via
          staking is higher than the plan earned via monthly payments, you can
          cancel your monthly payments via Stripe without losing your current
          level of API access.
        </p>
      </div>
    </div>
  );
};

export default PaymentsSection;
