import React, { useCallback } from "react";
import { useGlobalState } from "../../helpers/GlobalStateProvider";
import { useModalState } from "../../helpers/ModalProvider";
import ModalWrapper from "./ModalWrapper"; // Adjust import if needed
import { ethers } from "ethers";

const ClaimBroadcastModal: React.FC = () => {
  const { transactionDetails, selectedChainId, setTransactionDetails } = useGlobalState();
  const {
    isClaimBroadcastModalVisible,
    setClaimBroadcastModalVisible,
  } = useModalState();


  // Function to close the modal
  const handleClose = useCallback(() => {
    setClaimBroadcastModalVisible(false);
    setTransactionDetails(null);
  }, [setClaimBroadcastModalVisible]);

    // If the modal isn't visible, don't render anything
    if (!isClaimBroadcastModalVisible || !transactionDetails?.tx) {
      return null;
    }
  
  // Safely format transaction details
  const formattedTransactionId = transactionDetails?.tx
    ? `${transactionDetails.tx.slice(0, 6)}...${transactionDetails.tx.slice(-4)}`
    : "";

  const bigIntMorValue = BigInt(transactionDetails?.morAmount ?? 0)

    
  const morValueInUSD = parseFloat(ethers.formatEther(bigIntMorValue) ?? '0') * (transactionDetails?.morPrice);
  const ethFeeInUSD = (transactionDetails?.ethFee ?? 0) * (transactionDetails?.ethPrice ?? 0);

  return (
    <ModalWrapper onBackdropClick={handleClose}>
      {/* Dark backdrop + container (like in ChainSelectionModal) */}
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1000,
        }}
        onClick={handleClose}
      >
        <div
          style={{
            width: 560,
            maxHeight: "90vh",
            padding: 24,
            overflowY: "auto",
            background: "#FFFFFF",
            boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.2)",
            borderRadius: 24,
            display: "flex",
            flexDirection: "column",
          }}
          // Prevent clicks inside modal from closing the backdrop
          onClick={(e) => e.stopPropagation()}
        >
          {/* Title & close button row */}
          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 16 }}>
            <div
              style={{
                fontSize: 24,
                fontWeight: 600,
                color: "#190C33",
              }}
            >
              Transaction Sent
            </div>
            {/* Close button */}
            <button
              onClick={handleClose}
              style={{
                background: "transparent",
                border: "none",
                cursor: "pointer",
                fontSize: 20,
                fontWeight: "bold",
                color: "#333",
              }}
            >
              ✕
            </button>
          </div>

          {/* Body text */}
          <div style={{ marginBottom: 16 }}>
            Your transaction has been submitted. It may take a few minutes for your
            account to reflect any changes. You can view the status of your transaction
            by clicking the transaction ID below, or close this window.
          </div>

          {/* “Card” container (optional styling) */}
          <div
            style={{
              background: "rgba(25, 12, 51, 0.03)",
              border: "1px solid #D3D3D3",
              borderRadius: 12,
              padding: 16,
              marginBottom: 24,
            }}
          >
            {/* Amount Claimed */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 8,
              }}
            >
              <span style={{ color: "#8C8599" }}>Amount Claimed:</span>
              <span>
                {(parseFloat(ethers.formatEther(transactionDetails?.morAmount.toFixed(0)) ?? 0)).toFixed(3)} MOR (
                ${morValueInUSD.toFixed(2)})
              </span>
            </div>

            {/* Fee */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 8,
              }}
            >
              <span style={{ color: "#8C8599" }}>Fee:</span>
              <span>
                {(transactionDetails?.ethFee ?? 0).toFixed(8)} ETH (
                ${ethFeeInUSD.toFixed(2)})
              </span>
            </div>

            {/* Transaction ID */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span style={{ color: "#8C8599" }}>Transaction ID:</span>
              <a
                href={
                  selectedChainId === 42161
                    ? `https://arbiscan.io/tx/${transactionDetails?.tx}`
                    : `https://basescan.org/tx/${transactionDetails?.tx}`
                }
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#5061AC", textDecoration: "underline" }}
              >
                {formattedTransactionId}
              </a>
            </div>
          </div>

          {/* Bottom "Close" button */}
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              onClick={handleClose}
              style={{
                minWidth: 80,
                height: 42,
                padding: "0 20px",
                background: "#FFFFFF",
                borderRadius: 12,
                border: "1px solid #5061AC",
                fontSize: 14,
                fontWeight: 500,
                color: "#5061AC",
                cursor: "pointer",
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ClaimBroadcastModal;
