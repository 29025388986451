import { apiUrls } from "../../config";
import { env } from "../../helpers/GlobalStateProvider";

export const CHAT_HISTORY_KEY = "chatbotHistory";


// Sync function for local storage
export const getLocalChatHistory = (): any[] => {
  const savedHistory = localStorage.getItem(CHAT_HISTORY_KEY);
  return savedHistory ? JSON.parse(savedHistory) : [];
};

// Async function to retrieve chat history
export const getChatHistory = async (): Promise<any[]> => {
  const token = localStorage.getItem("bearerToken")
  if (token) {
    try {
      console.log('fetching chat history from backend...')
      const response = await fetch(`${apiUrls[env]}/rag/chat/history`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) throw new Error("Failed to fetch chat history from backend");
      const { chatHistory } = await response.json();
      console.log('got chat history from backend')
      return chatHistory;
    } catch (error) {
      console.log("Error fetching chat history from backend:", error);
    }
  }
  console.log('got chat history from local storage')
  return getLocalChatHistory();
};

// Save function to store chat history
export const saveChatHistory = async (history: any[]): Promise<void> => {
  const token = localStorage.getItem("bearerToken")
  if (token) {
    try {
      const response = await fetch(`${apiUrls[env]}/rag/chat/history`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ chatHistory: history }),
      });
      if (!response.ok) throw new Error("Failed to save chat history to backend");
    } catch (error) {
      console.log("Error saving chat history to backend:", error);
    }
  }
  // Fallback to local storage
  localStorage.setItem(CHAT_HISTORY_KEY, JSON.stringify(history));
};

// Function to migrate local chat history to backend
export const migrateLocalChatHistory = async (): Promise<void> => {
  const token = localStorage.getItem("bearerToken")
  if (token) {
    try {
      // If no localChatHistory is provided, get it from localStorage
      const historyToMigrate = getLocalChatHistory();

      const response = await fetch(`${apiUrls[env]}/rag/chat/migrate-history`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ localChatHistory: historyToMigrate }),
      });
      if (!response.ok) throw new Error("Failed to migrate chat history to backend");
      return;
    } catch (error) {
      console.log("Error migrating chat history to backend:", error);
    }
  }
  console.log("No bearer token found; migration skipped.");
};

// Clear function to remove chat history
export const clearChatHistory = (): void => {
  localStorage.removeItem(CHAT_HISTORY_KEY);
};