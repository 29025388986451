// StakeModals.tsx
import React, { useState } from "react";
import StakeModal1 from "./StakeModalContent/StakeModal1";
import StakeModalLoading from "./StakeModalContent/StakeModalLoading";
import StakeModal2 from "./StakeModalContent/StakeModal2";
import { useModalState } from "../../helpers/ModalProvider";
import { DialogBackdrop, DialogRoot } from "@chakra-ui/react";
import { DialogContent } from "../../components/ui/dialog";

const StakeModals: React.FC = () => {
  // Local states for multi-step flow
  const [isStakeModal2Visible, setIsStakeModal2Visible] = useState(false);
  const [isStakeModalLoadingVisible, setIsStakeModalLoadingVisible] =
    useState(false);

  // Local states for approval logic
  const [approvalDone, setApprovalDone] = useState(false);
  const [approvalTxid, setApprovalTxid] = useState<string | boolean>(false);

  // From ModalProvider: stake modal visibility + close function
  const { isStakeModalVisible, handleCloseStakeModal } = useModalState();

  // Step 1 → Step 2
  const handleContinue = async () => {
    setIsStakeModal2Visible(true);
  };

  // Step 1 → “Loading” (approval)
  const handleApproveContinue = async () => {
    setIsStakeModalLoadingVisible(true);
  };

  // Closes all stake modals
  const handleCloseStakeModals = () => {
    setIsStakeModal2Visible(false);
    setIsStakeModalLoadingVisible(false);
    handleCloseStakeModal(); // from ModalProvider
  };

  return (
    <DialogRoot open={isStakeModalVisible} onOpenChange={handleCloseStakeModals}>
      <DialogBackdrop />
      <DialogContent>
        {/* Step 1: StakeModal1 */}
      {isStakeModalVisible &&
        !isStakeModal2Visible &&
        !isStakeModalLoadingVisible && (
          <StakeModal1
            handleClose={handleCloseStakeModals}
            onContinue={handleContinue}
            handleApproveContinue={handleApproveContinue}
            setApprovalDone={setApprovalDone}
            setApprovalTxid={setApprovalTxid}
          />
        )}
      {/* Loading Modal (approval) */}
      {isStakeModalLoadingVisible && !approvalDone && (
        <StakeModalLoading approvalTxId={approvalTxid} />
      )}

      {/* Step 2: StakeModal2 */}
      {isStakeModal2Visible && (
          <StakeModal2 handleClose={handleCloseStakeModals} />
        )}
      </DialogContent>
    </DialogRoot>
  );
};

export default StakeModals;
