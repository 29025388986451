import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import ChatSidebar from "./ChatSidebar";
import MessageList from "./MessageList";
import InputField from "./InputField";
import WalletInfo from "./WalletInfo";
import HeaderContainer from "../Header";
import { getChatHistory, saveChatHistory } from "./chatHistoryStorage";
import { apiUrls } from "../../config";
import { env, useGlobalState } from "../../helpers/GlobalStateProvider";
import { useModalState } from "../../helpers/ModalProvider";
import { useNavigate } from "react-router-dom";

const settings: Record<string, any> = {
  openai: {
    models: [],
  },
  venice: {
    models: ["llama-3.3-70b", "llama-3.1-405b", "deepseek-r1-llama-70b"],
  },
};

const ChatContainer: React.FC = () => {
  useEffect(() => {
    const fetchChatHistory = async () => {
        const history = await getChatHistory();
        setChatHistory(history);
    };

    fetchChatHistory();
  }, []);

  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [initialInstructions, setInitialInstructions] = useState<string>("");
  const [selectedModel, setSelectedModel] = useState<string>(
    "llama-3.3-70b",
  );
  const chatEndRef = useRef<HTMLDivElement>(null);

  const [remainingPrompts, setRemainingPrompts] = useState<any>({
    remainingPrompts: 10,
    promptsPerDay: 10,
  });

  let {
    info,
    setChatHistory,
    chatHistory,
    selectedChatId,
    setSelectedChatId,
    messages,
    setMessages,
    isSignedIn,
    handleLogout,
  } = useGlobalState();
  const navigate = useNavigate()
  const userTier = info?.tier || 0; // Handle undefined info.tier

  useEffect(() => {
    fetchInitialInstructions();
  }, [selectedModel]);

  const [intervalId, setIntervalId] = useState<any>(null);

  useEffect(() => {
    clearInterval(intervalId);
    const iid = setInterval(async () => {
      await fetchRemainingPrompts();
    }, 15000);
    setIntervalId(iid);
    fetchRemainingPrompts();
    return () => clearInterval(iid);
  }, []);

  useEffect(() => {
    if (userTier <= 0 && selectedModel !== "llama-3.3-70b") {
      setSelectedModel("llama-3.3-70b");
    }
  }, [userTier, selectedModel]);

  const { showErrorMessage } = useModalState();

  const fetchInitialInstructions = () => {
    const selectedLlm = getSelectedLlm();

    axios
      .get(`${apiUrls[env]}/rag/chat/init`, {
        params: {
          modelToUse: selectedModel,
          llmToUse: selectedLlm,
        },
      })
      .then((response) => {
        setInitialInstructions(response.data.instructions || "");
      })
      .catch((error) =>
        console.error("Error fetching initial instructions:", error),
      );
  };

  const fetchRemainingPrompts = async () => {
    const bearerToken = localStorage.getItem("bearerToken");
    const response = await axios.post(
      `${apiUrls[env]}/rag/chat/remainingPrompts`,
      {},
      {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      },
    );
    setRemainingPrompts(response?.data);
  };

  const getSelectedLlm = (): string => {
    return settings.openai.models.includes(selectedModel) ? "openai" : "venice";
  };

  const handleSendMessage = async () => {
    if (loading || inputValue.trim() === "") return;

    const userMessage = {
      id: Date.now(),
      sender: "User",
      text: inputValue.trim(),
      date: new Date(),
    };

    setInputValue("");
    scrollToBottom();

    let currentChatId = selectedChatId;

    if (currentChatId === null) {
      // Create new chat with the first message
      const newChatId = Date.now();
      currentChatId = newChatId;

      setChatHistory((prevChatHistory: any) => {
        const newChat = {
          id: newChatId,
          title: userMessage.text,
          messages: [userMessage],
        };
        return [...prevChatHistory, newChat];
      });

      setSelectedChatId(currentChatId);
    } else {
      // Update existing chat
      setChatHistory((prevChatHistory: any) =>
        prevChatHistory.map((chat: any) => {
          if (chat.id === currentChatId) {
            const updatedMessages = [...chat.messages, userMessage];
            let updatedTitle = chat.title;
            if (chat.messages.length === 0) {
              updatedTitle = userMessage.text;
            }
            return { ...chat, messages: updatedMessages, title: updatedTitle };
          }
          return chat;
        }),
      );
    }

    setLoading(true);

    const startTime = Date.now();

    try {
      const selectedLlm = getSelectedLlm();

      const bearerToken = localStorage.getItem("bearerToken");

      const response = await axios.post(
        `${apiUrls[env]}/rag/chat`,
        {
          question: userMessage.text,
          llmToUse: selectedLlm,
          modelToUse: selectedModel,
          initialInstructions,
        },
        {
          headers: { Authorization: `Bearer ${bearerToken}` },
        },
      );

      await fetchRemainingPrompts();
      const strippedResponse = response.data.chatresponse
        .toString()
        .replace(/CorrectAnswer:|UnknownResult:/, "")
        .trim();
      const responseTime = Date.now() - startTime;

      const botMessage = {
        id: Date.now() + 1,
        sender: "CoinCap",
        text: strippedResponse,
        date: new Date(),
        model: selectedModel,
        duration: responseTime,
      };

      setChatHistory((prevChatHistory: any) =>
        prevChatHistory.map((chat: any) => {
          if (chat.id === currentChatId) {
            const updatedMessages = [...chat.messages, botMessage];
            return { ...chat, messages: updatedMessages };
          }
          return chat;
        }),
      );
    } catch (error) {
      showErrorMessage("An unexpected error occurred. Please try again.");
    } finally {
      setLoading(false);
      scrollToBottom();
    }
  };

  const scrollToBottom = () => {
    if (chatEndRef.current)
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (selectedChatId !== null) {
      const selectedChat = chatHistory.find(
        (chat: any) => chat.id === selectedChatId,
      );
      if (selectedChat) {
        setMessages(selectedChat.messages);
      } else {
        setMessages([]);
      }
    } else {
      setMessages([]);
    }
  }, [selectedChatId, chatHistory]);

  // Save chat history to localStorage whenever it changes
  useEffect(() => {
    saveChatHistory(chatHistory);
  }, [chatHistory]);

  return (
    <div
      style={{
        minHeight: "100vh",
        width: "100%",
        maxWidth: "1392px",
        margin: "0 auto",
        paddingTop: "24px",
        paddingBottom: "24px",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
      }}
    >
      <HeaderContainer behavior="chatbot" />
      {/* Main container to center the content */}
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "stretch",
          padding: "20px",
        }}
      >
        {/* Content wrapper for max width */}
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "24px",
          }}
        >
          {/* Main chat area */}
          <div
            style={{
              flex: 1,
              display: "flex",
              gap: "24px",
              padding: "40px",
              paddingTop: "16px",
              minHeight: 0,
              background: "white",
              borderRadius: "24px",
              boxShadow: "0px 6px 12px rgba(24, 12, 51, 0.04)",
            }}
          >
            {/* Left Column */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "320px",
                height: "100%",
                minHeight: 0,
              }}
            >
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  minHeight: 0,
                }}
              >
                <ChatSidebar />
              </div>
              {isSignedIn() && (
                <div style={{ flexShrink: 0, marginTop: "16px" }}>
                  <WalletInfo
                    disconnectWallet={handleLogout}
                    info={info}
                  />
                </div>
              )}
            </div>

            {/* Right Column */}
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                background: "rgba(255, 255, 255, 0.80)",
                boxShadow: "0px 6px 12px rgba(24, 12, 51, 0.04)",
                borderRadius: "40px",
                padding: "40px",
                minHeight: 0,
              }}
            >
              {/* Title and Dropdown */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "24px",
                }}
              >
                <h1
                  style={{
                    fontSize: "24px",
                    fontWeight: "800",
                    color: "#190C33",
                    margin: 0,
                  }}
                >
                  {
                    chatHistory.find((chat: any) => chat.id === selectedChatId)
                      ?.title
                  }
                </h1>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#190C33",
                    }}
                  >
                    Speaking to CoinCap via:
                  </span>
                  <select
                    value={selectedModel}
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      const isFreeModel = selectedValue === "llama-3.3-70b";
                      const isDisabled = !isFreeModel && userTier <= 0;
                      if (isDisabled) return; // Prevent selecting disabled options
                      setSelectedModel(selectedValue);
                    }}
                    style={{
                      padding: "8px 16px",
                      borderRadius: "12px",
                      border: "1px solid #190C33",
                      fontSize: "14px",
                      background: "#F9F9F9",
                      cursor: "pointer",
                      outline: "none",
                    }}
                  >
                    {Object.values(settings)
                      .flatMap(({ models }: any) => models)
                      .map((model: string) => {
                        const isFreeModel = model === "llama-3.3-70b";
                        const isDisabled = !isFreeModel && userTier <= 0;
                        return (
                          <option
                            key={model}
                            value={model}
                            disabled={isDisabled}
                          >
                            {model} {isDisabled ? "(PRO)" : ""}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>

              <div style={{ flex: 1, overflowY: "auto", minHeight: 0 }}>
                <MessageList messages={messages} chatEndRef={chatEndRef} />
              </div>
              <div style={{ flexShrink: 0 }}>
                {/* Remaining Prompts */}
                {remainingPrompts !== null && (
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      color: "#6B7280",
                      marginBottom: "8px",
                      paddingLeft: "16px",
                    }}
                  >
                    {remainingPrompts.remainingPrompts > 1000000
                      ? "Unlimited prompts remaining today"
                      : `${remainingPrompts.remainingPrompts}/${remainingPrompts.promptsPerDay} prompts remaining today`}
                  </div>
                )}

                <div style={{ flexShrink: 0 }}>
                  <InputField
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    handleSendMessage={handleSendMessage}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatContainer;
