import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { apiUrls } from "../../config";
import { env, useGlobalState } from "../../helpers/GlobalStateProvider";
import { useModalState } from "../../helpers/ModalProvider"; // Adjust path if needed
import { Field } from "../../components/ui/field";
import { Button } from "../../components/ui/button";
import { Input } from "@chakra-ui/react";
import { DialogBackdrop, DialogBody, DialogCloseTrigger, DialogContent, DialogFooter, DialogHeader, DialogRoot, DialogTitle } from "../../components/ui/dialog";

const EditAPIKeysModal: React.FC = () => {
  // 1) Get modal visibility + content from ModalProvider
  const { isEditModalVisible, setEditModalVisible, modalContent, showErrorMessage } =
    useModalState();

  // 2) Destructure from modalContent
  const { label, apiKey } = modalContent;

  // 3) Access global logic (like refreshAccountInfo) from GlobalState
  const { refreshAccountInfo } = useGlobalState();

  // 4) Local state for the updated label
  const [newLabel, setNewLabel] = useState<string>(label);

  // 5) Ref for focusing the input when the modal opens
  const inputRef = useRef<HTMLInputElement>(null);

  // 6) useEffect to handle "on open" logic
  useEffect(() => {
    // If not visible, do nothing
    if (!isEditModalVisible) return;

    // When opening, reset newLabel to the current label
    setNewLabel(label);

    // Focus + select
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [isEditModalVisible, label]);

  // 8) Actual Save handler (like in your original code)
  const handleSave = async () => {
    const requestBody = {
      apiKey,
      action: "enable",
      label: newLabel,
    };

    try {
      const bearerToken = localStorage.getItem("bearerToken");
      if (!bearerToken) {
        console.log("No bearer token found");
        setEditModalVisible(false);
        return;
      }

      await axios.post(`${apiUrls[env]}/v3/apiKey/toggleApiKey`, requestBody, {
        headers: { Authorization: `Bearer ${bearerToken}` },
      });
      await refreshAccountInfo();
    } catch (error: any) {
      showErrorMessage(
        `An unexpected error occurred. ${error?.response?.data?.message}`,
      );
    }

    setEditModalVisible(false);
  };

  // 9) Render the modal UI (copied from your original snippet)
  return (
    <DialogRoot lazyMount open={isEditModalVisible} onOpenChange={e => setEditModalVisible(e.open)}>
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit API Key</DialogTitle>
          <DialogCloseTrigger />
        </DialogHeader>
        <DialogBody>
          <Field label='Label'>
            <Input
              ref={inputRef}
              value={newLabel}
              onChange={(e) => setNewLabel(e.target.value)} 
              onKeyDown={(e) => {
                if (e.key === "Enter" && newLabel) {
                  e.preventDefault();
                  handleSave();
                }
              }}
            />
          </Field>
        </DialogBody>
        <DialogFooter>
          <Button onClick={handleSave} disabled={!newLabel}>Save Changes</Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

export default EditAPIKeysModal;
