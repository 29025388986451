// DeleteAuthModal.tsx
import axios from "axios";
import React from "react";
import { env, useGlobalState } from "../../helpers/GlobalStateProvider";
import { useModalState } from "../../helpers/ModalProvider";
import { apiUrls } from "../../config";
import { clearChatHistory } from "../chatbot/chatHistoryStorage";
import { useNavigate } from "react-router-dom";

const DeleteAccountModal: React.FC = () => {
  const {
    isDeleteAccountModalVisible,
    closeDeleteAccountModal,
    showErrorMessage,
  } = useModalState();

  const navigate = useNavigate();
  const { refreshAccountInfo, setBearerToken } = useGlobalState();

  if (!isDeleteAccountModalVisible) {
    return null;
  }

  const doDeleteAccount = async () => {
    console.log("Deleting account...");
    const bearerToken = localStorage.getItem("bearerToken");
    if (!bearerToken) {
      showErrorMessage("No bearer token found. Please sign in first.");
      return;
    }

    try {
      const response = await axios.post(
        `${apiUrls[env]}/v3/apiKey/delete_account`,
        {},
        { headers: { Authorization: `Bearer ${bearerToken}` } }
      );
      console.log("Account deleted successfully:", response.data);
      localStorage.removeItem("bearerToken");
      setBearerToken(null)
      clearChatHistory();
      refreshAccountInfo();
      closeDeleteAccountModal();
      navigate("/dashboard");
    } catch (error: any) {
      showErrorMessage(
        `An unexpected error occurred ${error?.response?.data?.message}`
      );
    }
  };

  const onCancel = () => {
    closeDeleteAccountModal();
  };

  return (
    <div
      style={{
        position: "fixed",
        inset: 0,
        background: "rgba(0, 0, 0, 0.4)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
      }}
      onClick={onCancel}
    >
      <div
        style={{
          width: "600px", // Increased width
          background: "white",
          borderRadius: "16px",
          padding: "32px",
          position: "relative",
          textAlign: "left",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <h2
          style={{
            color: "var(--Bright-Red-100, #FF4574)",
            fontSize: "23px",
            fontWeight: 800,
            lineHeight: "1.5",
            marginBottom: "16px",
            letterSpacing: "-0.46px",
          }}
        >
          Delete Account?
        </h2>
        <p
          style={{
            fontSize: "16px",
            color: "#473D5C",
            marginBottom: "16px",
            lineHeight: "1.6",
          }}
        >
          Deleting your account will cause the following:
        </p>
        <ul
          style={{
            marginBottom: "16px",
            paddingLeft: "20px",
            listStyleType: "disc",
            color: "#473D5C",
            fontSize: "16px",
            lineHeight: "1.8",
          }}
        >
          <li>You will permanently lose all accrued CoinCap points.</li>
          <li>You will permanently lose all chat history.</li>
          <li>All API keys and API history will be deleted.</li>
        </ul>
        <p
          style={{
            fontWeight: 700,
            color: "#190C33",
            fontSize: "16px",
            marginBottom: "24px",
            lineHeight: "1.5",
          }}
        >
          This action cannot be undone!
        </p>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "16px",
          }}
        >
          <button
            onClick={onCancel}
            style={{
              borderRadius: "12px",
              border: "1px solid var(--Plum-100, #5061AC)",
              background: "var(--White, #FFF)",
              display: "flex",
              minWidth: "42px",
              minHeight: "42px",
              padding: "12px",
              justifyContent: "center",
              alignItems: "center",
              gap: "4px",
              color: "var(--Plum-100, #5061AC)",
              fontWeight: 600,
              cursor: "pointer",
            }}
          >
            Cancel
          </button>
          <button
            onClick={doDeleteAccount}
            style={{
              padding: "8px 16px",
              background: "var(--Bright-Red-100, #FF4574)",
              borderRadius: "12px",
              color: "#FFFFFF",
              fontWeight: 600,
              border: "none",
              cursor: "pointer",
            }}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccountModal;
