import React from "react";
import { DialogBody, Heading, Link, Spinner, Text } from "@chakra-ui/react";
import { useGlobalState } from "../../../helpers/GlobalStateProvider";

const StakeModalLoading: React.FC<any> = ({ approvalTxId }) => {
  console.log("approvalTxid", approvalTxId);

  const { selectedChainId } = useGlobalState();
  return (
    <DialogBody textAlign='center' display='flex' flexDirection='column' alignItems='center' justifyContent='center' gap={6}>
      <Heading>Awaiting Confirmation...</Heading>
      <Spinner size='xl' css={{ "--spinner-track-color": "colors.border.muted" }} />
      {approvalTxId && (
            <Link 
              href={selectedChainId === 42161 ? `https://arbiscan.io/tx/${approvalTxId}` : `https://basescan.org/tx/${approvalTxId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {`${approvalTxId.slice(0, 6)}...${approvalTxId.slice(-6)}`}
            </Link>
          )}
      <Text>Wait for the approval transaction to be confirmed and then sign the staking transaction... Please do not close this window until all transactions have been signed.</Text>
    </DialogBody>
  );
};

export default StakeModalLoading;
