import React from "react";
import HeaderContainer from "./Header";
import SwaggerUI from 'swagger-ui-react';
import { swagggerDocUrls } from "../config";
import { env } from "../helpers/GlobalStateProvider";
import 'swagger-ui-react/swagger-ui.css';
import './custom-swagger.css'; 
import { Layout } from "../components/Layout";

{/* @ts-expect-error Server Component */}
const SwaggerComponent = () => <SwaggerUI url={swagggerDocUrls[env]} />

const ApiDocs: React.FC = () => {
  return (
    <Layout behavior="docs">
      {/* Main content container */}
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          // If you prefer left alignment, change 'alignItems' to 'flex-start'
          alignItems: "flex-start",
          padding: "20px",
        }}
      >
        {/* Wrap the content in a column with some reasonable max width */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "28px",
            maxWidth: 680,
            width: "100%",
          }}
        >
          
          <div
            style={{
              color: "#190C33",
              fontSize: 30,
              fontFamily: "Be Vietnam Pro",
              fontWeight: 700,
              lineHeight: "36px",
              wordWrap: "break-word",
            }}
          >
            CoinCap API 3.0
          </div>

          <div style={{ alignSelf: "stretch" }}>
            <span
              style={{
                color: "#473D5C",
                fontSize: 14,
                fontFamily: "Be Vietnam Pro",
                fontWeight: 400,
                lineHeight: "21px",
                wordWrap: "break-word",
              }}
            >
              CoinCap is a useful tool for real-time pricing and market activity
              for over 1,000 cryptocurrencies. By collecting exchange data from
              thousands of markets, we are able to offer transparent and
              accurate data on asset price and availability.
              <br />
              <br />
              Our API will offer insight into exactly which exchanges and
              markets contribute to our pricing.
              <br />
              <br />
              For all endpoints, a single page offers 100 responses by default
              and supports up to 2,000 responses per page upon requests.
              <br />
              <br />
              You can purchase API access, generate keys, and monitor API usage
              via our dashboard at:{" "}
              <a 
                href="https://pro.coincap.io/dashboard" 
                target="_blank" 
                rel="noopener noreferrer"
                style={{ textDecoration: "underline", color: "#473D5C" }}
              >
                pro.coincap.io/dashboard
              </a>
            </span>
          </div>

          <div
            style={{
              color: "#190C33",
              fontSize: 20,
              fontFamily: "Be Vietnam Pro",
              fontWeight: 700,
              lineHeight: "24px",
              wordWrap: "break-word",
            }}
          >
            Migrating from API 2.0 to API 3.0
          </div>

          <div style={{ alignSelf: "stretch" }}>
            <span
              style={{
                color: "#473D5C",
                fontSize: 14,
                fontFamily: "Be Vietnam Pro",
                fontWeight: 400,
                lineHeight: "21px",
                wordWrap: "break-word",
              }}
            >
              We are currently in the process of sunsetting API 2.0 and
              migrating all users over to the API 3.0. Care has been taken to
              ensure that the migration process is simple and smooth for all of
              our existing API users. However, there are some elements that have
              changed and should be taken into consideration when migrating to
              API 3.0:
            </span>
            <ul
              style={{
                margin: "10px 0 10px 20px",
                color: "#473D5C",
                fontSize: 14,
                fontFamily: "Be Vietnam Pro",
                fontWeight: 400,
                lineHeight: "21px",
                listStyleType: "disc",
                listStylePosition: "outside",
              }}
            >
              <li>
                The Rest API base URL has changed from <strong>api.coincap.io/v2/</strong> to: <strong>rest.coincap.io/v3/</strong>
              </li>
              <br />
              <li>
                The Web-socket base URL has changed from <strong>wss://ws.coincap.io/</strong> to: <strong>wss://wss.coincap.io/</strong>
              </li>
              <br />
              <li>
                All calls now require a bearer token in the header or URL. For example:
              </li>
              <br />
              <a target="_blank" rel="noopener noreferrer" style={{ textDecoration: "underline", color: "#473D5C" }} href="https://api.coincap.io/v2/assets">api.coincap.io/v2/assets</a>
              {' '}(Old Api)
              <br/>
              <br/>
              <a target="_blank" rel="noopener noreferrer" style={{ textDecoration: "underline", color: "#473D5C" }} href="https://rest.coincap.io/v3/assets?apiKey=YourApiKey">rest.coincap.io/v3/assets?apiKey=YourApiKey</a>
              {' '}(New Api)
              <br/>

              <br/>
              See Swagger documentation below for more examples

              <br/>
              <br/>
              <li>
                Slug & Asset Id are the same thing
              </li>
              <br />
            </ul>
            <span
              style={{
                color: "#473D5C",
                fontSize: 14,
                fontFamily: "Be Vietnam Pro",
                fontWeight: 400,
                lineHeight: "21px",
                wordWrap: "break-word",
              }}
            >
              Aside from the four points listed here, we have kept all other
              features of API 3.0 the same as API 2.0.
              <br />
              <br />
              For any questions regarding migration to the new API, suggestions
              on new API features, or bugs/issues you notice, please reach out
              to us on{" "}
              <a
                href="https://mee6.xyz/en/i/S3ZfymIhLR"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "underline", color: "#473D5C" }}
              >
                Discord
              </a>
              <br />
              .<br />
              Over the next couple weeks, we will start rate limiting the old
              V2 API and returning a 429 error message notifying users that they
              are required to migrate. These error messages will increase in
              frequency until all calls to the old V2 API return this error
              message. At that point, the V2 API will be deactivated
              permanently.
            </span>
          </div>

          <div
            style={{
              color: "#190C33",
              fontSize: 20,
              fontFamily: "Be Vietnam Pro",
              fontWeight: 700,
              lineHeight: "24px",
              wordWrap: "break-word",
            }}
          >
            Access and Limits
          </div>

          <div style={{ alignSelf: "stretch" }}>
            <span
              style={{
                color: "#473D5C",
                fontSize: 14,
                fontFamily: "Be Vietnam Pro",
                fontWeight: 400,
                lineHeight: "21px",
                wordWrap: "break-word",
              }}
            >
              We track API usage by API credits. Each API call charges a base
              cost of 1 credit, and then 1 additional credit per 2,500 bytes of
              additional data. The web-socket charges 1 credit per minute of
              use. All tiers are limited to 600 API calls per minute.
              <br />
              <br />

              All API calls require a bearer token in the header or URL.
              <br />
              <br />
              <a 
                href="https://pro.coincap.io/dashboard" 
                target="_blank" 
                rel="noopener noreferrer"
                style={{ textDecoration: "underline", color: "#473D5C" }}
              >
                Signup for a key
              </a>
              <br /><br />
            </span>

            <span
              style={{
                color: "#473D5C",
                fontSize: 16,
                fontFamily: "Be Vietnam Pro",
                fontWeight: 700,
                lineHeight: "19.2px",
                wordWrap: "break-word",
              }}
            >
              Free Tier
            </span>
            <ul
              style={{
                margin: "10px 0 10px 20px",
                color: "#473D5C",
                fontSize: 14,
                fontFamily: "Be Vietnam Pro",
                fontWeight: 400,
                lineHeight: "21px",
                listStyleType: "disc",
                listStylePosition: "outside",
              }}
            >
              <li>2,500 credits per month</li>
              <li>NO web-socket access</li>
            </ul>

            <span
              style={{
                color: "#473D5C",
                fontSize: 16,
                fontFamily: "Be Vietnam Pro",
                fontWeight: 700,
                lineHeight: "19.2px",
                wordWrap: "break-word",
              }}
            >
              Basic Tier
            </span>
            <ul
              style={{
                margin: "10px 0 10px 20px",
                color: "#473D5C",
                fontSize: 14,
                fontFamily: "Be Vietnam Pro",
                fontWeight: 400,
                lineHeight: "21px",
                listStyleType: "disc",
                listStylePosition: "outside",
              }}
            >
              <li>50,000 credits per month</li>
              <li>NO web-socket access</li>
            </ul>

            <span
              style={{
                color: "#473D5C",
                fontSize: 16,
                fontFamily: "Be Vietnam Pro",
                fontWeight: 700,
                lineHeight: "19.2px",
                wordWrap: "break-word",
              }}
            >
              Growth Tier
            </span>
            <ul
              style={{
                margin: "10px 0 10px 20px",
                color: "#473D5C",
                fontSize: 14,
                fontFamily: "Be Vietnam Pro",
                fontWeight: 400,
                lineHeight: "21px",
                listStyleType: "disc",
                listStylePosition: "outside",
              }}
            >
              <li>150,000 credits per month</li>
              <li>Full web-socket access</li>
            </ul>

            <span
              style={{
                color: "#473D5C",
                fontSize: 16,
                fontFamily: "Be Vietnam Pro",
                fontWeight: 700,
                lineHeight: "19.2px",
                wordWrap: "break-word",
              }}
            >
              Professional Tier
            </span>
            <ul
              style={{
                margin: "10px 0 10px 20px",
                color: "#473D5C",
                fontSize: 14,
                fontFamily: "Be Vietnam Pro",
                fontWeight: 400,
                lineHeight: "21px",
                listStyleType: "disc",
                listStylePosition: "outside",
              }}
            >
              <li>450,00 credits per month</li>
              <li>Full web-socket access</li>
            </ul>

            <span
              style={{
                color: "#473D5C",
                fontSize: 16,
                fontFamily: "Be Vietnam Pro",
                fontWeight: 700,
                lineHeight: "19.2px",
                wordWrap: "break-word",
              }}
            >
              Enterprise Tier
            </span>
            <ul
              style={{
                margin: "10px 0 10px 20px",
                color: "#473D5C",
                fontSize: 14,
                fontFamily: "Be Vietnam Pro",
                fontWeight: 400,
                lineHeight: "21px",
                listStyleType: "disc",
                listStylePosition: "outside",
              }}
            >
              <li>3,000,000 credits per month</li>
              <li>Full web-socket access</li>
            </ul>
          </div>

          <div
            style={{
              color: "#190C33",
              fontSize: 30,
              fontFamily: "Be Vietnam Pro",
              fontWeight: 700,
              lineHeight: "36px",
              wordWrap: "break-word",
            }}
          >
            REST API Documentation
          </div>

          <div
            style={{
              color: "#473D5C",
              fontSize: 14,
              fontFamily: "Be Vietnam Pro",
              fontWeight: 400,
              lineHeight: "21px",
              wordWrap: "break-word",
              alignSelf: "stretch",
            }}
          >
            Our REST API can be accessed via the following URL:
            <strong> rest.coincap.io/v3/... </strong>
            <br />
            We have included our OpenAPI Spec & Swagger documentation below where you
            can test and experiment with API V3. Please note that using these
            Swagger components also requires a bearer token included in the
            header. To automatically add your API key to each call made through
            Swagger, click the ‘Authorize’ button below and paste in your API
            key.
          </div>

          <SwaggerComponent />

          <div
            style={{
              color: "#190C33",
              fontSize: 30,
              fontFamily: "Be Vietnam Pro",
              fontWeight: 700,
              lineHeight: "36px",
              wordWrap: "break-word",
            }}
          >
            Web-socket Documentation
          </div>

          <div
            style={{
              color: "#473D5C",
              fontSize: 14,
              fontFamily: "Be Vietnam Pro",
              fontWeight: 400,
              lineHeight: "21px",
              wordWrap: "break-word",
            }}
          >
            The web-socket is available through the Growth, Professional, and
            Enterprise tiers. Using the web-socket consumes 1 credit per minute
            the web-socket is open.
            <br />
            <br />
            The web-socket can be accessed via the following URL:{" "}
            <strong>wss://wss.coincap.io/</strong>
          </div>

          <div
            style={{
              color: "#190C33",
              fontSize: 20,
              fontFamily: "Be Vietnam Pro",
              fontWeight: 700,
              lineHeight: "24px",
              wordWrap: "break-word",
            }}
          >
            Trades Web-socket
          </div>

          <div style={{ alignSelf: "stretch" }}>
            <span
              style={{
                color: "#473D5C",
                fontSize: 14,
                fontFamily: "Be Vietnam Pro",
                fontWeight: 400,
                lineHeight: "21px",
                wordWrap: "break-word",
              }}
            >
              The CoinCap trade web-socket streams trades from other
              cryptocurrency exchange web-sockets. Users must select a specific
              exchange. In the /exchanges endpoint, users can determine if an
              exchange has a socket available by noting the response 
              <em> “socket”:true/false</em>. See an example in the /exchanges
              endpoint documentation above. The trades web-socket is the only
              way to receive individual trade data through CoinCap.
            </span>
            <br/>
            <br/>

            <div
              style={{
                margin: "10px 0 10px 20px",
                color: "#473D5C",
                fontSize: 14,
                fontFamily: "Be Vietnam Pro",
                fontWeight: 400,
                lineHeight: "21px",
                listStyleType: "disc",
                listStylePosition: "outside",
              }}
            >
            Example usage with websocat:
            <br/>
            <br/>
              <li>
              <strong>websocat "wss://wss.coincap.io/trades/gdax&apiKey=YourApiKey"</strong>
              </li>
              </div>
          </div>

          <div
            style={{
              color: "#190C33",
              fontSize: 20,
              fontFamily: "Be Vietnam Pro",
              fontWeight: 700,
              lineHeight: "24px",
              wordWrap: "break-word",
            }}
          >
            Prices Web-socket
          </div>

          <div style={{ alignSelf: "stretch" }}>
            <span
              style={{
                color: "#473D5C",
                fontSize: 14,
                fontFamily: "Be Vietnam Pro",
                fontWeight: 400,
                lineHeight: "21px",
                wordWrap: "break-word",
              }}
            >
              The CoinCap prices endpoint is the most accurate source of
              real-time changes to the global price of an asset. Each time the
              system receives data that moves the global price in one direction
              or another, this change is immediately published through the
              websocket. These prices correspond with the values shown in /assets
              – a value that may change several times per second based on market
              activity.
            </span>
            <br/>
            <br/>
            <div
              style={{
                margin: "10px 0 10px 20px",
                color: "#473D5C",
                fontSize: 14,
                fontFamily: "Be Vietnam Pro",
                fontWeight: 400,
                lineHeight: "21px",
                listStyleType: "disc",
                listStylePosition: "outside",
              }}
            >
            Example usage with websocat:
            <br/>
            <br/>
            
              <li>
                <strong>websocat "wss://wss.coincap.io/prices?assets=bitcoin,usdc&apiKey=XXX"</strong>
              </li>
              <br />
              <li>
                <strong>websocat "wss://wss.coincap.io/prices?assets=ALL&apiKey=YourApiKey"</strong>
              </li>
              </div>
          </div>

          <div
            style={{
              color: "#190C33",
              fontSize: 20,
              fontFamily: "Be Vietnam Pro",
              fontWeight: 700,
              lineHeight: "24px",
              wordWrap: "break-word",
            }}
          >
            V2 API Docs
          </div>
          <div
            style={{
              color: "#190C33",
              fontFamily: "Be Vietnam Pro",
              lineHeight: "24px",
              wordWrap: "break-word",
            }}
          >
                        <span
              style={{
                color: "#473D5C",
                fontSize: 14,
                fontFamily: "Be Vietnam Pro",
                fontWeight: 400,
                lineHeight: "21px",
                wordWrap: "break-word",
              }}
            >
            Documentation for our deprecated v2 API can be found here:{' '}
            <a
              href="https://docs.coincap.io/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'underline', color: '#473D5C' }}
            >
              https://docs.coincap.io/
            </a></span>
          </div>        
        </div>
      </div>
    </Layout>
  );
};

export default ApiDocs;
