// UpdateAuthMethodModal.tsx
import React, { useState, useEffect } from "react";
import axios from "axios";
import { env, useGlobalState } from "../../helpers/GlobalStateProvider";
import { useModalState } from "../../helpers/ModalProvider";
import { apiUrls } from "../../config";
import ModalWrapper from "./ModalWrapper";

const UpdateAuthMethodModal: React.FC = () => {
  // From your modal provider:
  const { isUpdateAuthModalVisible, closeUpdateAuthModal, showErrorMessage } =
    useModalState();
  // From your global state (for user info & refresh):
  const { info, refreshAccountInfo } = useGlobalState();

  // Local form states:
  const [newEmail, setNewEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  // Always call useEffect, but conditionally do setup inside it
  useEffect(() => {
    // If modal not visible, just return
    if (!isUpdateAuthModalVisible) {
      return;
    }
    // When the modal becomes visible, reset fields
    setNewEmail(info?.email || "");
    setCurrentPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
  }, [info?.email, isUpdateAuthModalVisible]);

  // Submit handler
  const handleSubmit = async () => {
    if (newPassword && newPassword !== confirmNewPassword) {
      alert("New passwords do not match.");
      return;
    }

    // Basic token/env checks
    const bearerToken = localStorage.getItem("bearerToken");
    if (!bearerToken) {
      showErrorMessage(`No bearer token found. Please sign in first.`);
      return;
    }
    // Attempt to update email/password
    try {
      const response = await axios.post(
        `${apiUrls[env]}/v3/apiKey/update_email`,
        { newEmail, currentPassword, newPassword },
        { headers: { Authorization: `Bearer ${bearerToken}` } },
      );
      console.log("Email updated successfully:", response.data);

      // Refresh user info so the new email shows up
      await refreshAccountInfo();

      // Close modal on success
      closeUpdateAuthModal();
    } catch (err: any) {
      showErrorMessage(
        `An unexpected error occurred. ${err.response?.data?.message}`,
      );
    }
  };

  // Close handler (backdrop or "X" button)
  const handleClose = () => {
    closeUpdateAuthModal();
  };

  // If the modal is not visible, we render nothing
  if (!isUpdateAuthModalVisible) {
    return null;
  }

  return (
    <ModalWrapper onBackdropClick={handleClose}>
      <div
        style={{
          position: "fixed",
          inset: 0,
          background: "rgba(0,0,0,0.4)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 9999,
        }}
        onClick={handleClose}
      >
        <div
          style={{
            width: "400px",
            background: "white",
            borderRadius: "16px",
            padding: "24px",
            position: "relative",
          }}
          onClick={(e) => e.stopPropagation()} // don't close when clicking inside
        >
          <h2 style={{ margin: 0, marginBottom: "16px" }}>
            Update Authentication Method
          </h2>

          <div
            style={{ display: "flex", flexDirection: "column", gap: "12px" }}
          >
            {/* Update email address */}
            <div>
              <label
                htmlFor="newEmail"
                style={{
                  display: "block",
                  marginBottom: "4px",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                Update email address
              </label>
              <input
                id="newEmail"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                style={{
                  width: "100%",
                  height: "42px",
                  padding: "8px",
                  borderRadius: "8px",
                  border: "1px solid #D1CED6",
                }}
              />
            </div>

            {/* Current password */}
            <div>
              <label
                htmlFor="currentPassword"
                style={{
                  display: "block",
                  marginBottom: "4px",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                Enter current password
              </label>
              <input
                id="currentPassword"
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                style={{
                  width: "100%",
                  height: "42px",
                  padding: "8px",
                  borderRadius: "8px",
                  border: "1px solid #D1CED6",
                }}
              />
            </div>

            {/* New password */}
            <div>
              <label
                htmlFor="newPassword"
                style={{
                  display: "block",
                  marginBottom: "4px",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                Enter new password
              </label>
              <input
                id="newPassword"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                style={{
                  width: "100%",
                  height: "42px",
                  padding: "8px",
                  borderRadius: "8px",
                  border: "1px solid #D1CED6",
                }}
              />
            </div>

            {/* Confirm new password */}
            <div>
              <label
                htmlFor="confirmNewPassword"
                style={{
                  display: "block",
                  marginBottom: "4px",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                Confirm new password
              </label>
              <input
                id="confirmNewPassword"
                type="password"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                style={{
                  width: "100%",
                  height: "42px",
                  padding: "8px",
                  borderRadius: "8px",
                  border: "1px solid #D1CED6",
                }}
              />
            </div>
          </div>

          {/* Save button */}
          <button
            onClick={handleSubmit}
            style={{
              marginTop: "24px",
              width: "100%",
              padding: "12px",
              background: "#5061AC",
              color: "#FFFFFF",
              fontSize: "14px",
              fontWeight: 600,
              borderRadius: "8px",
              border: "none",
              cursor: "pointer",
            }}
          >
            Save
          </button>

          {/* Close ("X") button */}
          <button
            onClick={handleClose}
            style={{
              position: "absolute",
              top: "16px",
              right: "16px",
              background: "transparent",
              border: "none",
              cursor: "pointer",
              fontSize: "18px",
            }}
          >
            &times;
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default UpdateAuthMethodModal;
