import React, { useEffect, useRef, useState } from "react";

interface InputFieldProps {
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  handleSendMessage: () => void;
  loading: boolean;
}

const InputField: React.FC<InputFieldProps> = ({
  inputValue,
  setInputValue,
  handleSendMessage,
  loading,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
      adjustTextareaHeight();
    }
  }, []);

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Reset height to recalculate
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevents adding a new line
      handleSend();
    }
  };

  const handleSend = () => {
    handleSendMessage();
    setInputValue(""); // Clear the text area
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Reset textarea height
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "end",
        padding: "12px 16px",
        background: "#EBEDF1",
        borderRadius: "20px",
        border: "1px solid #8D8D8D",
      }}
    >
      <textarea
        ref={textareaRef}
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
          adjustTextareaHeight();
        }}
        onKeyDown={handleKeyDown}
        placeholder="Ask a question..."
        style={{
          alignSelf: "center",
          flex: 1,
          border: "none",
          outline: "none",
          background: "transparent",
          fontSize: "16px",
          color: "black", // Changed to black
          resize: "none", // Prevent manual resizing
          lineHeight: "1.5",
          overflow: "hidden",
        }}
        rows={1}
      />
      <button
        onClick={handleSend}
        disabled={loading || inputValue.trim() === ""}
        style={{
          padding: "12px",
          background:
            inputValue.trim() === ""
              ? "rgba(104, 123, 206, 0.48)"
              : "linear-gradient(69deg, #697CCF 0%, #5F9EE7 100%)",
          color: "#FFFFFF",
          borderRadius: "12px",
          border: "none",
          cursor:
            inputValue.trim() === "" || loading ? "not-allowed" : "pointer",
        }}
      >
        <img
          src="/external/arrow-up.svg"
          alt="Send"
          style={{ width: "16px", height: "16px" }}
        />
      </button>
    </div>
  );
};

export default InputField;
