// AddEmailModal.tsx
import React, { useState } from "react";
import axios from "axios";
import { env, useGlobalState } from "../../helpers/GlobalStateProvider";
import { useModalState } from "../../helpers/ModalProvider";
import { apiUrls } from "../../config";

const AddEmailModal: React.FC = () => {
  // Pull up whether the modal is visible and how to close it
  const { isAddEmailModalVisible, closeAddEmailModal, showErrorMessage } =
    useModalState();

  // We need to refresh account info after adding an email
  const { refreshAccountInfo } = useGlobalState();

  // Local form states
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // If the modal is not visible, render nothing
  if (!isAddEmailModalVisible) {
    return null;
  }

  const handleAddEmail = async () => {
    const bearerToken = localStorage.getItem("bearerToken");
    if (!bearerToken) {
      showErrorMessage("You need to be logged in to add an email.");
      return;
    }

    try {
      const response = await axios.post(
        `${apiUrls[env]}/v3/apiKey/associate_email`,
        { email, password },
        { headers: { Authorization: `Bearer ${bearerToken}` } },
      );
      console.log("Email associated successfully:", response.data);

      // Refresh user info so the newly added email shows up
      await refreshAccountInfo();

      // Close the modal on success
      closeAddEmailModal();
    } catch (err: any) {
      if (err.response?.data?.message) {
        showErrorMessage(err.response.data.message);
      } else {
        showErrorMessage("An unexpected error occurred. Please try again.");
      }
    }
  };

  const handleCancel = () => {
    setEmail("");
    setPassword("");
    closeAddEmailModal();
  };

  return (
    <div
      style={{
        position: "fixed",
        inset: 0,
        background: "rgba(0,0,0,0.4)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
      }}
      onClick={handleCancel}
    >
      <div
        style={{
          width: "400px",
          background: "white",
          borderRadius: "16px",
          padding: "24px",
          position: "relative",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <h2 style={{ margin: 0, marginBottom: "16px" }}>Add Email</h2>
        <p style={{ fontSize: "14px", color: "#473D5C", marginBottom: "16px" }}>
          Enter the email and password you want to associate:
        </p>

        {/* Simple Input Fields */}
        <input
          style={{
            width: "100%",
            padding: "8px",
            marginBottom: "8px",
            borderRadius: "6px",
            border: "1px solid #ddd",
          }}
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          style={{
            width: "100%",
            padding: "8px",
            marginBottom: "8px",
            borderRadius: "6px",
            border: "1px solid #ddd",
          }}
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        {/* Action Buttons */}
        <div
          style={{ display: "flex", justifyContent: "flex-end", gap: "16px" }}
        >
          <button
            onClick={handleCancel}
            style={{
              padding: "8px 16px",
              background: "transparent",
              border: "2px solid #5061AC",
              borderRadius: "8px",
              color: "#5061AC",
              fontWeight: 600,
              cursor: "pointer",
            }}
          >
            Cancel
          </button>
          <button
            onClick={handleAddEmail}
            style={{
              padding: "8px 16px",
              background: "#5061AC",
              borderRadius: "8px",
              color: "#FFFFFF",
              fontWeight: 600,
              border: "none",
              cursor: "pointer",
            }}
          >
            Add
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddEmailModal;
