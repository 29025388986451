import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import BigNumber from "bignumber.js";
import StakeModal1UI from "./UISection";
import {
  getBuilderPoolId,
  getMorTokenAddress,
  getMorStakerAddress,
} from "@coincap/common2/dist/helpers";
import axios from "axios";
import { apiKeys, apiUrls } from "../../../config";
import { env, useGlobalState } from "../../../helpers/GlobalStateProvider";
import { useModalState } from "../../../helpers/ModalProvider";
import { erc20Abi } from "@coincap/common2/dist/erc20Abi";
import { useWalletClient } from "wagmi";
import { stakingAbi } from "@coincap/common2/dist/stakingAbi";

interface StakeModal1Props {
  handleClose: () => void;
  onContinue: () => void;
  setApprovalDone: any;
  handleApproveContinue: () => void;
  setApprovalTxid: any;
}

/** Stake MOR tokens */
const broadcastStakeTx = async (
  walletClient: any,
  amount: ethers.BigNumberish,
  currentChainId: number
) => {
  if (!walletClient) {
    console.log("No wallet connected");
    return;
  }
  const provider = new ethers.BrowserProvider(walletClient);
  const stakerAddress = getMorStakerAddress(currentChainId);
  const builderPoolId = getBuilderPoolId(currentChainId);
  const signer = await provider.getSigner();
  const contract = new ethers.Contract(stakerAddress, stakingAbi, signer);

  const tx = await contract.deposit(builderPoolId, amount);
  console.log("Transaction sent:", tx.hash);
  return tx.hash;
};

/** Unstake MOR tokens */
const broadcastUnstakeTx = async (
  walletClient: any,
  amount: ethers.BigNumberish,
  currentChainId: number
) => {
  if (!walletClient) {
    console.log("No wallet connected");
    return;
  }
  const provider = new ethers.BrowserProvider(walletClient);
  const stakerAddress = getMorStakerAddress(currentChainId);
  const builderPoolId = getBuilderPoolId(currentChainId);
  const signer = await provider.getSigner();
  const contract = new ethers.Contract(stakerAddress, stakingAbi, signer);

  const tx = await contract.withdraw(builderPoolId, amount);
  return tx.hash;
};

/** Check allowance */
const checkApproval = async (walletClient: any, currentChainId: number) => {
  if(!walletClient) {
    console.log("No wallet connected");
    return;
  }

  const provider = new ethers.BrowserProvider(walletClient);
  const stakerAddress = getMorStakerAddress(currentChainId);
  const morTokenAddress = getMorTokenAddress(currentChainId);
  const signer = await provider.getSigner();
  const contract = new ethers.Contract(morTokenAddress, erc20Abi, signer);
  const ownerAddress = await signer.getAddress();
  const allowance = await contract.allowance(ownerAddress, stakerAddress);
  return allowance;
};

/** Approve staker contract */
const triggerApprove = async (
  walletClient: any,
  setApprovalTxid: any,
  currentChainId: number
) => {
  if (!walletClient) {
    console.error("No wallet connected");
    return;
  }
  const provider = new ethers.BrowserProvider(walletClient);
  const signer = await provider.getSigner();

  const abi = [
    "function approve(address spender, uint256 amount) public returns (bool)",
  ];

  const stakerAddress = getMorStakerAddress(currentChainId);
  const morTokenAddress = getMorTokenAddress(currentChainId);

  const contract = new ethers.Contract(morTokenAddress, abi, signer);

  try {
    // Approve max
    const tx = await contract.approve(stakerAddress, ethers.MaxUint256);
    setApprovalTxid(tx.hash);
    console.log("Transaction sent:", tx.hash);

    await tx.wait();
    console.log("Transaction confirmed");
  } catch (error) {
    console.error("Error approving tokens:", error);
  }
};

const StakingModal1: React.FC<StakeModal1Props> = ({
  handleClose,
  onContinue,
  handleApproveContinue,
  setApprovalDone,
  setApprovalTxid,
}) => {
  const [infoExpanded, setInfoExpanded] = useState(false);
  const [needsApproval, setNeedsApproval] = useState(false);
  const [usdPerMor, setUsdPerMor] = useState(0);
  const [usdPerEth, setUsdPerEth] = useState(0);
  const [gasPriceGwei, setGasPriceGwei] = useState(0);

  // -------------------
  // CHANGED HERE: store balances as string (instead of number)
  // -------------------
  const [availableMor, setAvailableMor] = useState<string>("0");
  const [depositedCurrentChainMor, setDepositedCurrentChainMor] = useState<string>("0");
  const [depositedBaseMor, setDepositedBaseMor] = useState<string>("0");
  const [depositedArbitrumMor, setDepositedArbitrumMor] = useState<string>("0");
  const [depositedTotalMor, setDepositedTotalMor] = useState<string>("0");

  const [continueClicked, setContinueClicked] = useState(false);

  const { setTransactionDetails, info, selectedChainId, checkNetwork } = useGlobalState();
  const { stakeModalDetails, showErrorMessage, handleCloseStakeModal } = useModalState();
  const [isDeposit, setIsDeposit] = useState(!stakeModalDetails?.isUnstake);

  // STILL store `amount` as string
  const [amount, setAmount] = useState<string>(
    stakeModalDetails?.amount ? String(stakeModalDetails?.amount) : "1"
  );

  const { data: walletClient } = useWalletClient();
  const [lastDepositTime, setLastDepositTime] = useState<string | undefined>();

  useEffect(() => {
    if (info?.walletAddress && walletClient?.account?.address) {
      if (
        info?.walletAddress?.toLowerCase() !==
        walletClient?.account?.address?.toLowerCase()
      ) {
        console.log(
          "Wallet address mismatch:",
          info?.walletAddress,
          walletClient?.account?.address
        );
        showErrorMessage(
          `Wallet address mismatch. Switch browser wallet to ${info?.walletAddress?.toLowerCase()}`
        );
        handleCloseStakeModal();
      }
    }
  }, [info?.walletAddress, walletClient?.account?.address]);

  useEffect(() => {
    (async () => {
      if (!info?.walletAddress) return;
      if (!walletClient) return;

      const provider = new ethers.BrowserProvider(walletClient);
      const signer = await provider.getSigner();

      const fetchBalances = async () => {
        try {
          const stakerAddress = getMorStakerAddress(selectedChainId);
          const morTokenAddress = getMorTokenAddress(selectedChainId);

          const morContract = new ethers.Contract(
            morTokenAddress,
            ["function balanceOf(address owner) view returns (uint256)"],
            signer
          );

          // Keep the full precision as a string
          const morBalance = await morContract.balanceOf(info?.walletAddress);
          setAvailableMor(ethers.formatUnits(morBalance, 18));  // string

          const feeData = await provider.getFeeData();
          const gasPrice = feeData.gasPrice;
          setGasPriceGwei(parseFloat(ethers.formatUnits(gasPrice ?? "0", "gwei")));

          // The staked info from your `info` object might be a float or a number
          // For minimal changes, just convert to string:
          const arbitrumDeposited = String(info?.stakedArbitrumBalance ?? '0');
          const baseDeposited = String(info?.stakedBaseBalance ?? '0');

          const deposited =
            selectedChainId === 8453 ? baseDeposited : arbitrumDeposited;

          // For total, we can do parseFloat + string, or just bigNumber. We'll keep it minimal:
          const totalDeposited =
            parseFloat(arbitrumDeposited) + parseFloat(baseDeposited);

          const stakerContract = new ethers.Contract(
            stakerAddress,
            stakingAbi,
            signer
          );
          const builderPoolId = getBuilderPoolId(selectedChainId);
          const userData = await stakerContract.usersData(
            info?.walletAddress,
            builderPoolId
          );
          setLastDepositTime(userData[0].toString());

          // MOR price
          try {
            const result1 = await axios.get(`https://rest.coincap.io/v3/assets/morpheus?apiKey=dc86b3f37a5dc009dfa6c1c47514303ba6679d683dfada84ca8bc6c535421c2e`);
            const priceUsd1 = parseFloat(result1.data.data.priceUsd);
            setUsdPerMor(priceUsd1);
          } catch (err: any) {
            showErrorMessage(
              `Error getting mor price. Defaulting to 15. ${err?.response?.data?.message}`
            );
            console.log("error getting MOR price, defaulting to 15");
            setUsdPerMor(15); // fallback
          }

          // ETH price
          const result2 = await axios.get(`https://rest.coincap.io/v3/assets/ethereum?apiKey=dc86b3f37a5dc009dfa6c1c47514303ba6679d683dfada84ca8bc6c535421c2e`);
          const priceUsd2 = parseFloat(result2.data.data.priceUsd);
          setUsdPerEth(priceUsd2);

          // Store everything as strings
          setDepositedCurrentChainMor(deposited);
          setDepositedBaseMor(baseDeposited);
          setDepositedArbitrumMor(arbitrumDeposited);
          setDepositedTotalMor(String(totalDeposited));
          setApprovalTxid();

        } catch (err: any) {
          showErrorMessage(`Error getting MOR info: ${err}`);
        }
      };

      // Check allowance against the typed amount
      const amountBn = new BigNumber(amount || "0");
      const normalizedAmount = amountBn.shiftedBy(18); // *10^18

      const approvedAmount = await checkApproval(walletClient, selectedChainId);
      if (new BigNumber(approvedAmount?.toString() ?? "0").lt(normalizedAmount)) {
        setNeedsApproval(true);
        setApprovalDone(false);
      } else {
        setApprovalDone(true);
        setNeedsApproval(false);
      }

      await fetchBalances();
    })();
  }, [amount, info?.walletAddress, selectedChainId]);

  const handleStake = async () => {
    if (!walletClient) {
      console.log("No wallet provider available cannot stake");
      showErrorMessage("No wallet provider available cannot stake");
      return;
    }

    const networkOk = await checkNetwork();
    if (!networkOk) return;

    if (amount) {
      const amountBn = new BigNumber(amount || "0");
      const approvedAmount = await checkApproval(walletClient, selectedChainId);
      const normalizedAmount = amountBn.shiftedBy(18);

      if (new BigNumber(approvedAmount?.toString() ?? "0").lt(normalizedAmount)) {
        await triggerApprove(walletClient, setApprovalTxid, selectedChainId);
        setApprovalDone(true);
      }

      // broadcast stake
      const amountInWei = ethers.parseUnits(amountBn.toFixed(), 18);
      const tx = await broadcastStakeTx(walletClient, amountInWei, selectedChainId);

      // For UI logging
      const morAmount = amountBn.toNumber(); // caution for big amounts
      const morPrice = usdPerMor;
      const ethPrice = usdPerEth;
      const ethFee = new BigNumber(estimatedFeeEth).toNumber();

      setTransactionDetails({
        tx,
        morAmount,
        morPrice,
        ethFee,
        ethPrice,
        txType: "stake",
      });
    }
  };

  const handleUnstake = async () => {
    if (!walletClient) {
      console.log("No wallet provider available cannot stake");
      showErrorMessage("No wallet provider available cannot stake");
      return;
    }

    const networkOk = await checkNetwork();
    if (!networkOk) return;

    if (amount) {
      const amountBn = new BigNumber(amount || "0");
      const amountInWei = ethers.parseUnits(amountBn.toFixed(), 18);
      const tx = await broadcastUnstakeTx(walletClient, amountInWei, selectedChainId);

      // For UI logging
      const morAmount = amountBn.toNumber();
      const morPrice = usdPerMor;
      const ethPrice = usdPerEth;
      const ethFee = new BigNumber(estimatedFeeEth).toNumber();

      setTransactionDetails({
        tx,
        morAmount,
        morPrice,
        ethFee,
        ethPrice,
        txType: "unstake",
      });
    }
  };

  const estimatedFeeEth = new BigNumber(gasPriceGwei)
    .multipliedBy(200000)
    .div(1e9)
    .toNumber();
  const estimatedFeeUsd = Math.ceil(estimatedFeeEth * usdPerEth * 100) / 100;

  // For display or small calculations, parse them:
  const numericAmount = parseFloat(amount) || 0;
  const numericAvailableMor = parseFloat(availableMor) || 0;
  const numericDepositedCurrentChainMor = parseFloat(depositedCurrentChainMor) || 0;
  const numericTotalDeposited = parseFloat(depositedTotalMor) || 0;

  const newStakedAmount = isDeposit
    ? numericDepositedCurrentChainMor + numericAmount
    : numericDepositedCurrentChainMor - numericAmount;

  const newTotalStakedAmount = isDeposit
    ? numericTotalDeposited + numericAmount
    : numericTotalDeposited - numericAmount;

  let apiTier = "Free";
  if (newTotalStakedAmount >= 100) {
    apiTier = "Enterprise";
  } else if (newTotalStakedAmount >= 50) {
    apiTier = "Professional";
  } else if (newTotalStakedAmount >= 15) {
    apiTier = "Growth";
  } else if (newTotalStakedAmount >= 5) {
    apiTier = "Basic";
  }

  const pointsPerDay = (() => {
    switch (apiTier) {
      case "Enterprise":
        return newTotalStakedAmount * 25;
      case "Professional":
        return newTotalStakedAmount * 20;
      case "Growth":
        return newTotalStakedAmount * 15;
      case "Basic":
        return newTotalStakedAmount * 10;
      case "Free":
      default:
        return newTotalStakedAmount * 2;
    }
  })();

  const handleToggle = () => {
    setIsDeposit(!isDeposit);
  };

  // -------------------
  // CHANGED HERE: Use strings, so no precision lost
  // -------------------
  const handleMaxClick = () => {
    const currentTime = Date.now() / 1000;
    const oneWeek = 7 * 24 * 60 * 60;
    const canWithdraw =
      currentTime - oneWeek > new Date(parseInt(lastDepositTime ?? "0")).getTime();

    if (isDeposit) {
      // Use the string version
      setAmount(availableMor);
    } else {
      setAmount(canWithdraw ? depositedCurrentChainMor : "0");
    }
  };

  const handleInfoClick = () => {
    setInfoExpanded(!infoExpanded);
  };

  const showApprove = needsApproval && isDeposit;

  const continueButtonText = isDeposit
    ? needsApproval
      ? "Approve & Stake"
      : "Stake"
    : "Withdraw Mor";

  const isContinueDisabled =
    numericAmount <= 0 ||
    (isDeposit && numericAmount > numericAvailableMor) ||
    (!isDeposit && numericAmount > numericDepositedCurrentChainMor);

  const handleContinueClick = async () => {
    try {
      setContinueClicked(true);
      if (!isContinueDisabled) {
        if (isDeposit) {
          if (needsApproval) {
            await handleApproveContinue();
          }
          await handleStake();
        } else {
          await handleUnstake();
        }
        onContinue();
      }
    } catch (e) {
      console.log("handleContinueClick error", e);
      setContinueClicked(false);
    }
  };

  const totalCostUsd = numericAmount * usdPerMor + estimatedFeeUsd;

  console.log('depositedCurrentChainMor', depositedCurrentChainMor)
  return (
    <StakeModal1UI
      handleClose={handleClose}
      handleToggle={handleToggle}
      isDeposit={isDeposit}
      amount={amount} // pass as string
      setAmount={setAmount} // setter also uses string
      usdPerMor={usdPerMor}
      // pass these as strings now
      availableMor={availableMor}
      withdrawableMor={depositedCurrentChainMor}
      handleMaxClick={handleMaxClick}
      handleInfoClick={handleInfoClick}
      infoExpanded={infoExpanded}
      displayValue={(val: number) => (isNaN(val) ? 0 : val)}
      estimatedFeeEth={estimatedFeeEth}
      estimatedFeeUsd={estimatedFeeUsd}
      totalCostUsd={totalCostUsd}
      apiTier={apiTier}
      pointsPerDay={pointsPerDay}
      showApprove={showApprove}
      continueButtonText={continueButtonText}
      isContinueDisabled={isContinueDisabled}
      handleContinueClick={handleContinueClick}
      continueClicked={continueClicked}
      newStakedAmount={newStakedAmount}
      lastDepositTime={lastDepositTime}
    />
  );
};

export default StakingModal1;
