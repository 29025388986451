import React from "react";

type DeleteAccountSectionProps = {
  doDeleteAccount: () => void;
};

const DeleteAccountSection: React.FC<DeleteAccountSectionProps> = ({
  doDeleteAccount,
}) => {
  return (
    <div
      style={{
        border: "2px solid #FF4574",
        borderRadius: "24px",
        padding: "36px",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      {/* Title */}
      <h2
        style={{
          margin: 0,
          fontSize: "24px",
          fontWeight: 800,
          color: "#FF4574", // Use #FF4574 for heading
          lineHeight: "32px",
        }}
      >
        Delete Account
      </h2>

      {/* "Danger!" in bold before the "Deleting your account will cause the following:" */}
      <p
        style={{
          margin: 0,
          fontSize: "16px",
          fontWeight: 400,
          color: "#473D5C",
        }}
      >
        <strong>Danger!</strong> Deleting your account will cause the following:
      </p>

      {/* Bullet list */}
      <ul
        style={{
          margin: 0,
          paddingLeft: "20px",
          color: "#473D5C",
          fontSize: "14px",
          lineHeight: "20px",
        }}
      >
        <li>You will permanently lose all accrued CoinCap points.</li>
        <li>You will permanently lose all chat history.</li>
        <li>All API keys and API history will be deleted.</li>
      </ul>

      {/* Final line emphasizing irreversibility */}
      <p
        style={{
          margin: 0,
          fontSize: "14px",
          fontWeight: 700,
          color: "#473D5C",
        }}
      >
        This action cannot be undone!
      </p>

      {/* Delete button aligned to the right */}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "16px",
        }}
      >
        <button
          onClick={doDeleteAccount}
          style={{
            padding: "12px 20px",
            background: "#FF4574", // Button background
            borderRadius: "12px",
            color: "#FFFFFF", // White text
            fontSize: "14px",
            fontWeight: 600,
            border: "none",
            cursor: "pointer",
          }}
        >
          Delete My Account
        </button>
      </div>
    </div>
  );
};

export default DeleteAccountSection;
