export const apiUrls: Record<string, string> = {
  stage: "https://rest.staging.wagmi.productions",
  production: "https://rest.coincap.io",
     development: "https://rest.staging.wagmi.productions",
//development: "http://localhost:8098",
};

export const apiKeys: Record<string, string> = {
  stage: "d23b2201280fd9bd473c1dbf463bad4af2a35a19c3af2be92278f8c79a9b1264",
  production: "dc86b3f37a5dc009dfa6c1c47514303ba6679d683dfada84ca8bc6c535421c2e",
development: "d23b2201280fd9bd473c1dbf463bad4af2a35a19c3af2be92278f8c79a9b1264", // stage
};

export const siteUrls: Record<string, string> = {
  stage: "https://staging.wagmi.productions",
  production: "https://coincap.io",
  development: "http://localhost:8000",
};

export const swagggerDocUrls: Record<string, string> = {
  stage: "https://rest.staging.wagmi.productions/api-docs.json",
  production: "https://rest.coincap.io/api-docs.json",
  development: "http://localhost:8098/api-docs.json",
};

export const FIAT_ENABLED = true

//https://rest.coincap.io/api-docs/
