import React from "react";
import { useModalState } from "../../helpers/ModalProvider";

const ResetPasswordModal: React.FC = () => {
  const {
    isResetPasswordModalVisible,
    closeResetPasswordModal,
  } = useModalState();

  if (!isResetPasswordModalVisible) {
    return null;
  }

  const doResetPassword = async () => {
    window.open("https://mee6.xyz/en/i/S3ZfymIhLR", "_blank");
  };

  const onCancel = () => {
    closeResetPasswordModal();
  };

  return (
    <div
      style={{
        position: "fixed",
        inset: 0,
        background: "rgba(0, 0, 0, 0.4)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
      }}
      onClick={onCancel}
    >
      <div
        style={{
          width: "600px", // Increased width
          background: "white",
          borderRadius: "16px",
          padding: "32px",
          position: "relative",
          textAlign: "left",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <h2
          style={{
            color: "#5061AC",
            fontSize: "23px",
            fontWeight: 800,
            lineHeight: "1.5",
            marginBottom: "16px",
            letterSpacing: "-0.46px",
          }}
        >
          Reset Password
        </h2>
        <p
          style={{
            fontWeight: 700,
            color: "#190C33",
            fontSize: "16px",
            marginBottom: "24px",
            lineHeight: "1.5",
          }}
        >
          To reset your password, join our discord server and create a support ticket through the #create-a-ticket channel and a member of our team will help you reset your password.
        </p>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "16px",
          }}
        >
          <button
            onClick={onCancel}
            style={{
              borderRadius: "12px",
              border: "1px solid var(--Plum-100, #5061AC)",
              background: "var(--White, #FFF)",
              display: "flex",
              minWidth: "42px",
              minHeight: "42px",
              padding: "12px",
              justifyContent: "center",
              alignItems: "center",
              gap: "4px",
              color: "var(--Plum-100, #5061AC)",
              fontWeight: 600,
              cursor: "pointer",
            }}
          >
            Return to Login
          </button>
          <button
            onClick={doResetPassword}
            style={{
              padding: "8px 16px",
              background: "#5061AC",
              borderRadius: "12px",
              color: "#FFFFFF",
              fontWeight: 600,
              border: "none",
              cursor: "pointer",
            }}
          >
            Join Discord
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordModal;
