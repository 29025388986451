import React, { useMemo, useRef } from "react";
import UnlockDateSection from "./UnlockDateSection";
import {
  DialogBody,
  DialogCloseTrigger,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";
import {
  AlertDescription,
  AlertRoot,
  Box,
  Button,
  CardBody,
  CardRoot,
  Flex,
  Input,
  Link,
  SegmentGroupIndicator,
  SegmentGroupItem,
  SegmentGroupRoot,
  Stack,
  StackSeparator,
  Text,
} from "@chakra-ui/react";
import { Field } from "../../../components/ui/field";
import { InputGroup } from "../../../components/ui/input-group";
import { useModalState } from "../../../helpers/ModalProvider";

interface StakeModal1UIProps {
  handleClose: () => void;
  handleToggle: () => void;
  isDeposit: boolean;
  amount: string; // We store amount as a string for precision
  setAmount: (value: string) => void;
  usdPerMor: number;

  // CHANGED HERE: these are now strings
  availableMor: string;         
  withdrawableMor: string;

  handleMaxClick: () => void;
  handleInfoClick?: () => void;
  infoExpanded?: boolean;
  displayValue: (value: number) => number;
  estimatedFeeEth: number;
  estimatedFeeUsd: number;
  totalCostUsd: number;
  apiTier: string;
  pointsPerDay: number;
  showApprove: boolean;
  continueButtonText: string;
  isContinueDisabled: boolean;
  handleContinueClick: () => void;
  continueClicked: boolean;
  newStakedAmount: number;
  lastDepositTime: string | undefined;
}

const StakeModal1UI: React.FC<StakeModal1UIProps> = ({
  handleClose,
  handleToggle,
  isDeposit,
  amount,
  setAmount,
  usdPerMor,
  availableMor,
  withdrawableMor,
  handleMaxClick,
  handleInfoClick,
  infoExpanded,
  displayValue,
  estimatedFeeEth,
  estimatedFeeUsd,
  totalCostUsd,
  apiTier,
  pointsPerDay,
  showApprove,
  continueButtonText,
  isContinueDisabled,
  handleContinueClick,
  continueClicked,
  newStakedAmount,
  lastDepositTime,
}) => {

  console.log('withdrawableMor', withdrawableMor)
  const inputRef = useRef<HTMLInputElement>(null);
  const { setChainSelectionModalVisible } = useModalState();

  const apr = 0.2;
  const morRewardsPerYear = apr * newStakedAmount;
  const usdRewardsPerYear = morRewardsPerYear * usdPerMor;

  const currentTime = Date.now() / 1000;
  const oneWeek = 7 * 24 * 60 * 60;

  console.log('lastDepositTime', lastDepositTime)

  const originalDepositTime = new Date(parseInt(lastDepositTime ?? "0")).getTime();
  const oneWeekAgo = currentTime - oneWeek;
  const canWithdraw = oneWeekAgo > originalDepositTime;

  console.log('canWithdraw', canWithdraw)


  // Even though we have strings, let's parse them for display
  const numericAvailableMor = parseFloat(availableMor) || 0;
  const numericWithdrawableMor = parseFloat(withdrawableMor) || 0;
  const numericAmount = parseFloat(amount) || 0;

  // If not past one week, or if newStakedAmount is between 0 and 0.001, disable withdraw
  const extraDisabled =
    (!canWithdraw && !isDeposit) ||
    (newStakedAmount !== 0 && newStakedAmount < 0.001);

  const fieldHelperText = useMemo(() => {
    if (isDeposit) {
      return (
        <Flex width="full" justifyContent="space-between" gap={4}>
          <Link
            href="https://app.uniswap.org/swap"
            target="_blank"
            rel="noopener noreferrer"
          >
            Purchase MOR on Uniswap
          </Link>
          <div>Available MOR: {availableMor}</div>
        </Flex>
      );
    }
    console.log('withdrawableMor', withdrawableMor)
    return (
      <Flex width="full" justifyContent="space-between" gap={4}>
        <div>
          MOR Available to withdraw: {canWithdraw ? numericWithdrawableMor : 0}
        </div>
      </Flex>
    );
  }, [isDeposit, numericAvailableMor, numericWithdrawableMor, canWithdraw, availableMor, withdrawableMor]);

  const stakingSummary = useMemo(() => {
    return (
      <Stack gap={4} separator={<StackSeparator />}>
        <Stack gap={4}>
          <Flex width="full" justifyContent="space-between">
            <Text color="fg.muted">Staking Amount:</Text>
            <Text>
              {amount} MOR (${displayValue(numericAmount * usdPerMor).toFixed(2)})
            </Text>
          </Flex>
          <Flex width="full" justifyContent="space-between">
            <Text color="fg.muted">Estimated Fee:</Text>
            <Text>
              {displayValue(estimatedFeeEth).toFixed(8)} ETH ($
              {displayValue(estimatedFeeUsd).toFixed(2)})
            </Text>
          </Flex>
          <Flex width="full" justifyContent="space-between">
            <Text color="fg.muted">Total Cost:</Text>
            <Text>${displayValue(totalCostUsd).toFixed(2)}</Text>
          </Flex>
        </Stack>
        <Stack gap={4}>
          <Flex width="full" justifyContent="space-between">
            <Text color="fg.muted">New API Tier:</Text>
            <Text>{apiTier}</Text>
          </Flex>
          <Flex width="full" justifyContent="space-between">
            <Text color="fg.muted">New CoinCap Points:</Text>
            <Text>{displayValue(pointsPerDay).toFixed(4)}/day</Text>
          </Flex>
          <Flex width="full" justifyContent="space-between">
            <Text color="fg.muted">Estimated Yearly Return (20% APR):</Text>
            <Text>
              {displayValue(morRewardsPerYear).toFixed(2)} MOR ($
              {displayValue(usdRewardsPerYear).toFixed(2)})
            </Text>
          </Flex>
          <UnlockDateSection epochTime={Date.now() + 7 * 24 * 60 * 60 * 1000} />
        </Stack>
      </Stack>
    );
  }, [
    amount,
    apiTier,
    displayValue,
    estimatedFeeEth,
    estimatedFeeUsd,
    morRewardsPerYear,
    numericAmount,
    pointsPerDay,
    totalCostUsd,
    usdPerMor,
    usdRewardsPerYear,
  ]);

  const withdrawSummary = useMemo(() => {
    return (
      <Stack gap={4} separator={<StackSeparator />}>
        <Stack gap={4}>
          <Flex width="full" justifyContent="space-between">
            <Text color="fg.muted">Withdraw Amount:</Text>
            <Text>
              {numericAmount} MOR (${displayValue(numericAmount * usdPerMor).toFixed(2)})
            </Text>
          </Flex>
          <Flex width="full" justifyContent="space-between">
            <Text color="fg.muted">Estimated Fee:</Text>
            <Text>
              {displayValue(estimatedFeeEth).toFixed(8)} ETH ($
              {displayValue(estimatedFeeUsd).toFixed(2)})
            </Text>
          </Flex>
        </Stack>
        <Stack gap={4}>
          <Flex width="full" justifyContent="space-between">
            <Text color="fg.muted">New API Tier:</Text>
            <Text>{apiTier}</Text>
          </Flex>
          <Flex width="full" justifyContent="space-between">
            <Text color="fg.muted">New CoinCap Points:</Text>
            <Text>{displayValue(pointsPerDay).toFixed(4)}/day</Text>
          </Flex>
          <UnlockDateSection
            epochTime={
              parseInt(lastDepositTime ? lastDepositTime : "0") * 1000 +
              7 * 24 * 60 * 60 * 1000
            }
          />
        </Stack>
      </Stack>
    );
  }, [
    amount,
    apiTier,
    displayValue,
    estimatedFeeEth,
    estimatedFeeUsd,
    lastDepositTime,
    numericAmount,
    pointsPerDay,
    usdPerMor,
  ]);

  const dateIsPast10th = new Date() > new Date("2025-04-10T00:00:00Z");

  console.log('dateIsPast10th', dateIsPast10th)

  return (
    <>
      <DialogHeader>
        <DialogTitle>Stake MOR to CoinCap</DialogTitle>
        <DialogCloseTrigger />
      </DialogHeader>
      <DialogBody>
        <SegmentGroupRoot value={isDeposit ? "deposit" : "withdraw"} width="full">
          <SegmentGroupIndicator />
          <SegmentGroupItem
            value="deposit"
            onClick={() => {
              handleToggle();
              setAmount("1"); // Reset to "1" if switching to deposit
            }}
            flex={1}
            justifyContent="center"
          >
            {!dateIsPast10th ? 'Deposit' : 'Deposit (Locked)'} 
          </SegmentGroupItem>
          <SegmentGroupItem
            value="withdraw"
            onClick={() => {
              handleToggle();
              setAmount("0"); // Reset to "0" if switching to withdraw
            }}
            flex={1}
            justifyContent="center"
          >
            {!dateIsPast10th ? 'Withdraw' : 'Withdraw (Locked)'} 
          </SegmentGroupItem>
        </SegmentGroupRoot>
        <Stack mt={6} gap={6}>
          <Field
            label={`Amount to ${isDeposit ? "Stake" : "Unstake"}`}
            helperText={fieldHelperText}
          >
            <InputGroup
              width="full"
              endElement={
                <Button
                  size="xs"
                  variant="subtle"
                  colorPalette="green"
                  onClick={handleMaxClick}
                >
                  MAX
                </Button>
              }
              flex={1}
            >
              <Input
                ref={inputRef}
                type="text"
                size="lg"
                inputMode="decimal"
                value={amount}
                placeholder="0"
                onFocus={(e) => {
                  if (inputRef.current) {
                    inputRef.current.select();
                  }
                  if (parseFloat(amount) === 0) setAmount("");
                }}
                onBlur={(e) => {
                  if (e.target.value === "") setAmount("0");
                }}
                onChange={(e) => {
                  let { value } = e.target;
                  
                  // If user just types ".", interpret as "0."
                  if (value === ".") {
                    value = "0.";
                  }
                
                  // Regex: 
                  //   ^\d*            => any number of whole digits
                  //   (\.\d{0,18})?   => optional decimal point + up to 18 decimals
                  //   $               => end
                  const pattern = /^\d*(\.\d{0,18})?$/;
                
                  if (pattern.test(value)) {
                    setAmount(value);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === "Return") {
                    e.preventDefault();
                    if (!isContinueDisabled && !continueClicked) {
                      handleContinueClick();
                    }
                  }
                }}
              />
            </InputGroup>
          </Field>
          <CardRoot variant="subtle">
            <CardBody>{isDeposit ? stakingSummary : withdrawSummary}</CardBody>
          </CardRoot>
        </Stack>
      </DialogBody>
      <DialogFooter flexDirection="column" gap={4} width="full">
        {newStakedAmount !== 0 && newStakedAmount < 0.001 && newStakedAmount > 0 && (
          <AlertRoot status="warning">
            <AlertDescription>
              You cannot have less than 0.001 MOR staked
            </AlertDescription>
          </AlertRoot>
        )}

        {showApprove && !(newStakedAmount !== 0 && newStakedAmount < 0.001) && (
          <AlertRoot status="warning">
            <AlertDescription>
              Since this is your first time staking to CoinCap, you will be
              asked to sign two transactions. The first transaction is to
              approve the maximum amount of MOR that can be sent from your
              wallet to this staking contract. This signature{" "}
              <Box as="span" fontWeight="bold" fontStyle="italic">
                does NOT
              </Box>{" "}
              allow us to withdraw funds from your wallet and you will not be
              charged a network fee for the first transaction.
            </AlertDescription>
          </AlertRoot>
        )}
          {!dateIsPast10th &&(<AlertRoot status="warning">
            <AlertDescription>
            <Box as="span" fontWeight="bold">
                ATTENTION:
              </Box>{" "}Starting on April 10th, all staked MOR will be locked while the Morpheus team migrates to the v2 builder staking contract. Staked MOR can be withdrawn again on April 14th when the migration is complete. During this time, staking and withdrawal functionality will be disabled.
            </AlertDescription>
          </AlertRoot>)
          }
          {dateIsPast10th &&(<AlertRoot status="warning">
            <AlertDescription>
            <Box as="span" fontWeight="bold">
                ATTENTION:
              </Box>{" "}Staking and withdrawal functionality are disabled while the Morpheus team migrates to the v2 builder staking contract. These features will return again on April 14th when the migration is complete.
            </AlertDescription>
          </AlertRoot>)
          }
        <Flex width="100%" justifyContent="flex-end" gap={4}>
          <Button
            variant="outline"
            onClick={() => {
              setChainSelectionModalVisible(true);
              return handleClose();
            }}
          >
            Close
          </Button>
          <Button
            onClick={handleContinueClick}
            disabled={isContinueDisabled || continueClicked || extraDisabled || dateIsPast10th}
          >
            {continueButtonText}
          </Button>
        </Flex>
      </DialogFooter>
    </>
  );
};

export default StakeModal1UI;
