// DeleteChatModal.tsx
import React from "react";
import ModalWrapper from "./ModalWrapper"; // or wherever
import { useModalState } from "../../helpers/ModalProvider";
import { useGlobalState } from "../../helpers/GlobalStateProvider";

const DeleteChatModal: React.FC = () => {
  const {
    isDeleteChatModalVisible,
    deleteChatId,
    deleteChatName,
    closeDeleteChatModal,
  } = useModalState();

  const { setChatHistory, chatHistory, setSelectedChatId, setMessages } =
    useGlobalState();

  const deleteChat = (chatId: number) => {
    setChatHistory((prev: any) =>
      prev.filter((chat: any) => chat.id !== chatId),
    );
    if (chatId === deleteChatId) {
      const remainingChats = chatHistory.filter(
        (chat: any) => chat.id !== chatId,
      );
      if (remainingChats.length > 0) {
        setSelectedChatId(remainingChats[0].id);
      } else {
        setSelectedChatId(null);
        setMessages([]);
      }
    }
    closeDeleteChatModal();
  };

  // If not visible, return null
  if (!isDeleteChatModalVisible) {
    return null;
  }

  const handleDelete = () => {
    if (deleteChatId !== null) {
      deleteChat(deleteChatId);
    } else {
      closeDeleteChatModal();
    }
  };

  return (
    <ModalWrapper onBackdropClick={() => closeDeleteChatModal()}>
      {/* Remove the extra full-screen overlay DIV, because ModalWrapper provides it */}
      <div
        style={{
          width: 540,
          minHeight: 200,
          padding: 24,
          background: "white",
          boxShadow: "0px 6px 12px rgba(24, 12, 50, 0.12)",
          borderRadius: 24,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div
            style={{
              color: "#190C33",
              fontSize: 20,
              fontWeight: 600,
              lineHeight: "30px",
            }}
          >
            Delete Chat?
          </div>
          <div
            style={{
              marginTop: 20,
              color: "#190C33",
              fontSize: 14,
              lineHeight: "16.8px",
            }}
          >
            Are you sure you want to delete{" "}
            <span style={{ fontWeight: 600 }}>{deleteChatName}</span>?
          </div>
          <div
            style={{
              marginTop: 10,
              color: "#716D79",
              fontSize: 14,
              lineHeight: "17.36px",
            }}
          >
            This will permanently delete this conversation and remove all memory
            from your device.
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 16,
            marginTop: 30,
          }}
        >
          <button
            onClick={() => closeDeleteChatModal()}
            style={{
              height: 42,
              padding: "0 20px",
              background: "#EBEBEB",
              borderRadius: 12,
              border: "none",
              fontSize: 14,
              fontWeight: 400,
              color: "#716D79",
              cursor: "pointer",
            }}
          >
            Cancel
          </button>
          <button
            onClick={handleDelete}
            style={{
              height: 42,
              padding: "0 20px",
              background: "#FF4574",
              borderRadius: 12,
              border: "none",
              fontSize: 14,
              fontWeight: 400,
              color: "#FFFFFF",
              cursor: "pointer",
            }}
          >
            Delete
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default DeleteChatModal;
