import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { GlobalStateProvider } from "./helpers/GlobalStateProvider";
import { ModalProvider } from "./helpers/ModalProvider";

// Your other components:
import Dashboard from "./views/Dashboard";
import Signin from "./views/Signin";
import Signup from "./views/Signup";
import Settings from "./views/settings/Settings";
import ChatContainer from "./views/chatbot/ChatContainer";
import { ErrorToast } from "./helpers/ErrorToast";
import { CannotConnectWalletModal } from "./views/modals/CannotConnectWalletModal";
import DeleteAPIKeyModal from "./views/modals/DeleteApiKeyModal";
import EditAPIKeysModal from "./views/modals/EditApiKeyModal";
import { WhatIsStakingModal } from "./views/modals/WhatIsStakingModal";
import StakeModals from "./views/modals/StakeModals";
import DeleteAuthModal from "./views/modals/DeleteAuthModal";
import AddEmailModal from "./views/modals/AddEmailModal";
import UpdateAuthMethodModal from "./views/modals/UpdateAuthModal";
import RenameChatModal from "./views/modals/RenameChatModal";
import DeleteChatModal from "./views/modals/DeleteChatModal";
import { Provider } from "./components/ui/provider";
import DeleteAccountModal from "./views/modals/DeleteAccountModal";
import ResetPasswordModal from "./views/modals/ResetPasswordModal";
import { ChainSelectionModal } from "./views/modals/ChainSelectionModal";
import ClaimBroadcastModal from "./views/modals/ClaimBroadcastModal";
import ApiDocs from "./views/ApiDocs";
import AprilMorUpgradeModal from "./views/modals/AprilMorUpgradeModal";

console.log('start')
const RedirectToExternal: React.FC<{ url: string }> = ({ url }) => {
  React.useEffect(() => {
    window.location.href = url;
  }, [url]);

  return null; // Render nothing as the page is being redirected
};

const App: React.FC = () => {
  return (
    <Provider>
      <Router>
        <ModalProvider>
          <GlobalStateProvider>

            <Routes>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/signin" element={<Signin />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/chatbot" element={<ChatContainer />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/api-docs" element={<ApiDocs />} />
              <Route path="*" element={<RedirectToExternal url="https://splash.coincap.io" />} />

            </Routes>

              {/* Modals */}
              <DeleteAPIKeyModal />
              <EditAPIKeysModal />
              <WhatIsStakingModal />
              <StakeModals />
              <DeleteAuthModal />
              <AddEmailModal />
              <UpdateAuthMethodModal />
              <RenameChatModal />
              <DeleteChatModal />
              <CannotConnectWalletModal />
              <DeleteAccountModal />
              <ResetPasswordModal />
              <ChainSelectionModal />
              <ClaimBroadcastModal />
              <AprilMorUpgradeModal />

              {/* Toast */}
              <ErrorToast />

          </GlobalStateProvider>
        </ModalProvider>
      </Router>
    </Provider>
  );
};

export default App;
