// DeleteAuthModal.tsx
import axios from "axios";
import React from "react";
import { env, useGlobalState } from "../../helpers/GlobalStateProvider";
import { useModalState } from "../../helpers/ModalProvider";
import { apiUrls } from "../../config";

const DeleteAuthModal: React.FC = () => {
  const {
    isDeleteAuthModalVisible,
    deleteAuthType,
    deleteAuthIdentifier,
    closeDeleteAuthModal,
    showErrorMessage,
  } = useModalState();

  const { refreshAccountInfo } = useGlobalState();

  // If the modal isn't supposed to be visible, return null so nothing is rendered.
  if (!isDeleteAuthModalVisible) {
    return null;
  }

  const onConfirm = async () => {
    try {
      const bearerToken = localStorage.getItem("bearerToken");
      if (!bearerToken) {
        return;
      }

      // Call API to remove the chosen auth method
      const response = await axios.post(
        `${apiUrls[env]}/v3/apiKey/delete_auth_method`,
        { method: deleteAuthType }, // 'email' or 'wallet'
        { headers: { Authorization: `Bearer ${bearerToken}` } },
      );
      console.log(
        `${deleteAuthType} auth removed successfully:`,
        response.data,
      );

      // Refresh user info to reflect changes
      await refreshAccountInfo();

      // Close the modal
      closeDeleteAuthModal();
    } catch (error: any) {
      showErrorMessage(
        `An unexpected error occurred. ${error?.response?.data?.message}`,
      );
    }
  };

  const onCancel = () => {
    closeDeleteAuthModal();
  };

  return (
    <div
      style={{
        position: "fixed",
        inset: 0,
        background: "rgba(0, 0, 0, 0.4)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
      }}
      onClick={onCancel}
    >
      <div
        style={{
          width: "400px",
          background: "white",
          borderRadius: "16px",
          padding: "24px",
          position: "relative",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <h2 style={{ margin: 0, marginBottom: "16px" }}>
          Delete Authentication Method?
        </h2>
        <p style={{ fontSize: "14px", color: "#473D5C", marginBottom: "16px" }}>
          Are you sure you want to delete <strong>{deleteAuthType}</strong>{" "}
          authentication method
          {deleteAuthIdentifier ? `: ${deleteAuthIdentifier}?` : "?"}
          <br />
          This will permanently remove this authentication method from your
          account.
        </p>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "16px",
          }}
        >
          <button
            onClick={onCancel}
            style={{
              padding: "8px 16px",
              background: "transparent",
              border: "2px solid #5061AC",
              borderRadius: "8px",
              color: "#5061AC",
              fontWeight: 600,
              cursor: "pointer",
            }}
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            style={{
              padding: "8px 16px",
              background: "#AC5050",
              borderRadius: "8px",
              color: "#FFFFFF",
              fontWeight: 600,
              border: "none",
              cursor: "pointer",
            }}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteAuthModal;
