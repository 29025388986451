import React, { useState, useEffect } from "react";
import { apiUrls, FIAT_ENABLED } from "../config";
import { useNavigate } from "react-router-dom";
import { env, STRIPE_CUSTOMER_PORTAL, useGlobalState } from "../helpers/GlobalStateProvider";
import { useModalState } from "../helpers/ModalProvider";
import { Button, Card, Flex, Heading, Link, List, SegmentGroup, Stack } from "@chakra-ui/react";

interface APIPlansContainerProps {
  tier?: number; // This can be 0 or a positive number. If not logged in, maybe 0.
}

const APIPlansContainer: React.FC<APIPlansContainerProps> = () => {
  const { info, stakeMorAndConnectWallet } =
    useGlobalState();

  const { showErrorMessage } = useModalState();

  const tier = info?.tier || 0;
  const stakedBalance = info?.stakedBalance || 0; // Make sure you read the user’s staked balance

  const [mode, setMode] = useState<"stripe" | "staking">(`${FIAT_ENABLED ? 'stripe' : 'staking'}`);
  const [tierLinks, setTierLinks] = useState<{ [key: string]: string }>({});
  const [customerId, setCustomerId] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTierLinks = async () => {
      try {
        const token = localStorage.getItem("bearerToken");
        const response = await fetch(`${apiUrls[env]}/webhooks/buy`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          // this is expected to happen if they are signed out and isnt really an error
          return;
        }
        const data = await response.json();

        setCustomerId(data.customerId || null);

        if (mode === "stripe") {
          if (data.customerId) {
            const BILLING_URL = STRIPE_CUSTOMER_PORTAL;
            setTierLinks({
              "1": BILLING_URL,
              "2": BILLING_URL,
              "3": BILLING_URL,
              "4": BILLING_URL,
            });
          } else {
            setTierLinks(data.tiers || {});
          }
        } else {
          setTierLinks({
            "1": "",
            "2": "",
            "3": "",
            "4": "",
          });
        }
      } catch (error: any) {
        showErrorMessage(
          `"An error has occured: ${error?.response?.data?.message}`,
        );
      }
    };

    fetchTierLinks();
  }, [env, mode]);

  // Mark plan at index 2 as recommended (just an example)
  const isRecommended = (planTier: number) => planTier === 2;

  // Hard-coded plan info
  const basePlanData = [
    {
      title: "Free",
      mor: "0 MOR",
      monthlyPrice: "$0/mo",
      features: [
        "2,500 credits/month*",
        "No web-socket access",
      ],
      stakingLastLine: "2 CoinCap points per staked MOR per day",
      monthlyLastLine: "Early access to AI Chatbot",
    },
    {
      title: "Basic",
      mor: "5 MOR",
      monthlyPrice: "$25/mo",
      features: [
        "50,000 credits/month*",
        "No web-socket access",
      ],
      stakingLastLine: "10 CoinCap points per staked MOR per day",
      monthlyLastLine: "Early access to AI Chatbot",
    },
    {
      title: "Growth",
      mor: "15 MOR",
      monthlyPrice: "$65/mo",
      features: [
        "150,000 credits/month*",
        "Full web-socket access",
      ],
      stakingLastLine: "15 CoinCap points per staked MOR per day",
      monthlyLastLine: "Early access to AI Chatbot",
    },
    {
      title: "Professional",
      mor: "50 MOR",
      monthlyPrice: "$150/mo",
      features: [
        "450,000 credits/month*",
        "Full web-socket access",
      ],
      stakingLastLine: "20 CoinCap points per staked MOR per day",
      monthlyLastLine: "Early access to AI Chatbot",
    },
    {
      title: "Enterprise",
      mor: "100 MOR",
      monthlyPrice: "$300/mo",
      features: [
        "3,000,000 credits/month*",
        "Full web-socket access",
      ],
      stakingLastLine: "25 CoinCap points per staked MOR per day",
      monthlyLastLine: "Early access to AI Chatbot",
    },
  ];

  // Build plan info depending on monthly vs. staking
  const planData = basePlanData.map((plan, index) => {
    const finalFeatures = [...plan.features, plan.monthlyLastLine];
    if (mode === "staking") {
      finalFeatures.push(plan.stakingLastLine);
    }
    return {
      ...plan,
      displayPrice: mode === "stripe" ? plan.monthlyPrice : plan.mor,
      features: finalFeatures,
    };
  });

  // Check if the plan is the user's current tier
  const isCurrentPlan = (index: number) => index === tier;

  // Render the correct link or button
  const getButton = (recommended: boolean, index: number) => {
    // No button if it's the current plan
    if (isCurrentPlan(index)) return null;

    const isLowerTier = index < tier;
    // Grab the link from state
    const buttonUrl = tierLinks[String(index)]
    

    // The click handler
    const handleClick = (e: React.MouseEvent) => {
      console.log('handleClick')
      e.preventDefault();

      // If the user is not logged in, navigate them to sign in
      const bearerToken = localStorage.getItem("bearerToken");

      console.log('bearerToken on handle click', bearerToken)

      if (!bearerToken) {
        return navigate("/signin");
      }

      if (mode === "staking") {
        // 1) Parse new tier's MOR requirement from plan's `mor` string: e.g. "15 MOR" -> 15
        const newPlanMor =
          parseInt(planData[index].mor.replace(/\D/g, ""), 10) || 0;
        // 2) Compare to user's current staked balance
        const difference = newPlanMor - stakedBalance;

        if (difference > 0) {
          // They need to stake (upgrade)
          stakeMorAndConnectWallet(difference, false);
        } else if (difference < 0) {
          // They need to unstake (downgrade)
          stakeMorAndConnectWallet(0, true);
        } else {
          // Staked balance is exactly enough for this tier
          console.log(
            "No action needed - you already have the required MOR staked.",
          );
        }
        return;
      }
      console.log('past check2')

      console.log('buttonUrl', buttonUrl)
      if (buttonUrl) {
        window.location.href = buttonUrl;
      } else {
        navigate("/signin");
      }
    };

    // Decide if we should show a "downgrade plan" link
    // 1) If mode === 'stripe' and !customerId, we hide downgrades
    // 2) If mode === 'stripe' and we do have customerId, show them
    // 3) If mode === 'staking', we handle everything above
    if (isLowerTier) {
      if (mode === "stripe" && !customerId) {
        // Hide downgrade link entirely
        return null;
      }
      // Otherwise, show a text link
      return (
        <div style={{ marginTop: "auto" }}>
          {buttonUrl ? (
            <Button asChild width='full'>
              <Link href={buttonUrl}>
                Downgrade Plan
              </Link>
            </Button>
          ) : (
            <Button
              onClick={handleClick}
              width='full'
            >
              Downgrade Plan
            </Button>
          )}
        </div>
      );
    }

    // Otherwise it's an upgrade
    const buttonText = recommended
      ? "Upgrade Plan"
      : "Upgrade Plan";
    return (
        <Button
          width='full'
          onClick={handleClick}
          variant={recommended ? "primary" : "outline"}
        >
          {buttonText}
      </Button>
    );
  };

  return (
    <Card.Root>
      <Card.Header>
        <Card.Title>API Plans</Card.Title>
        <Card.Description>
          Select the plan that works best for your project. Plans can be purchased by staking MOR, or with a monthly payment.
          <br />
          Read more about the CoinCap API here:{" "}
          <a
            href={`/api-docs/`}
            target="_blank"
            rel="noreferrer"
            style={{
              color: "#5061AC",
              fontSize: 14,
              fontWeight: 700,
              textDecoration: "underline",
              lineHeight: "21px",
            }}
          >
            CoinCap API Docs
          </a>
        </Card.Description>
      </Card.Header>

      {/* Toggle (stripe vs. Staking) */}
      <Card.Body>
        <Stack alignItems='center' gap={6}>
        <SegmentGroup.Root value={mode}>
          <SegmentGroup.Indicator />
          <SegmentGroup.Item value="stripe" 
            onClick={() => setMode("stripe") }
            style={{
              cursor: FIAT_ENABLED ? "pointer" : "not-allowed",
              color: FIAT_ENABLED ? "inherit" : "#a0a0a0", // Adjust color for disabled state
              pointerEvents: FIAT_ENABLED ? "auto" : "none", // Prevent interaction if disabled
            }}>Monthly Payments</SegmentGroup.Item>
          <SegmentGroup.Item value="staking" onClick={() => setMode("staking")}>MOR Staking</SegmentGroup.Item>
        </SegmentGroup.Root>
          <Flex width='full' gap={4} flexWrap='wrap'>    
            {planData.map((plan, index) => {
              const recommended = isRecommended(index);
              const currentPlan = isCurrentPlan(index);

            // Title text changes for current or recommended
            let titleText = plan.title;
            if (currentPlan) {
              titleText += " (Current Plan)";
            } else if (recommended) {
              titleText += " (Recommended)";
            }

            // Build the Upgrade/Downgrade button or link
            const button = getButton(recommended, index);

            return (
              <Card.Root flex={1} minWidth='250px' variant={currentPlan ? 'subtle' : recommended ? 'elevated' : 'outline'} width='full' key={index} colorPalette={currentPlan? 'purple' : 'gray'}>
                <Card.Body>
                <Heading size='md' color={recommended ? 'fg.success' : 'fg.muted'}>{titleText}</Heading>
                <Heading size='4xl' mb={12}>{plan.displayPrice}</Heading>
   
                <List.Root as='ol' variant='plain' color='fg.muted'>
                {plan.features.map((feature, i) => (
                      <List.Item
                        key={i}
                        fontSize='sm'
                        mb={2}
                        fontWeight={i === plan.features.length - 1 && mode !== "stripe" ? 600 : 400}
  
                      >
                        {feature}
                      </List.Item>
                    ))}
                    </List.Root>
   
                  </Card.Body>
                  {button && <Card.Footer>{button}</Card.Footer>}
                </Card.Root>
              );
            })}
          </Flex>
        </Stack>
      </Card.Body>

      {/* Footer Note */}
      <Card.Footer color='fg.muted' fontSize='sm'>
        *Each API call charges a base cost of 1 credit, then 1 additional credit
        per 2500 bytes of data. Web-socket charges 1 credit per minute of use.
      </Card.Footer>
    </Card.Root>
  );
};

export default APIPlansContainer;
