import { Container, Stack } from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import HeaderContainer from "../views/Header";

type LayoutProps = {
  behavior?: "signin" | "dashboard" | "chatbot" | "docs"
} & PropsWithChildren

export const Layout: React.FC<LayoutProps> = ({ children, behavior }) => {
  return (
    <Stack>
      <HeaderContainer behavior={behavior} />
      <Container display='flex' flexDirection='column' gap={8}>
        {children}
      </Container>
    </Stack>
  )
}