// ModalWrapper.tsx
import React from "react";

interface ModalWrapperProps {
  onBackdropClick?: () => void;
  children: React.ReactNode;
}

const ModalWrapper: React.FC<ModalWrapperProps> = ({
  onBackdropClick,
  children,
}) => {
  return (
    // 1) The full-screen overlay that blurs *behind* it
    <div
      style={{
        position: "fixed",
        inset: 0,
        // This is the "glass" layer that blurs what's behind it
        backdropFilter: "blur(8px)",
        WebkitBackdropFilter: "blur(8px)", // Safari support
        // If you want a subtle color tint (optional):
        background: "rgba(255, 255, 255, 0.1)",

        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 9999,
      }}
      onClick={onBackdropClick}
    >
      {/* 2) The actual modal container with an OPAQUE or SEMI-OPAQUE background */}
      <div
        onClick={(e) => e.stopPropagation()} // Don’t close if user clicks inside
        style={{
          // This background is for your modal content
          background: "#FFFFFF", // <-- White or any color you like
          borderRadius: 16,
          boxShadow: "0 6px 16px rgba(0,0,0,0.3)",
          // If you want a bit less bright:
          // background: 'rgba(255,255,255,0.95)',
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default ModalWrapper;
