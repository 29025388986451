import { defineSlotRecipe } from "@chakra-ui/react";
import { cardAnatomy } from '@chakra-ui/react/anatomy'

export const cardRecipe = defineSlotRecipe({
  className: "chakra-card",
  slots: cardAnatomy.keys(),
  base: {
    root: {
      borderRadius: '3xl'
    }
  },
})