import React, { useCallback, useState } from "react";
import { env, useGlobalState } from "../../helpers/GlobalStateProvider";
import { useModalState } from "../../helpers/ModalProvider";
import ModalWrapper from "./ModalWrapper";
import { useSwitchChain, useWalletClient } from 'wagmi'
import { config } from "../..";
import { ethers } from "ethers";
import { getRewardsContractAddress } from '@coincap/common2/dist/helpers';
import { rewardsContractAbi } from "@coincap/common2/dist/rewardsContractAbi";
import axios from "axios";
import { apiUrls } from "../../config";
import BigNumber from "bignumber.js";
import { useConnectModal } from "@rainbow-me/rainbowkit";

export const ChainSelectionModal: React.FC = () => {
  const { updatePointsAndRewards, setSelectedChainId, info, isWithdrawRewards, setIsWithdrawRewards, selectedChainId, setTransactionDetails, rewardContractInfo, baseRewards, arbitrumRewards } = useGlobalState();
  const {
    isChainSelectionModalVisible,
    setChainSelectionModalVisible,
    showStakeModal,
    setClaimBroadcastModalVisible,
    showErrorMessage
  } = useModalState();

  const { openConnectModal } = useConnectModal()
  const [selectedNetwork, setSelectedNetwork] = useState<"arbitrum" | "base" | null>(null);
  const { switchChain } = useSwitchChain({ config })
  const { data: walletClient } = useWalletClient();

  const broadcastClaimRewardsTx = async (walletClient: any, currentChainId: number) => {
    if (!walletClient) {
      openConnectModal?.();
      console.log("No wallet connected");
      return;
    }
    const provider = new ethers.BrowserProvider(walletClient);
    const rewardsContractAddress = getRewardsContractAddress(currentChainId);
    const signer = await provider.getSigner();
    const contract = new ethers.Contract(rewardsContractAddress, rewardsContractAbi, signer);
    const address = await signer.getAddress();
    const result = await contract.userRewards(address);
  
    // const totalRewardsEarned = ethers.formatEther(result[0].toString())
    // const totalRewardsWithdrawn = ethers.formatEther(result[1].toString())
    const totalAvailableForWithdraw = (result[0] - result[1]).toString()
  
    let tx

    try {
      tx = await contract.withdrawRewards(totalAvailableForWithdraw);
    } catch(e) {
      return showErrorMessage(`Tx Error: user rejected action`);
    }
    console.log("Transaction sent:", tx.hash);

    setTimeout(async () => {
      console.log('updating points and rewards')
      updatePointsAndRewards()
    }, 10000)

    const amount = totalAvailableForWithdraw


    let usdPerMor
    try {
      const result1 = await axios.get(
        `${apiUrls[env]}/v3/assets/morpheus`,
      );
      usdPerMor = parseFloat(result1.data.data.priceUsd);
    } catch(e) {
      console.log('error getting mor price', e)
      usdPerMor = 15 // for local dev
    }
      
      const result2 = await axios.get(`${apiUrls[env]}/v3/assets/ethereum`);
      const usdPerEth = parseFloat(result2.data.data.priceUsd);



    const feeData = await provider.getFeeData();
    const gasPrice = feeData.gasPrice;
    const gasPriceGwei = parseFloat(ethers.formatUnits(gasPrice ?? "0", "gwei"))
    

    const estimatedFeeEth = new BigNumber(gasPriceGwei)
      .multipliedBy(200000)
      .div(1e9)
      .toNumber();

      const morAmount = new BigNumber(amount).toNumber()
      const morPrice = new BigNumber(usdPerMor).toNumber();
      const ethPrice = new BigNumber(usdPerEth).toNumber();
      const ethFee = new BigNumber(estimatedFeeEth).toNumber();
    
  
    setTransactionDetails({
      tx: tx.hash,
      morAmount,
      morPrice,
      ethFee,
      ethPrice,
    });
  
    return tx.hash;
  };
  

  const handleSelect = (network: "arbitrum" | "base") => {
    if(network === 'arbitrum') {
      switchChain({ chainId: 42161 })
      setSelectedChainId(42161);
    }
    else {
      switchChain({ chainId: 8453 })
      setSelectedChainId(8453);
    }
    setSelectedNetwork(network);
  };

  const handleClose = () => {
    setChainSelectionModalVisible(false);
  };
  const handleContinue = useCallback(async () => {
    setChainSelectionModalVisible(false);

    if(isWithdrawRewards) {
      console.log('isWithdrawRewards')
      broadcastClaimRewardsTx(walletClient, selectedChainId)
      setClaimBroadcastModalVisible(true)
      setIsWithdrawRewards(false)
      return
    }


    if (selectedNetwork === "arbitrum") {
      showStakeModal()
    } else {
      showStakeModal()
    }

    handleClose();
  }, [selectedNetwork, walletClient, isWithdrawRewards]);

  if (!isChainSelectionModalVisible) {
    return null;
  }

  const baseRewardContractInfo = rewardContractInfo?.baseRewardContractInfo
  const arbitrumRewardContractInfo = rewardContractInfo?.arbitrumRewardContractInfo

  const totalPendingBaseRewards = baseRewards - baseRewardContractInfo?.totalRewardsWithdrawn;
  const totalPendingArbitrumRewards = arbitrumRewards - arbitrumRewardContractInfo?.totalRewardsWithdrawn

  const totalRewardsEared = parseFloat(baseRewardContractInfo?.totalRewardsEarned ?? '0') + parseFloat(arbitrumRewardContractInfo?.totalRewardsEarned ?? '0')

  const totalRewardsWithdrawn = parseFloat(baseRewardContractInfo?.totalRewardsWithdrawn ?? '0') + parseFloat(arbitrumRewardContractInfo?.totalRewardsWithdrawn ?? '0')

  const availablePendingBaseRewards = parseFloat(baseRewardContractInfo?.totalRewardsEarned ?? '0') - parseFloat(baseRewardContractInfo?.totalRewardsWithdrawn ?? '0')
  const availablePendingArbitrumRewards = parseFloat(arbitrumRewardContractInfo?.totalRewardsEarned ?? '0') - parseFloat(arbitrumRewardContractInfo?.totalRewardsWithdrawn ?? '0')

  const totalPendingRewards = totalPendingBaseRewards + totalPendingArbitrumRewards;
  const availablePendingRewards = totalRewardsEared - totalRewardsWithdrawn


  return (
    <ModalWrapper onBackdropClick={handleClose}>
      {/* Dark backdrop */}
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1000,
        }}
        onClick={handleClose}
      >
        {/* Modal container */}
        <div
          style={{
            width: 560,
            maxHeight: "90vh",
            padding: 24,
            overflowY: "auto",
            background: "#FFFFFF",
            boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.2)",
            borderRadius: 24,
            display: "flex",
            flexDirection: "column",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {/* Title */}
          <div
            style={{
              color: "#190C33",
              fontSize: 24,
              fontWeight: 600,
              lineHeight: "32px",
              marginBottom: 16,
            }}
          >
            Stake MOR to CoinCap
          </div>

          {/* Arbitrum Card */}
          <div
            style={{
              background: "rgba(25, 12, 51, 0.03)",
              border:
                selectedNetwork === "arbitrum"
                  ? "2px solid #5061AC"
                  : "1px solid #D3D3D3",
              borderRadius: 12,
              padding: 16,
              marginBottom: 16,
              cursor: "pointer",
            }}
            onClick={() => handleSelect("arbitrum")}
          >
            {/* Chain header row (icon + name) */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 12,
                gap: 8,
              }}
            >
              <img
                src="/external/arbitrum_icon2.png"
                alt="Arbitrum"
                style={{ width: 24, height: 24 }}
              />
              <div style={{ fontSize: 16, fontWeight: 600 }}>Arbitrum</div>
            </div>

            {/* Stats rows */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 4,
              }}
            >
              <span style={{ color: "#8C8599" }}>Wallet Balance:</span>
              <span style={{ fontWeight: 500 }}>{parseFloat(info?.arbBalance ?? '0').toFixed(4)} MOR</span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 4,
              }}
            >
              <span style={{ color: "#8C8599" }}>Total MOR Staked:</span>
              <span style={{ fontWeight: 500 }}>{parseFloat(info?.stakedArbitrumBalance ?? '0').toFixed(4)} MOR</span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span style={{ color: "#8C8599" }}>Available Rewards:</span>
              <span style={{ fontWeight: 500 }}>{availablePendingArbitrumRewards?.toFixed(4) ?? 0.00} MOR</span>
            </div>
          </div>

          {/* Base Card */}
          <div
            style={{
              background: "rgba(25, 12, 51, 0.03)",
              border:
                selectedNetwork === "base"
                  ? "2px solid #5061AC"
                  : "1px solid #D3D3D3",
              borderRadius: 12,
              padding: 16,
              marginBottom: 24,
              cursor: "pointer",
            }}
            onClick={() => handleSelect("base")}
          >
            {/* Chain header row (icon + name) */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 12,
                gap: 8,
              }}
            >
              <img
                src="/external/base_icon2.png"
                alt="Base"
                style={{ width: 24, height: 24 }}
              />
              <div style={{ fontSize: 16, fontWeight: 600 }}>Base</div>
            </div>

            {/* Stats rows */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 4,
              }}
            >
              <span style={{ color: "#8C8599" }}>Wallet Balance:</span>
              <span style={{ fontWeight: 500 }}>{parseFloat(info?.baseBalance ?? '0').toFixed(4)} MOR</span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 4,
              }}
            >
              <span style={{ color: "#8C8599" }}>Total MOR Staked:</span>
              <span style={{ fontWeight: 500 }}>{parseFloat(info?.stakedBaseBalance ?? '0').toFixed(4)} MOR</span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span style={{ color: "#8C8599" }}>Available Rewards:</span>
              <span style={{ fontWeight: 500 }}>{availablePendingBaseRewards?.toFixed(4) ?? 0.00} MOR</span>
            </div>
          </div>

          {/* Buttons */}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 16,
            }}
          >
            <button
              onClick={handleClose}
              style={{
                minWidth: 80,
                height: 42,
                padding: "0 20px",
                background: "#FFFFFF",
                borderRadius: 12,
                border: "1px solid #5061AC",
                fontSize: 14,
                fontWeight: 500,
                color: "#5061AC",
                cursor: "pointer",
              }}
            >
              Close
            </button>

            <button
              onClick={handleContinue}
              disabled={!selectedNetwork}
              style={{
                minWidth: 80,
                height: 42,
                padding: "0 20px",
                background: selectedNetwork ? "#5061AC" : "#A2A2A2",
                borderRadius: 12,
                border: "none",
                fontSize: 14,
                fontWeight: 500,
                color: "#FFFFFF",
                cursor: selectedNetwork ? "pointer" : "not-allowed",
              }}
            >
              {selectedNetwork
                ? `Continue on ${
                    selectedNetwork === "arbitrum" ? "Arbitrum" : "Base"
                  }`
                : "Continue"}
            </button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

