import React, { useState } from "react";
import { env, useGlobalState } from "../helpers/GlobalStateProvider";
import axios from "axios";
import { apiUrls } from "../config";
import { useModalState } from "../helpers/ModalProvider";
import { Card, IconButton, Table, VStack } from "@chakra-ui/react";
import { LuCopy, LuPencil, LuPlus, LuTrash } from "react-icons/lu";
import { Button } from "../components/ui/button";
import {  tierInfo } from "@coincap/common2/dist/helpers"

const APIKeysContainer: React.FC<any> = () => {
  const [showToast, setShowToast] = useState(false);

  const { info, refreshAccountInfo, isSignedIn } = useGlobalState();
  const { showDeleteModal, showEditModal, showErrorMessage } = useModalState();

  const handleCreateApiKey = async () => {
    try {
      const bearerToken = localStorage.getItem("bearerToken");
      await axios.post(
        `${apiUrls[env]}/v3/apiKey/registerApiKey`,
        {},
        { headers: { Authorization: `Bearer ${bearerToken}` } },
      );
      await refreshAccountInfo();
    } catch (error) {
      showErrorMessage("Error creating API key");
    }
  };

  const formatLastUsed = (date: string) => {
    const now = new Date();
    const lastUsed = new Date(date);
    const diffInSeconds = Math.floor(
      (now.getTime() - lastUsed.getTime()) / 1000,
    );

    if (diffInSeconds < 60) {
      return `${diffInSeconds} seconds ago`;
    }

    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) {
      return `${diffInMinutes} minutes ago`;
    }

    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) {
      return `${diffInHours} hours ago`;
    }

    return lastUsed.toLocaleDateString("en-CA");
  };

  const handleCopyClick = (key: string) => {
    navigator.clipboard.writeText(key);
    setShowToast(true);
    setTimeout(() => setShowToast(false), 3000);
  };

  // Filter and sort API keys
  const apiKeys = info?.apiKeys
    ?.filter((k: any) => k.enabled)
    ?.sort(
      (a: any, b: any) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );

  return (
    <Card.Root>
      <Card.Header flexDir='row' justifyContent='space-between' alignItems='center'>
        <VStack alignItems='flex-start'>  
          <Card.Title>My API Keys</Card.Title>
          <Card.Description>View and manage your API keys:</Card.Description>
        </VStack>
        <Button variant='outline' disabled={!isSignedIn()} onClick={isSignedIn() ? handleCreateApiKey : undefined}>
          <LuPlus />
          Add New Key
          </Button>
      </Card.Header>

      <Card.Body>
        {/* Show table only if there are API keys */}
      {apiKeys && apiKeys.length > 0 && (
        <>
          {/* Table Container (Header + Rows) */}
          <Table.Root>
            <Table.Header>
              <Table.Row>
                <Table.ColumnHeader>Label</Table.ColumnHeader>
                <Table.ColumnHeader>API Key</Table.ColumnHeader>
                <Table.ColumnHeader>Created On</Table.ColumnHeader>
                <Table.ColumnHeader>Credits Used</Table.ColumnHeader>
                <Table.ColumnHeader>Last Used</Table.ColumnHeader>
                <Table.ColumnHeader>Actions</Table.ColumnHeader>
              </Table.Row>
            </Table.Header>
            <Table.Body>
            {apiKeys.map((k: any) => (
              <Table.Row key={k.key}>
                {/* Label */}
                <Table.Cell>
                  <span
                    style={{ cursor: "pointer" }}
                  onClick={() => showEditModal(k.key, k.label)}
                >
                  {k.label}
                  </span>
                </Table.Cell>

                {/* API Key + copy icon */}
                <Table.Cell>
                <span style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ marginRight: 8 }}>{k.key.slice(0, 16)}...</div>
                  <IconButton variant='ghost' size='sm' onClick={() => handleCopyClick(k.key)}>
                    <LuCopy />
                    </IconButton>
                  </span>
                </Table.Cell>

                {/* Created On */}
                <Table.Cell>
                  <span>{new Date(k.createdAt).toLocaleDateString("en-CA")}</span>
                </Table.Cell>

                {/* Credits Used */}
                <Table.Cell>
                  <span>{k.monthly_usage}</span>
                </Table.Cell>

                {/* Last Used */}
                <Table.Cell>
                  <span>{formatLastUsed(k.updatedAt)}</span>
                </Table.Cell>

                {/* Actions: side-by-side */}
                <Table.Cell>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 8,
                      justifyContent: "flex-start",
                    }}
                >
                  <IconButton size='sm' variant='ghost' onClick={() => showEditModal(k.key, k.label)}>
                    <LuPencil />
                  </IconButton> 
                  <IconButton size='sm' variant='ghost' colorPalette='red' onClick={() => showDeleteModal(k.key, k.label)}>
                    <LuTrash />
                  </IconButton> 
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
            </Table.Body>
          </Table.Root>
        </>
      )}

      {/* Toast (copied message) */}
      {showToast && (
        <div
          style={{
            /* .toast plus your inline overrides */
            position: "fixed",
            bottom: 20,
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "rgba(255, 255, 255, 0.08)",
            color: "black",
            padding: 16,
            borderRadius: 16,
            border: "1px rgba(255, 255, 255, 0.36) solid",
            boxShadow: "0px 6px 12px rgba(24.51, 12.15, 50.78, 0.12)",
            backdropFilter: "blur(200px)",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "space-between",
            zIndex: 1000,
            opacity: 0.9,
            transition: "opacity 0.5s ease",
          }}
        >
          API Key copied to clipboard
        </div>
      )}
      </Card.Body>
      <Card.Footer>
      <span>Total Credits Used This Month:</span>
                  {info?.apiKeys.reduce(
                (total: any, key: any) => total + (key?.monthly_usage || 0),
                0,
              ) || 0}{" "}
              / {tierInfo && tierInfo[info?.tier]?.credits}
      </Card.Footer>
    </Card.Root>
  );
};

export default APIKeysContainer;
