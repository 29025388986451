import React from "react";
import { useGlobalState } from "../../helpers/GlobalStateProvider";
import { useModalState } from "../../helpers/ModalProvider";
import ModalWrapper from "./ModalWrapper";

export const WhatIsStakingModal: React.FC = () => {
  // 1) Access any global logic you need (like staking):
  const { stakeMorAndConnectWallet } = useGlobalState();

  // 2) Access the modal's visibility from the modal provider:
  const { isWhatIsModalVisible, setWhatIsModalVisible } = useModalState();

  // 3) If the modal is not supposed to be visible, render nothing:
  if (!isWhatIsModalVisible) {
    return null;
  }

  const handleClose = () => {
    setWhatIsModalVisible(false);
  };

  const closeAndStake = () => {
    // Close the modal first, then do the stake logic
    handleClose();
    stakeMorAndConnectWallet(1, false);
  };

  return (
    <ModalWrapper onBackdropClick={handleClose}>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1000,
        }}
        onClick={handleClose}
      >
        <div
          style={{
            width: 560,
            maxHeight: "90vh",
            padding: 24,
            overflowY: "auto",
            background: "#FFFFFF",
            boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.2)",
            borderRadius: 24,
            backdropFilter: "blur(200px)",
            display: "flex",
            flexDirection: "column",
          }}
          // Prevent clicks inside from closing the modal
          onClick={(e) => e.stopPropagation()}
        >
          <div
            style={{
              color: "#190C33",
              fontSize: 24,
              fontWeight: 600,
              lineHeight: "32px",
              marginBottom: 16,
            }}
          >
            What is MOR Staking?
          </div>

          <div
            style={{
              color: "#190C33",
              fontSize: 16,
              lineHeight: "24px",
              marginBottom: 24,
            }}
          >
            Morpheus is building the world’s first open-source, peer-to-peer network of personal, general-purpose AI—powered by the MOR token.
            <br />
            <br />
            Now, you can support CoinCap and gain full access to our crypto-data API simply by staking your MOR tokens with us on either Arbitrum or Base networks. Every MOR you stake helps strengthen and grow CoinCap, ensuring we can continue to deliver top-tier data and services.            <br />
            <br />
            In return, you’ll get access to our world-class API, earn valuable CoinCap Points, and receive a 10% APR on all MOR tokens staked.*
            <br />
            <br />
            For a limited time, we’re offering an additional 10% APR*—bringing your total to 20%! Don’t miss this chance to maximize your rewards and support the future of CoinCap.
            <br />
            <br />
            <a
              href="https://mor.org/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "#6B46C1",
                textDecoration: "underline",
                fontWeight: 600,
              }}
            >
              Learn more about Morpheus
            </a>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 16,
            }}
          >
            {/* Close button with outline style */}
            <button
              onClick={handleClose}
              style={{
                minWidth: 80,
                height: 42,
                padding: "0 20px",
                background: "#FFFFFF",
                borderRadius: 12,
                border: "1px solid #190C33",
                fontSize: 14,
                fontWeight: 500,
                color: "#190C33",
                cursor: "pointer",
              }}
            >
              Close
            </button>

            {/* Stake MOR button (filled) */}
            <button
              onClick={closeAndStake}
              style={{
                minWidth: 80,
                height: 42,
                padding: "0 20px",
                background: "#FF4574",
                borderRadius: 12,
                border: "none",
                fontSize: 14,
                fontWeight: 500,
                color: "#FFFFFF",
                cursor: "pointer",
              }}
            >
              Stake MOR
            </button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
