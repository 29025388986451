// RenameChatModal.tsx
import React from "react";
import { useModalState } from "../../helpers/ModalProvider";
import { useGlobalState } from "../../helpers/GlobalStateProvider";
import ModalWrapper from "./ModalWrapper";

const RenameChatModal: React.FC = () => {
  const {
    isRenameChatModalVisible,
    renameChatId,
    renameChatValue,
    setRenameChatValue,
    closeRenameChatModal,
    setChatToRenameId,
    setChatOptionsId,
  } = useModalState();

  const { setChatHistory } = useGlobalState();
  // If the modal isn't supposed to be visible, return null
  if (!isRenameChatModalVisible) {
    return null;
  }

  const handleClose = () => {
    closeRenameChatModal();
  };

  const renameChat = (chatId: number) => {
    if (renameChatValue.trim() !== "") {
      setChatHistory((prev: any) =>
        prev.map((chat: any) =>
          chat.id === chatId ? { ...chat, title: renameChatValue } : chat,
        ),
      );
      closeRenameChatModal();
      setRenameChatValue("");
      setChatToRenameId(null);
      setChatOptionsId(null);
    }
  };
  const handleRename = () => {
    if (renameChatId !== null) {
      renameChat(renameChatId);
    } else {
      closeRenameChatModal();
    }
  };

  return (
    <ModalWrapper onBackdropClick={handleClose}>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(0,0,0,0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1000,
        }}
        onClick={handleClose}
      >
        <div
          style={{
            width: 540,
            padding: 24,
            background: "white",
            boxShadow: "0px 6px 12px rgba(24, 12, 50, 0.12)",
            borderRadius: 24,
            backdropFilter: "blur(200px)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div>
            <div
              style={{
                color: "#190C33",
                fontSize: 20,
                fontWeight: 600,
                lineHeight: "30px",
              }}
            >
              Rename Chat
            </div>
            <input
              type="text"
              value={renameChatValue}
              onChange={(e) => setRenameChatValue(e.target.value)}
              style={{
                width: "100%",
                height: 42,
                marginTop: 20,
                padding: "0 12px",
                fontSize: 14,
                fontWeight: 400,
                color: "#190C33",
                borderRadius: 12,
                border: "1px solid #CCCCCC",
                background: "#F9F9F9",
                outline: "none",
              }}
              placeholder="Enter new chat name"
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 16,
              marginTop: 30,
            }}
          >
            <button
              onClick={handleClose}
              style={{
                height: 42,
                padding: "0 20px",
                background: "#EBEBEB",
                borderRadius: 12,
                border: "none",
                fontSize: 14,
                fontWeight: 400,
                color: "#716D79",
                cursor: "pointer",
              }}
            >
              Cancel
            </button>
            <button
              onClick={handleRename}
              style={{
                height: 42,
                padding: "0 20px",
                background: "#697CCF",
                borderRadius: 12,
                border: "none",
                fontSize: 14,
                fontWeight: 400,
                color: "#FFFFFF",
                cursor: "pointer",
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default RenameChatModal;
