import "@rainbow-me/rainbowkit/styles.css";
import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiProvider } from "wagmi";
import { RainbowKitProvider, getDefaultConfig } from "@rainbow-me/rainbowkit";
import { arbitrum, base } from "wagmi/chains";
import App from "./App";

export const config = getDefaultConfig({
  appName: "Coincap API",
  projectId: "b8b86090fa70aafa2e8b43e88b4ac81c",
  chains: [arbitrum, base],
});

const queryClient = new QueryClient();

const rootElement = document.getElementById("app");
if (!rootElement) {
  throw new Error("Failed to find the app element");
}

const root = ReactDOM.createRoot(rootElement);

root.render(
  <WagmiProvider config={config}>
    <QueryClientProvider client={queryClient}>
      <RainbowKitProvider>
        <App />
      </RainbowKitProvider>
    </QueryClientProvider>
  </WagmiProvider>,
);
