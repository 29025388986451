import { createSystem, defaultConfig, defineConfig, mergeConfigs } from "@chakra-ui/react";
import { buttonRecipe } from "../recipes/button";
import { cardRecipe } from "../recipes/card";
import { menuRecipe } from "../recipes/menu";
import { dialogRecipe } from "../recipes/dialog";

const theme = defineConfig({
  globalCss: {
    'body': {
      bg: '#F3F4F6',
      _dark: {
        bg: '#000'
      }
    }
  },
  theme: {
    recipes: {
      button: buttonRecipe,
    },
    slotRecipes: {
      card: cardRecipe,
      menu: menuRecipe,
      dialog: dialogRecipe
    }
  }
})

const config = mergeConfigs(defaultConfig, theme)

export default createSystem(config)