import React from "react";
import APIPlansContainer from "./APIPlansContainer";
import APIKeysContainer from "./APIKeysContainer";
import StakingStatsContainer from "./StakingStats";
import { Layout } from "../components/Layout";

const Dashboard: React.FC<any> = () => {
  return (
    <Layout>
      <StakingStatsContainer />
      <APIKeysContainer />
      <APIPlansContainer />
    </Layout>
  );
};

export default Dashboard;
