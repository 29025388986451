import { Flex, Text } from "@chakra-ui/react";
import React, {  } from "react";
import { LuInfo } from "react-icons/lu";
import { Tooltip } from "../../../components/ui/tooltip";

const UnlockDateSection: React.FC<any> = (input: any) => {
  const epochTime = input.epochTime;
  return (
    <>
    <Flex width='full' justifyContent='space-between'>
      <Text color='fg.muted'>
        Unlock Date:
      </Text>
      <Flex gap={2} alignItems='center'>
        <Text>
          {new Date(epochTime).toLocaleDateString("en-US")}
        </Text>
        <Tooltip content="Any MOR staked will be locked from withdrawal for 7 days. Please note that staking MOR resets the unlock date for all MOR you have staked to CoinCap." openDelay={0}>
          <LuInfo />
        </Tooltip>
        </Flex>
      </Flex>
    </>
  );
};

export default UnlockDateSection;
