import React from "react";
import { useGlobalState } from "../../helpers/GlobalStateProvider";
import { formatDecimals } from "../../helpers/utils";
import { useModalState } from "../../helpers/ModalProvider";

type MorStakingSectionProps = {
  cardStyle: React.CSSProperties;
  info: any;
  truncated: (num: any) => string;
};

const rowStyle: React.CSSProperties = {
  display: "contents",
};

const labelStyle: React.CSSProperties = {
  fontSize: "14px",
  fontWeight: 600,
  color: "#181432",
  marginBottom: "8px",
};

const valueStyle: React.CSSProperties = {
  fontSize: "14px",
  fontWeight: 400,
  color: "#473D5C",
  marginBottom: "8px",
};

const actionStyle: React.CSSProperties = {
  fontSize: "14px",
  fontWeight: 600,
  textDecoration: "underline",
  color: "#5061AC",
  background: "transparent",
  border: "none",
  cursor: "pointer",
  padding: 0,
  marginBottom: "8px",
};

const disabledActionStyle: React.CSSProperties = {
  ...actionStyle,
  color: "#A0A0A0",
  textDecoration: "none",
  cursor: "not-allowed",
  opacity: 0.7,
};

// Slightly smaller text for chain values
const chainValueStyle: React.CSSProperties = {
  fontSize: "12px",
};

const MorStakingSection: React.FC<MorStakingSectionProps> = ({
  cardStyle,
  info,
  truncated,
}) => {
  const { stakeMorAndConnectWallet, morRewards, baseRewards, arbitrumRewards, rewardContractInfo, setIsWithdrawRewards, totalPoints } = useGlobalState();
  const { setChainSelectionModalVisible } = useModalState();

  const baseRewardContractInfo = rewardContractInfo?.baseRewardContractInfo
  const arbitrumRewardContractInfo = rewardContractInfo?.arbitrumRewardContractInfo

  const totalPendingBaseRewards = baseRewards - baseRewardContractInfo?.totalRewardsWithdrawn;
  const totalPendingArbitrumRewards = arbitrumRewards - arbitrumRewardContractInfo?.totalRewardsWithdrawn

  const totalRewardsEared = parseFloat(baseRewardContractInfo?.totalRewardsEarned ?? '0') + parseFloat(arbitrumRewardContractInfo?.totalRewardsEarned ?? '0')

  const totalRewardsWithdrawn = parseFloat(baseRewardContractInfo?.totalRewardsWithdrawn ?? '0') + parseFloat(arbitrumRewardContractInfo?.totalRewardsWithdrawn ?? '0')

  const availablePendingBaseRewards = parseFloat(baseRewardContractInfo?.totalRewardsEarned ?? '0') - parseFloat(baseRewardContractInfo?.totalRewardsWithdrawn ?? '0')
  const availablePendingArbitrumRewards = parseFloat(arbitrumRewardContractInfo?.totalRewardsEarned ?? '0') - parseFloat(arbitrumRewardContractInfo?.totalRewardsWithdrawn ?? '0')

  const totalPendingRewards = totalPendingBaseRewards + totalPendingArbitrumRewards;
  const availablePendingRewards = totalRewardsEared - totalRewardsWithdrawn


  const staked = truncated(info?.stakedBalance);
  const points = truncated(totalPoints);
  const pointsDay = truncated(info?.pointsPerDay);
  const lifetimeMor = truncated(morRewards || 0);
  const lifetimeMorDay = truncated(info?.morRewardsPerDay || 0);

  const wallet = info?.walletAddress || "None";

  const handleStake = () => {
    stakeMorAndConnectWallet(1, false);
  };
  const handleWithdraw = () => {
    setIsWithdrawRewards(true);
    setChainSelectionModalVisible(true)
  }
  const handleUnstake = () => {
    stakeMorAndConnectWallet(0, true);
  };

  const canWithdraw = availablePendingRewards > 0;
  const hasWallet = wallet !== "None";
  const withdrawButtonLabel = canWithdraw
    ? `withdraw ${formatDecimals(availablePendingRewards ?? 0.00, 6) ?? 0.00} MOR`
    : "withdraw rewards";

  return (
    <div style={cardStyle}>
      <h2 style={{ margin: "0 0 4px", fontSize: "18px", fontWeight: 700 }}>
        MOR Staking
      </h2>
      <p style={{ margin: "0 0 16px", fontSize: "14px", color: "#756D85" }}>
        Manage your MOR staking.
      </p>

      {/* Divider */}
      <div
        style={{
          width: "100%",
          height: "1px",
          background: "#E4E4E7",
          marginBottom: "16px",
        }}
      />

      {/* Increased the first column so second column is pushed further right */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "220px 1fr auto",
          rowGap: "8px",
          columnGap: "16px",
        }}
      >
        {/* 1) TOTAL MOR STAKED */}
        <div style={rowStyle}>
          <div style={labelStyle}>Total MOR Staked</div>
          <div style={valueStyle}>
            {staked} MOR
            {/* Chain icons below */}
            <div style={{ display: "flex", gap: "8px", marginTop: "4px" }}>
              <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                <img
                  src="/external/base_icon.png"
                  alt="Base"
                  style={{ height: "1em" }}
                />
                <span style={chainValueStyle}>{formatDecimals(parseFloat(info?.stakedBaseBalance ?? '0.00'), 4) ?? 0.00}</span>
              </div>
              <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                <img
                  src="/external/arbitrum_icon.png"
                  alt="Arbitrum"
                  style={{ height: "1em" }}
                />
                <span style={chainValueStyle}>{formatDecimals(parseFloat(info?.stakedArbitrumBalance ?? '0.00'), 4) ?? 0.00}</span>
              </div>
            </div>
          </div>
          <button style={actionStyle} onClick={handleStake}>
            stake
          </button>
        </div>

        {/* 2) LIFETIME MOR REWARDS */}
        <div style={rowStyle}>
          <div style={labelStyle}>Lifetime MOR Rewards</div>
          <div style={valueStyle}>
            {lifetimeMor} MOR ({lifetimeMorDay} MOR/day)
            {/* Chain icons below */}
            <div style={{ display: "flex", gap: "8px", marginTop: "4px" }}>
              <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                <img
                  src="/external/base_icon.png"
                  alt="Base"
                  style={{ height: "1em" }}
                />
                <span style={chainValueStyle}>{formatDecimals(baseRewards ?? 0.00, 4) ?? 0.00}</span>
              </div>
              <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                <img
                  src="/external/arbitrum_icon.png"
                  alt="Arbitrum"
                  style={{ height: "1em" }}
                />
                <span style={chainValueStyle}>{formatDecimals(arbitrumRewards ?? 0.00, 4) ?? 0.00}</span>
              </div>
            </div>
          </div>
          <div />
        </div>

        {/* 3) PENDING MOR REWARDS */}
        <div style={rowStyle}>
          <div style={labelStyle}>Pending MOR Rewards</div>
          <div style={valueStyle}>
            {formatDecimals(availablePendingRewards ?? 0.00, 4)} MOR Available ({formatDecimals(totalPendingRewards ?? 0.00, 4) ?? 0.00} MOR Total)
            {/* Chain icons below */}
            <div style={{ display: "flex", gap: "8px", marginTop: "4px" }}>
              <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                <img
                  src="/external/base_icon.png"
                  alt="Base"
                  style={{ height: "1em" }}
                />
                <span style={chainValueStyle}>{formatDecimals(availablePendingBaseRewards ?? 0.00, 4) ?? 0.00} Available</span>
              </div>
              <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                <img
                  src="/external/arbitrum_icon.png"
                  alt="Arbitrum"
                  style={{ height: "1em" }}
                />
                <span style={chainValueStyle}>{formatDecimals(availablePendingArbitrumRewards ?? 0.00, 4) ?? 0.00} Available</span>
              </div>
            </div>
          </div>
          <button
            style={canWithdraw ? actionStyle : disabledActionStyle}
            onClick={canWithdraw ? handleWithdraw : undefined}
          >
            {withdrawButtonLabel}
          </button>
        </div>

        {/* 4) COINCAP POINTS EARNED */}
        <div style={rowStyle}>
          <div style={labelStyle}>CoinCap Points Earned</div>
          <div style={valueStyle}>
            {points} ({pointsDay}/day)
          </div>
          <div />
        </div>

        {/* 5) MOR STAKING WALLET */}
        <div style={rowStyle}>
          <div style={labelStyle}>MOR Staking Wallet</div>
          <div
            style={{
              ...valueStyle,
              display: "flex",
              gap: "6px",
              alignItems: "center",
            }}
          >
            <img
              src="/external/wallet2.svg"
              alt="wallet"
              style={{ width: "18px", height: "18px" }}
            />
            <span>{wallet}</span>
          </div>
          <button
            style={
              hasWallet
                ? { ...actionStyle, color: "#AC5050" }
                : disabledActionStyle
            }
            onClick={hasWallet ? handleUnstake : undefined}
          >
            unstake
          </button>
        </div>
      </div>
    </div>
  );
};

export default MorStakingSection;
