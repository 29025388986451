import React, { useEffect, useState } from "react";
import { apiUrls } from "../../config";
import axios from "axios";
import { env, useGlobalState } from "../../helpers/GlobalStateProvider";
import { useModalState } from "../../helpers/ModalProvider";
import { DialogCloseTrigger, DialogContent, DialogHeader, DialogRoot } from "../../components/ui/dialog";
import { CardBody, CardHeader, CardRoot, DialogBody, DialogFooter, DialogTitle, Flex, Heading, Stack, Text } from "@chakra-ui/react";
import { Button } from "../../components/ui/button";

const DeleteApiKeyModal: React.FC = () => {
  const {
    isDeleteModalVisible,
    setDeleteModalVisible,
    modalContent,
    showErrorMessage,
  } = useModalState();

  const [isLoading, setIsLoading] = useState(false);

  const label = modalContent.label;
  const apiKey = modalContent.apiKey;

  const { refreshAccountInfo } = useGlobalState();

  useEffect(() => {
    if (!isDeleteModalVisible) return;

    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        handleDeleteApiKey();
        setDeleteModalVisible(false);
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [isDeleteModalVisible, label, apiKey, setDeleteModalVisible]);


  const handleDeleteApiKey = async () => {
    try {
      setIsLoading(true);
      const bearerToken = localStorage.getItem("bearerToken");
      if (!bearerToken) return;

      const requestBody = {
        apiKey,
        action: "disable",
        label,
      };

      await axios.post(`${apiUrls[env]}/v3/apiKey/toggleApiKey`, requestBody, {
        headers: { Authorization: `Bearer ${bearerToken}` },
      });

      await refreshAccountInfo();
    } catch (error: any) {
      showErrorMessage(
        `An unexpected error occurred. ${error?.response?.data?.message}`,
      );
    } finally {
      setIsLoading(false);
      setDeleteModalVisible(false);
    }
  };

  return (
    <DialogRoot lazyMount open={isDeleteModalVisible} onOpenChange={e => setDeleteModalVisible(e.open)}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Delete API Key</DialogTitle>
          <DialogCloseTrigger />
        </DialogHeader>
        <DialogBody>
          <Stack>
            <CardRoot variant='subtle'>
              <CardHeader pb={6} borderBottomWidth={1} borderColor='border'>
                <Heading size='md'>Are you sure you want to delete this API key?</Heading>
                <Text color='fg.muted'>This action cannot be undone.</Text>
              </CardHeader>
              <CardBody gap={4}>
                <Flex justifyContent="space-between">
                  <Text>Key Label</Text>
                  <Text fontWeight='bold'>{label}</Text>
                </Flex>
                <Flex gap={4} justifyContent="space-between">
                  <Text>API Key</Text>
                  <Text flex={1} overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap'>{apiKey}</Text>
                </Flex>
              </CardBody>
            </CardRoot>
          </Stack>
        </DialogBody>
        <DialogFooter>
          <Button colorPalette='red' onClick={handleDeleteApiKey} loading={isLoading}>Delete Key</Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

export default DeleteApiKeyModal;
