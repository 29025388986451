import React, { useState, useEffect } from "react";
import { useGlobalState } from "../helpers/GlobalStateProvider";
import { useModalState } from "../helpers/ModalProvider";
import { Box, Card, Flex, Heading, IconButton, Popover, Portal, Stack, Stat, Text } from "@chakra-ui/react";
import { LuChevronDown, LuDownload, LuMessageCircleQuestion, LuUpload } from "react-icons/lu";
import { Button } from "../components/ui/button";
import { formatDecimals } from "../helpers/utils";

const StakingStatsContainer: React.FC<any> = () => {
  const { stakeMorAndConnectWallet, info, morRewards, rewardContractInfo, baseRewards, arbitrumRewards, setIsWithdrawRewards, totalPoints } = useGlobalState();
  const { setWhatIsModalVisible, setChainSelectionModalVisible } = useModalState();

  const baseRewardContractInfo = rewardContractInfo?.baseRewardContractInfo
  const arbitrumRewardContractInfo = rewardContractInfo?.arbitrumRewardContractInfo

  const totalPendingBaseRewards = baseRewards - baseRewardContractInfo?.totalRewardsWithdrawn;
  const totalPendingArbitrumRewards = arbitrumRewards - arbitrumRewardContractInfo?.totalRewardsWithdrawn

  const totalRewardsEared = parseFloat(baseRewardContractInfo?.totalRewardsEarned ?? '0') + parseFloat(arbitrumRewardContractInfo?.totalRewardsEarned ?? '0')

  const totalRewardsWithdrawn = parseFloat(baseRewardContractInfo?.totalRewardsWithdrawn ?? '0') + parseFloat(arbitrumRewardContractInfo?.totalRewardsWithdrawn ?? '0')

  const availablePendingBaseRewards = parseFloat(baseRewardContractInfo?.totalRewardsEarned ?? '0') - parseFloat(baseRewardContractInfo?.totalRewardsWithdrawn ?? '0')
  const availablePendingArbitrumRewards = parseFloat(arbitrumRewardContractInfo?.totalRewardsEarned ?? '0') - parseFloat(arbitrumRewardContractInfo?.totalRewardsWithdrawn ?? '0')

  const totalPendingRewards = totalPendingBaseRewards + totalPendingArbitrumRewards;
  const availablePendingRewards = totalRewardsEared - totalRewardsWithdrawn

  // Local states for the animated display
  const [displayedPoints, setDisplayedPoints] = useState<number>(0);
  const [displayedMorRewards, setDisplayedMorRewards] = useState<number>(0);

  // Animate displayedPoints
  useEffect(() => {
    if (totalPoints == null || info?.pointsPerDay == null) {
      setDisplayedPoints(0);
      return;
    }

    // Immediately set to the current "real" value
    const { pointsPerDay } = info;
    setDisplayedPoints(totalPoints);

    // Then gradually increment
    const pointsPerMs = pointsPerDay / 86400000; // day -> ms
    const pointsPer200Ms = pointsPerMs * 200;
    const intervalId = setInterval(() => {
      if (totalPoints > 0) {
        setDisplayedPoints((prev) => prev + pointsPer200Ms);
      }
    }, 200);

    return () => clearInterval(intervalId);
  }, [totalPoints, info?.pointsPerDay]);

  // Animate displayedMorRewards
  useEffect(() => {
    if (totalPendingRewards == null || info?.morRewardsPerDay == null) {
      setDisplayedMorRewards(0);
      return;
    }

    // Immediately set to the current "real" value
    const { morRewardsPerDay } = info;
    setDisplayedMorRewards(totalPendingRewards);
    // console.log('morRewardsPerDay', morRewardsPerDay)

    // Then gradually increment
    const rewardsPerS = morRewardsPerDay / 86400;
    const intervalId = setInterval(() => {
      if (morRewards > 0) {
        setDisplayedMorRewards((prev) => prev + rewardsPerS);
      }
    }, 1000);

   return () => clearInterval(intervalId);

  }, [totalPendingRewards, info?.morRewardsPerDay]);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <Flex width='full' justifyContent='space-between' alignItems='center' gap={4} flexDir={{ base: 'column', md: 'row' }}>

      <Card.Root background='url("/external/2x.png") center / 100% auto no-repeat,
            linear-gradient(to top right, #FF378B, #6060FF) center / cover no-repeat'>
        <Card.Body gap={2}>
          <Heading size='sm' color='white'>Double MOR Rewards!</Heading>
          <Text color='whiteAlpha.700' fontSize='sm'>For a limited time, earn 20% APR on each MOR staked!</Text>
        </Card.Body>
      </Card.Root>

      <Flex>
      <Stat.Root flex="auto" alignItems="center" px={8}>
  {/* Lay out the big number and the per-chain stack side-by-side */}
  <Flex direction="row" alignItems="center" gap={3}>
    <Stat.ValueText fontSize="3xl" fontWeight="normal">
      {formatDecimals(parseFloat(info?.stakedBalance ?? '0'), 3)}
    </Stat.ValueText>

    {/* Now stack the two smaller chain amounts vertically */}
    <Flex direction="column" alignItems="flex-start">
      <Flex alignItems="center" gap={1}>
        <img
          src="/external/base_icon.png"
          alt="Base"
          style={{ height: '1em' }}
        />
        <Text fontSize="sm">{formatDecimals(parseFloat(info?.stakedBaseBalance ?? '0'), 2)}</Text>
      </Flex>
      <Flex alignItems="center" gap={1}>
        <img
          src="/external/arbitrum_icon.png"
          alt="Arbitrum"
          style={{ height: '1em' }}
        />
        <Text fontSize="sm">{formatDecimals(parseFloat(info?.stakedArbitrumBalance ?? '0'), 2)}</Text>
      </Flex>
    </Flex>
  </Flex>

  <Stat.Label>STAKED MOR</Stat.Label>
</Stat.Root>

        <Stat.Root flex='auto' alignItems='center' borderLeftWidth={1} borderColor='border' borderRightWidth={1} px={8}>
          <Stat.ValueText fontSize='3xl' fontWeight='normal'>{formatDecimals(displayedPoints, 4)}</Stat.ValueText>
          <Stat.Label>COINCAP POINTS</Stat.Label>
        </Stat.Root>
        <Stat.Root flex='auto' alignItems='center' px={8}>
          <Stat.ValueText fontSize='3xl' fontWeight='normal'>{formatDecimals(displayedMorRewards, 6)}</Stat.ValueText>
          <Stat.Label>MOR REWARDS (20% APR)</Stat.Label>
        </Stat.Root>
        {/* Adjusted onOpenChange here to accept details and set the state accordingly */}
        <Popover.Root
  open={isDropdownOpen}
  onOpenChange={(details) => setIsDropdownOpen(details.open)}
>
  <Popover.Trigger asChild>
    <IconButton
      variant="primary"
      onClick={() => setIsDropdownOpen((prev) => !prev)}
    >
      <LuChevronDown />
    </IconButton>
  </Popover.Trigger>

  <Portal>
    {/* Increase the minWidth so the left side is wider and text won't wrap */}
    <Popover.Positioner>
      <Popover.Content minWidth={600}>
        <Flex flexDir={{ base: "column", md: "row" }}>
          {/* LEFT SIDE: Pending rewards info */}
          <Box
            p={6}
            borderRightWidth={1}
            borderColor="border.muted"
            flex={1}
          >
            {/* Heading */}
            <Text fontSize="xs" fontWeight="medium" color="gray.500">
              PENDING MOR REWARDS
            </Text>

            {/* Bold "Available" + total */}
            <Text fontSize="large" fontWeight="bold">
            {formatDecimals(availablePendingRewards ?? 0, 4)} Available
              <Text as="span" fontWeight="normal" ml={1}>
                ({formatDecimals(totalPendingRewards ?? 0, 4)} Total)
              </Text>
            </Text>

            {/* Chain icons and amounts below it */}
            <Flex mt={2} gap={4}>
              <Flex alignItems="center" gap={1}>
                <img
                  src="/external/base_icon.png"
                  alt="Base"
                  style={{ height: "1em" }}
                />
                <Text fontSize="sm">{formatDecimals(availablePendingBaseRewards ?? 0, 4)}</Text>
              </Flex>
              <Flex alignItems="center" gap={1}>
                <img
                  src="/external/arbitrum_icon.png"
                  alt="Arbitrum"
                  style={{ height: "1em" }}
                />
                <Text fontSize="sm">{formatDecimals(availablePendingArbitrumRewards ?? 0, 4)}</Text>
              </Flex>
            </Flex>

            {/* Next withdraw date */}
            <Box mt={6}>
              <Text fontSize="xs" fontWeight="medium" color="gray.500">
                NEXT WITHDRAW DATE
              </Text>
              <Text fontSize="md" fontWeight="bold">
                April 15, 2025.
              </Text>
            </Box>
          </Box>

          {/* RIGHT SIDE: The same buttons as before */}
          <Stack p={4} flex={1}>
            <Button
              justifyContent="flex-start"
              variant="surface"
              width="full"
              onClick={async () => {
                setIsWithdrawRewards(false);
                stakeMorAndConnectWallet(1, false);
                setIsDropdownOpen(false);
              }}
            >
              <LuDownload />
              Stake MOR
            </Button>
            <Button
              justifyContent="flex-start"
              variant="surface"
              width="full"
              onClick={() =>{
                if(availablePendingRewards === 0) return
                setIsWithdrawRewards(true);
                setChainSelectionModalVisible(true)
              } }
            >
              <LuUpload />
              Withdraw Rewards
            </Button>
            <Button
              justifyContent="flex-start"
              variant="surface"
              width="full"
              onClick={() => setWhatIsModalVisible(true)}
            >
              <LuMessageCircleQuestion />
              What is MOR Staking?
            </Button>
          </Stack>
        </Flex>
      </Popover.Content>
    </Popover.Positioner>
  </Portal>
</Popover.Root>
      </Flex>
    </Flex>
  );
};

export default StakingStatsContainer;
