import React, { useEffect } from "react";
import { useModalState } from "../../helpers/ModalProvider"; // adjust path
import {
  DialogRoot,
  DialogBackdrop,
  DialogContent,
} from "../../components/ui/dialog";

const AprilMorUpgradeModal: React.FC = () => {
  const { isAprilMorUpgradeModalVisible, setAprilMorUpgradeModalVisible } =
    useModalState();

  // --- "Show once" logic keys
  const BEFORE_KEY = "aprilMorUpgradeModalShownBefore10";
  const AFTER_KEY = "aprilMorUpgradeModalShownAfter10";

  useEffect(() => {
    const now = new Date();
    // Adjust the year as needed
    const april10 = new Date("2025-04-10T00:00:00");
    const april14 = new Date("2025-04-14T00:00:00");

    if (now < april10) {
      // Before April 10
      const shownBefore = localStorage.getItem(BEFORE_KEY);
      if (!shownBefore) {
        setAprilMorUpgradeModalVisible(true);
        localStorage.setItem(BEFORE_KEY, "true");
      }
    } else if (now >= april10 && now < april14) {
      // On/After April 10 but before April 14
      const shownAfter = localStorage.getItem(AFTER_KEY);
      if (!shownAfter) {
        setAprilMorUpgradeModalVisible(true);
        localStorage.setItem(AFTER_KEY, "true");
      }
    } else {
      // After April 14
      // never show the modal
      setAprilMorUpgradeModalVisible(false);
    }
  }, [setAprilMorUpgradeModalVisible]);

  const handleContinue = () => {
    setAprilMorUpgradeModalVisible(false);
  };

  return (
    <DialogRoot
      lazyMount
      open={isAprilMorUpgradeModalVisible}
      onOpenChange={(openState) => setAprilMorUpgradeModalVisible(openState.open)}
    >
      <DialogBackdrop />
      <DialogContent
        // Outer container styling for the modal
        style={{
          // from your Figma snippet:
          width: 540,
          maxWidth: 540,
          padding: 36,
          background: "white",
          boxShadow: "0px 6px 12px rgba(24.51, 12.15, 50.78, 0.04)",
          borderRadius: 36,
          // to replicate Figma flex layout:
          display: "inline-flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: 36,
        }}
      >
        {/* Title */}
        <div
          style={{
            // Title from Figma:
            color: "rgba(24.51, 12.15, 50.78, 0.80)",
            fontSize: 24,
            fontFamily: "Be Vietnam Pro, sans-serif",
            fontWeight: 800,
            lineHeight: "36px",
            wordWrap: "break-word",
          }}
        >
          Morpheus Staking v2 Migration
        </div>

        {/* Body text */}
        <div style={{ alignSelf: "stretch" }}>
          <span
            style={{
              color: "rgba(24.51, 12.15, 50.78, 0.80)",
              fontSize: 14,
              fontFamily: "Be Vietnam Pro, sans-serif",
              fontWeight: 400,
              lineHeight: "17.36px",
              wordWrap: "break-word",
            }}
          >
            Morpheus is migrating their builder staking contract to version 2{" "}
          </span>
          <span
            style={{
              color: "rgba(24.51, 12.15, 50.78, 0.80)",
              fontSize: 14,
              fontFamily: "Be Vietnam Pro, sans-serif",
              fontWeight: 700,
              lineHeight: "17.36px",
              wordWrap: "break-word",
            }}
          >
            starting on Thursday, April 10th
          </span>
          <span
            style={{
              color: "rgba(24.51, 12.15, 50.78, 0.80)",
              fontSize: 14,
              fontFamily: "Be Vietnam Pro, sans-serif",
              fontWeight: 400,
              lineHeight: "17.36px",
              wordWrap: "break-word",
            }}
          >
            . Starting on April 10th, all MOR staked to ALL builder subnets will
            be locked until the migration is complete{" "}
          </span>
          <span
            style={{
              color: "rgba(24.51, 12.15, 50.78, 0.80)",
              fontSize: 14,
              fontFamily: "Be Vietnam Pro, sans-serif",
              fontWeight: 700,
              lineHeight: "17.36px",
              wordWrap: "break-word",
            }}
          >
            on Monday, April 14th
          </span>
          <span
            style={{
              color: "rgba(24.51, 12.15, 50.78, 0.80)",
              fontSize: 14,
              fontFamily: "Be Vietnam Pro, sans-serif",
              fontWeight: 400,
              lineHeight: "17.36px",
              wordWrap: "break-word",
            }}
          >
            .
            <br />
            <br />
            Staking & unstaking functionality
            will be fully disabled until the migration is completed, and we recommend that you do not stake any MOR to the CoinCap subnet or other Morpheus builder subnets until April 14th. If you have other
            questions, please join our{" "}
          </span>
          <a
            href="https://mee6.xyz/en/i/S3ZfymIhLR"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: "#5061AC",
              fontSize: 14,
              fontFamily: "Be Vietnam Pro, sans-serif",
              fontWeight: 400,
              lineHeight: "17.36px",
              wordWrap: "break-word",
              textDecoration: "underline",
            }}
          >
            Discord server
          </a>
          <span
            style={{
              color: "rgba(24.51, 12.15, 50.78, 0.80)",
              fontSize: 14,
              fontFamily: "Be Vietnam Pro, sans-serif",
              fontWeight: 400,
              lineHeight: "17.36px",
              wordWrap: "break-word",
            }}
          >
            .
          </span>
        </div>

        {/* Button container (bottom-right) */}
        <div
          style={{
            alignSelf: "stretch",
            justifyContent: "flex-end",
            alignItems: "flex-start",
            gap: 16,
            display: "inline-flex",
          }}
        >
          <button
            onClick={handleContinue}
            style={{
              // from Figma snippet:
              minWidth: 42,
              minHeight: 42,
              padding: 12,
              background: "#5061AC",
              borderRadius: 12,
              border: "none",
              // for text inside:
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 4,
              cursor: "pointer",
            }}
          >
            <div
              style={{
                paddingLeft: 2,
                paddingRight: 2,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <div
                style={{
                  color: "white",
                  fontSize: 14,
                  fontFamily: "Be Vietnam Pro, sans-serif",
                  fontWeight: 600,
                  lineHeight: "16.8px",
                  wordWrap: "break-word",
                }}
              >
                I understand
              </div>
            </div>
          </button>
        </div>
      </DialogContent>
    </DialogRoot>
  );
};

export default AprilMorUpgradeModal;
