import React from "react";
import { useGlobalState } from "../../../helpers/GlobalStateProvider";
import { CardBody, CardRoot, DialogBody, Flex, Link, Stack, Text } from "@chakra-ui/react";
import { DialogCloseTrigger, DialogHeader, DialogTitle } from "../../../components/ui/dialog";

interface StakeModal2Props {
  handleClose: () => void;
}

const StakeModal2: React.FC<StakeModal2Props> = ({ handleClose }) => {
  const { transactionDetails, selectedChainId } = useGlobalState();

  console.log("transactionDetails is", transactionDetails);
  const formattedTransactionId = `${transactionDetails?.tx?.slice(0, 6)}...${transactionDetails?.tx?.slice(-4)}`;

  const morValueInUSD =
    transactionDetails.morAmount * transactionDetails.morPrice;
  const ethFeeInUSD = transactionDetails.ethFee * transactionDetails.ethPrice;
  const totalCostInUSD = morValueInUSD + ethFeeInUSD;

  return (
    <>
    <DialogHeader>
      <DialogTitle>Transaction Sent</DialogTitle>
      <DialogCloseTrigger />
    </DialogHeader>
    <DialogBody>
      <Text>Your transaction has been submitted. It may take a few minutes for your account to reflect any changes. You can view the status of your transaction by clicking the transaction ID below, or close this window.</Text>
      <CardRoot variant='subtle' mt={8}>
        <CardBody>
          <Stack>
            <Flex justifyContent="space-between">
              <Text color='fg.muted'>
                {transactionDetails.txType === "stake"
                  ? "Staking Amount:"
                  : "Withdraw Amount"}
                </Text>
              <Text>{transactionDetails.morAmount.toFixed(3)} MOR (${morValueInUSD.toFixed(2)})</Text>
            </Flex>
            <Flex justifyContent="space-between">
              <Text color='fg.muted'>Fee:</Text>
              <Text>{transactionDetails.ethFee.toFixed(8)} ETH (${ethFeeInUSD.toFixed(2)})</Text>
            </Flex>
            {transactionDetails.txType === "stake" && (
              <Flex justifyContent="space-between">
                <Text color='fg.muted'>Total Cost:</Text>
                <Text>${totalCostInUSD.toFixed(2)}</Text>
              </Flex>
            )}
            <Flex justifyContent="space-between">
              <Text color='fg.muted'>Transaction ID:</Text>
              <Link href={ selectedChainId === 42161 ? `https://arbiscan.io/tx/${transactionDetails.tx}` : `https://basescan.org/tx/${transactionDetails.tx}`} target="_blank" rel="noopener noreferrer">{formattedTransactionId}</Link>
            </Flex>
          </Stack>
        </CardBody>
      </CardRoot>
    </DialogBody>
    </>
  );
};

export default StakeModal2;
