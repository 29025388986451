import React, { useState, useEffect } from "react";
import HeaderContainer from "./Header";
import { useNavigate } from "react-router-dom";
import { env, useGlobalState } from "../helpers/GlobalStateProvider"; // Import your wallet hook
import axios from "axios";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { apiUrls } from "../config";
import { useModalState } from "../helpers/ModalProvider";
import { useDisconnect, useWalletClient } from "wagmi";
import { ethers } from "ethers";
import { migrateLocalChatHistory } from "./chatbot/chatHistoryStorage";

const Signin: React.FC<any> = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [waitingForWallet, setWaitingForWallet] = useState(false); // New state to track waiting for connection
  const navigate = useNavigate();
  const { openConnectModal } = useConnectModal();
  const { data: walletClient } = useWalletClient();

  const { showErrorMessage, handleCloseStakeModal, showResetPasswordModal } = useModalState();

  const { refreshAccountInfo, registerWallet, setBearerToken } = useGlobalState();

  const { disconnect } = useDisconnect();

  useEffect(() => {
    // If we were waiting for a wallet and now wallet.provider is available, try runWalletLogin again
    if (waitingForWallet && walletClient) {
      runWalletLogin();
    }
  }, [waitingForWallet, walletClient]);

  const handleEmailSignIn = async () => {
    try {
      const response = await fetch(`${apiUrls[env]}/v3/apiKey/email_login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email_address: email,
          password: password,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        const message = errorData.message || "Error signing in.";
        throw new Error(message);
      }

      const data = await response.json();
      console.log("Got bearer token:", data.bearerToken);
      localStorage.setItem("bearerToken", data.bearerToken);
      setBearerToken(data.bearerToken);
      migrateLocalChatHistory();
      navigate("/dashboard");
      await refreshAccountInfo();
    } catch (error: any) {
      console.log('errorr is', error)
      if(error.toString().includes('Too many'))
      showErrorMessage(error.toString());
      else {
        showErrorMessage("Invalid email or password");
      }
    }
  };

  const runWalletLogin = async () => {
    setWaitingForWallet(false); // Reset waiting state now that we're attempting the login

    try {
      
      if (!walletClient) {
        showErrorMessage(
          "No wallet connected. Please connect your wallet first.",
        );
        return;
      }
      const provider = new ethers.BrowserProvider(walletClient);

      const signer = await provider.getSigner();
      const message = `Sign this message to authenticate with the coincap API. ${Date.now()}`;
      const signedMessage = await signer.signMessage(message);

      // Call /wallet_login
      const response = await axios.post(
        `${apiUrls[env]}/v3/apiKey/wallet_login`,
        {
          originalMessage: message,
          signedMessage,
        },
      );

      if (response.status !== 200) {
        const msg = response.data.message || "Error signing in with wallet.";
        showErrorMessage(msg);
        return;
      }

      const data = response.data;
      localStorage.setItem("bearerToken", data.bearerToken);
      setBearerToken(data.bearerToken);
      migrateLocalChatHistory();
      navigate("/dashboard");
      await refreshAccountInfo();
    } catch (error: any) {
      console.log("Error during wallet sign in:", error);

      if (
        error?.response?.data?.message ===
        "No user found for this wallet address."
      ) {
        console.log(
          "account does not yet exist for this wallet, auto registering",
        );
        await registerWallet();
        navigate("/dashboard");
      }
      else if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        showErrorMessage(error.response.data.message);
      } else {
        showErrorMessage("An unexpected error occurred. Please try again.");
      }
    }
  };

  const handleConnectWalletClick = async () => {
    // set some big localstorage values
    if (!walletClient) {
      // No wallet connected, open the connect modal
      openConnectModal?.();
      setWaitingForWallet(true);
    } else {
      // Wallet is already connected
      runWalletLogin();
    }
  };

  useEffect(() => {
      disconnect();
  }, [])

  return (
    <div
      style={{
        minHeight: "100vh",
        width: "100%",
        maxWidth: "1392px",
        margin: "0 auto",
        paddingTop: "24px",
        paddingBottom: "24px",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
      }}
    >
      {/* Header */}
      <HeaderContainer behavior="signin" />

      {/* Main container to center the content */}
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
        }}
      >
        {/* Content wrapper for max width */}
        <div
          style={{
            maxWidth: "500px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "40px",
          }}
        >
          {/* Page title */}
          <h1
            style={{
              margin: 0,
              textAlign: "center",
              color: "#181432CC",
              fontSize: "24px",
              fontWeight: 800,
              lineHeight: "36px",
            }}
          >
            Sign in to your account
          </h1>

          {/* Sign In Card */}
          <div
            style={{
              width: "100%",
              padding: "36px",
              background: "white",
              boxShadow: "0px 6px 12px rgba(24.51, 12.15, 50.78, 0.04)",
              borderRadius: "36px",
              display: "flex",
              flexDirection: "column",
              gap: "36px",
            }}
          >
            {/* Email & Password Fields */}
            <div
              style={{ display: "flex", flexDirection: "column", gap: "48px" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "24px",
                }}
              >
                {/* Email field */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        color: "#473D5C",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "16.8px",
                      }}
                    >
                      Email address
                    </div>
                  </div>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{
                      width: "100%",
                      height: "42px",
                      padding: "12px",
                      background: "white",
                      borderRadius: "12px",
                      border: "1px solid #D1CED6",
                      fontSize: "14px",
                    }}
                  />
                </div>

                {/* Password field */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        color: "#473D5C",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "16.8px",
                      }}
                    >
                      Password
                    </div>
                    <div
                      onClick={showResetPasswordModal}
                      style={{
                        color: "#5061AC",
                        fontSize: "14px",
                        fontWeight: 600,
                        lineHeight: "16.8px",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      Forgot password?
                    </div>
                  </div>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    style={{
                      width: "100%",
                      height: "42px",
                      padding: "12px",
                      background: "white",
                      borderRadius: "12px",
                      border: "1px solid #D1CED6",
                      fontSize: "14px",
                    }}
                  />
                </div>
              </div>

              {/* Sign In Button */}
              <div
                onClick={handleEmailSignIn}
                style={{
                  width: "100%",
                  padding: "12px",
                  background: "#5061AC",
                  borderRadius: "12px",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "inline-flex",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    paddingLeft: "4px",
                    paddingRight: "4px",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      color: "white",
                      fontSize: "14px",
                      fontWeight: 600,
                      lineHeight: "16.8px",
                    }}
                  >
                    Sign in
                  </div>
                </div>
              </div>
            </div>

            {/* Connect a Wallet Section */}
            <div
              style={{ display: "flex", flexDirection: "column", gap: "36px" }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  gap: "32px",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    height: 0,
                    borderTop: "1px solid rgba(24.51, 12.15, 50.78, 0.10)",
                  }}
                ></div>
                <div
                  style={{
                    color: "rgba(24.51,12.15,50.78,0.80)",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "21px",
                    letterSpacing: "0.28px",
                  }}
                >
                  Or connect a wallet
                </div>
                <div
                  style={{
                    flex: 1,
                    height: 0,
                    borderTop: "1px solid rgba(24.51, 12.15, 50.78, 0.10)",
                  }}
                ></div>
              </div>
              <div
                onClick={handleConnectWalletClick}
                style={{
                  width: "100%",
                  padding: "12px",
                  borderRadius: "12px",
                  border: "1px solid #5061AC",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "inline-flex",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    paddingLeft: "4px",
                    paddingRight: "4px",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      color: "#5061AC",
                      fontSize: "14px",
                      fontWeight: 600,
                      lineHeight: "16.8px",
                    }}
                  >
                    Connect a wallet
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Get Started Link */}
          <div style={{ textAlign: "center" }}>
            <span
              style={{
                color: "rgba(24.51, 12.15, 50.78, 0.40)",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "21px",
              }}
            >
              Don’t have an account?
            </span>
            <span style={{ color: "white" }}> </span>
            <a
              href="/signup"
              rel="noopener noreferrer"
              style={{
                color: "#5061AC",
                fontSize: "14px",
                fontWeight: 600,
                textDecoration: "underline",
                lineHeight: "21px",
              }}
            >
              Get started for free
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
