// ChatSidebar.tsx
import React, { useEffect, useRef } from "react";
import { useModalState } from "../../helpers/ModalProvider";
import { useGlobalState } from "../../helpers/GlobalStateProvider";

const ChatSidebar: React.FC = () => {
  const optionsMenuRef = useRef<HTMLDivElement | null>(null);

  const {
    chatHistory,
    selectedChatId,
    info,
    setSelectedChatId,
    setChatHistory,
  } = useGlobalState();

  const isWalletConnected = !!info?.walletAddress;

  const selectChat = (chatId: number) => {
    setSelectedChatId(chatId);
    setChatOptionsId(null);
  };

  const createNewChat = (): number => {
    const newChatId = Date.now();
    const newChat = {
      id: newChatId,
      title: "New Chat",
      messages: [],
    };
    setChatHistory((prev: any) => [...prev, newChat]);
    setSelectedChatId(newChatId);
    return newChatId;
  };

  const {
    chatOptionsId,
    setChatOptionsId,
    showRenameChatModal,
    showDeleteChatModal,
  } = useModalState();

  // Determine user's account info
  const userTier = info?.tier || 0;

  // Manage chat creation constraints
  const maxChats = userTier === 0 ? 5 : Infinity;
  const canCreateNewChat =
    (isWalletConnected &&
      (maxChats === Infinity || chatHistory.length < maxChats)) ||
    (!isWalletConnected && chatHistory.length === 0);

  // Close the options menu if user clicks outside it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        optionsMenuRef.current &&
        !optionsMenuRef.current.contains(event.target as Node)
      ) {
        setChatOptionsId(null);
      }
    };

    if (chatOptionsId !== null) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [chatOptionsId, setChatOptionsId]);

  // Chat list container style
  const chatListContainerStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    overflowY: "auto",
    flex: 1,
    minHeight: 0,
  };

  return (
    <aside
      style={{
        flex: 1,
        minHeight: 0,
        width: "100%",
        background: "rgba(255, 255, 255, 0.80)",
        boxShadow: "0px 6px 12px rgba(24, 12, 51, 0.04)",
        borderRadius: "40px",
        border: "1px solid rgba(255, 255, 255, 0.36)",
        padding: "40px 24px",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        boxSizing: "border-box",
      }}
    >
      <h2
        style={{
          fontSize: "24px",
          fontWeight: "800",
          color: "#190C33",
          marginBottom: "16px",
        }}
      >
        Chats ({chatHistory.length})
      </h2>

      {/* "New Chat" button */}
      <div
        style={{
          position: "relative",
          padding: "8px 12px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: canCreateNewChat ? "pointer" : "not-allowed",
          color: canCreateNewChat ? "black" : "rgba(0, 0, 0, 0.3)",
          fontSize: "16px",
          fontWeight: "500",
          background: canCreateNewChat ? "transparent" : "rgba(0, 0, 0, 0.05)",
          borderRadius: "12px",
        }}
        onClick={() => {
          if (canCreateNewChat) {
            createNewChat();
          }
        }}
        onMouseEnter={(e) => {
          // Show tooltip if user can't create a new chat
          const tooltip = document.createElement("div");
          tooltip.id = "tooltip";
          tooltip.innerText = !isWalletConnected
            ? "Connect your wallet to create new chats."
            : chatHistory.length >= maxChats
              ? "You have reached the maximum number of chats allowed. Upgrade your account to create more."
              : "";
          tooltip.style.position = "absolute";
          tooltip.style.top = "-30px";
          tooltip.style.left = "0";
          tooltip.style.background = "#333";
          tooltip.style.color = "#fff";
          tooltip.style.padding = "6px 12px";
          tooltip.style.borderRadius = "4px";
          tooltip.style.fontSize = "12px";
          tooltip.style.whiteSpace = "nowrap";
          tooltip.style.zIndex = "1000";
          tooltip.style.visibility = canCreateNewChat ? "hidden" : "visible";
          e.currentTarget.appendChild(tooltip);
        }}
        onMouseLeave={(e) => {
          const tooltip = document.getElementById("tooltip");
          if (tooltip) {
            e.currentTarget.removeChild(tooltip);
          }
        }}
      >
        New Chat
        <img
          src="/external/plus-circle.svg"
          alt="New Chat"
          style={{
            width: "16px",
            height: "16px",
            opacity: canCreateNewChat ? 1 : 0.3,
          }}
        />
      </div>

      {/* Chat List Container */}
      <div style={chatListContainerStyle}>
        {chatHistory.map((chat: any) => {
          const isSelected = chat.id === selectedChatId;
          return (
            <div
              key={chat.id}
              style={{
                width: "100%",
                height: "44px",
                paddingTop: 8,
                paddingBottom: 8,
                paddingLeft: 12,
                paddingRight: 10,
                background: isSelected
                  ? "rgba(24.51, 12.15, 50.78, 0.06)"
                  : "transparent",
                borderRadius: 12,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
                position: "relative",
                flexShrink: 0,
              }}
              onClick={() => selectChat(chat.id)}
              onContextMenu={(e) => {
                e.preventDefault();
                e.stopPropagation();
                // Right-click to rename
                // Instead of local states:
                // We'll call showRenameChatModal from the ModalProvider
                showRenameChatModal(chat.id, chat.title);
                console.log("Right-clicked on chat:", chat.title);
              }}
            >
              <div
                style={{
                  flex: 1,
                  color: isSelected
                    ? "rgba(24.51, 12.15, 50.78, 0.80)"
                    : "rgba(24, 12, 51, 0.52)",
                  fontSize: 14,
                  fontWeight: "500",
                  lineHeight: "21px",
                  wordWrap: "break-word",
                }}
              >
                {chat.title}
              </div>

              {/* Ellipsis button for options */}
              <button
                onClick={(event) => {
                  event.stopPropagation();
                  // We'll store the selected chat ID in `chatOptionsId`
                  setChatOptionsId(chat.id);
                }}
                style={{
                  background: "transparent",
                  border: "none",
                  cursor: "pointer",
                  padding: 0,
                  margin: 0,
                  marginLeft: "auto",
                }}
              >
                <img
                  src="/external/ellipsis-horizontal.svg"
                  alt="Options"
                  style={{
                    width: "24px",
                    height: "24px",
                  }}
                />
              </button>

              {/* Options Menu */}
              {chatOptionsId === chat.id && (
                <div
                  ref={optionsMenuRef}
                  style={{
                    position: "absolute",
                    top: "100%",
                    right: "0",
                    background: "#FFFFFF",
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                    borderRadius: "8px",
                    zIndex: 1,
                  }}
                >
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      showRenameChatModal(chat.id, chat.title);
                      setChatOptionsId(null);
                    }}
                    style={{
                      padding: "8px 16px",
                      background: "transparent",
                      border: "none",
                      cursor: "pointer",
                      width: "100%",
                      textAlign: "left",
                    }}
                  >
                    Rename
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      // We can call showDeleteChatModal from the ModalProvider:
                      showDeleteChatModal(chat.id, chat.title);
                      setChatOptionsId(null);
                    }}
                    style={{
                      padding: "8px 16px",
                      background: "transparent",
                      border: "none",
                      cursor: "pointer",
                      width: "100%",
                      textAlign: "left",
                    }}
                  >
                    Delete Chat
                  </button>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </aside>
  );
};

export default ChatSidebar;
