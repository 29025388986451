import React from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalState } from "../../helpers/GlobalStateProvider";

interface WalletInfoProps {
  disconnectWallet: () => void;
  info: any;
}

const WalletInfo: React.FC<WalletInfoProps> = ({
  info,
  disconnectWallet
}) => {
  const walletAddress = info?.walletAddress
  const formattedAddress = walletAddress ? `0x${walletAddress.toUpperCase().slice(2, 9)}` : '';
  let tier = "FREE";
  if (info?.tier > 0) tier = "PRO";

  const goToSignin = () => {
    navigate('/signin')
  }

  const{ isSignedIn } = useGlobalState();

  const navigate = useNavigate();
  return (
    <div
      style={{
        width: 320,
        height: 242,
        paddingTop: 36,
        paddingBottom: 36,
        paddingLeft: 36,
        paddingRight: 24,
        background: "rgba(255, 255, 255, 0.80)",
        boxShadow: "0px 6px 12px rgba(24.51, 12.15, 50.78, 0.04)",
        borderRadius: 40,
        border: "1px rgba(255, 255, 255, 0.36) solid",
        backdropFilter: "blur(120px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: 36,
      }}
    >
      <div
        style={{
          alignSelf: "stretch",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 12,
        }}
      >
        <img
          src="/external/rectangle11.png"
          alt="Wallet Icon"
          style={{
            width: 48,
            height: 48,
            borderRadius: 18,
          }}
        />
        <div style={{ display: "flex", flexDirection: "column", gap: 0 }}>
          <div
            style={{
              color: "#000000",
              fontSize: 16,
              fontWeight: "800",
              lineHeight: "24px",
            }}
          >
            {formattedAddress}
          </div>
          <div
            style={{
              color: "#FFB82E",
              fontSize: 14,
              fontWeight: "400",
              lineHeight: "21px",
            }}
          >
            {tier}
          </div>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 12,
            cursor: "pointer",
          }}
          onClick={isSignedIn() ? disconnectWallet : goToSignin}
        >
          <img
            src="/external/arrow-right-start-on-rectangle.svg"
            alt="Sign Out Icon"
            style={{
              width: 16,
              height: 16,
            }}
          />
          <div
            style={{
              color: "#463C5B",
              fontSize: 14,
              fontWeight: "400",
              lineHeight: "21px",
            }}
          >
            {isSignedIn() ? 'Sign Out' : 'Sign In'}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 12,
            cursor: "pointer",
          }}
          onClick={() => window.open("https://coincap.io", "_blank")}
        >
          <img
            src="/external/group-18.svg"
            alt="CoinCap.io Icon"
            style={{
              width: 16,
              height: 16,
            }}
          />
          <div
            style={{
              color: "#463C5B",
              fontSize: 14,
              fontWeight: "400",
              lineHeight: "21px",
            }}
          >
            CoinCap.io
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 12,
            cursor: "pointer",
          }}
          onClick={() => window.open("https://mee6.xyz/en/i/S3ZfymIhLR", "_blank")}
        >
          <img
            src="/external/question-mark-circle.svg"
            alt="Help Icon"
            style={{
              width: 16,
              height: 16,
              filter: "brightness(0)",
            }}
          />
          <div
            style={{
              color: "#463C5B",
              fontSize: 14,
              fontWeight: "400",
              lineHeight: "21px",
            }}
          >
            Help
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletInfo;
