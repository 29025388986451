// Settings.tsx
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { apiUrls, FIAT_ENABLED } from "../../config";
import { env, STRIPE_CUSTOMER_PORTAL, useGlobalState } from "../../helpers/GlobalStateProvider";
import { useModalState } from "../../helpers/ModalProvider";

// Components
import HeaderContainer from "../Header";
import APIPlansContainer from "../APIPlansContainer";
import MyAccountSection from "./MyAccountSection";
import MorStakingSection from "./MorStakingSection";
import PaymentsSection from "./PaymentsSection";
import DeleteAccountSection from "./DeleteAccountSection";
import { clearChatHistory } from "../chatbot/chatHistoryStorage";

// We no longer directly import DeleteAuthModal, AddEmailModal, or UpdateAuthMethodModal here.
// Those are rendered in `ModalDisplay` using the modal provider state.

const Settings: React.FC<any> = () => {
  const navigate = useNavigate();
  const { info } = useGlobalState();

  // Pull up the new modal triggers from the ModalProvider
  const {
    showDeleteAuthModal,
    showAddEmailModal,
    showUpdateAuthModal,
    showErrorMessage,
    showDeleteAccountModal
  } = useModalState();

  const doDeleteAccount = async () => {
    showDeleteAccountModal()
  };

  /**
   * Opens the payments portal in a new tab.
   */
  const openPaymentsPortal = () => {
    console.log("Opening payments portal...", STRIPE_CUSTOMER_PORTAL);
    window.open(`${STRIPE_CUSTOMER_PORTAL}`, "_blank");
  };

  // TODO move this kind of logic into common
  const getPlanType = useCallback(() => {
    if(!info?.tier || info?.tier === 0) return 'Free';
    else if(info?.tier === 1) return 'Basic';
    else if(info?.tier === 2) return 'Growth';
    else if(info?.tier === 3) return 'Professional';
    else if(info?.tier === 4) return 'Enterprise';
    else return 'Error'
  }, [info?.tier])

  // Example "plan" label for demonstration
  const planType = getPlanType()

  // Common “card style” for each section container
  const cardStyle: React.CSSProperties = {
    background: "#FFFFFF",
    borderRadius: "24px",
    padding: "24px 32px",
    boxShadow: "0px 6px 12px rgba(24,12,50,0.04)",
  };

  const truncated = (num: any) => parseFloat(num || 0).toFixed(6);

  // Scroll to the "API Plans" section
  const handleUpgradePlan = () => {
    const anchorElement = document.getElementById("api-plans-section");
    if (anchorElement) {
      anchorElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const handleDeleteEmail = () => {
    showDeleteAuthModal("email", info?.email || "");
  };
  const handleDeleteWallet = () => {
    showDeleteAuthModal("wallet", info?.walletAddress || "");
  };

  const handleShowAddEmailModal = () => {
    showAddEmailModal();
  };

  const handleEditEmail = () => {
    showUpdateAuthModal();
  };

  // Render
  return (
    <div
      style={{
        minHeight: "100vh",
        width: "100%",
        maxWidth: "1392px",
        margin: "0 auto",
        paddingTop: "24px",
        paddingBottom: "24px",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
      }}
    >
      <HeaderContainer behavior="dashboard" />

      {/* 1. My Account */}
      <MyAccountSection
        accountType={planType}
        userEmail={info?.email}
        hasEmail={!!info?.email}
        hasWallet={!!info?.walletAddress}
        walletAddress={info?.walletAddress}
        onUpgradePlan={handleUpgradePlan}
        onAddEmail={handleShowAddEmailModal}
        onEditEmail={handleEditEmail}
        onDeleteEmail={handleDeleteEmail}
        onDeleteWallet={handleDeleteWallet}
        cardStyle={cardStyle}
      />

      {/* 2. MOR Staking */}
      <MorStakingSection
        cardStyle={cardStyle}
        info={info}
        truncated={truncated}
      />

      {/* 3. Payments */}
      {FIAT_ENABLED && <PaymentsSection
        cardStyle={cardStyle}
        openPaymentsPortal={openPaymentsPortal}
      />}

      {/* 4. API Plans */}
      <div id="api-plans-section">
        <APIPlansContainer />
      </div>

      {/* 5. Delete Account */}
      <DeleteAccountSection doDeleteAccount={doDeleteAccount} />

      {/*
        We no longer render the modals (DeleteAuth, AddEmail, UpdateAuthMethod)
        directly here. Those are all rendered in `ModalDisplay` 
        using the states from our `ModalProvider`.
      */}
    </div>
  );
};

export default Settings;
