import React from "react";
import { useGlobalState } from "../../helpers/GlobalStateProvider";
import { useModalState } from "../../helpers/ModalProvider";

type MyAccountSectionProps = {
  accountType: string; // e.g. "Growth"
  userEmail?: string; // e.g. "elmutt@gmail.com"
  hasEmail: boolean;
  hasWallet: boolean;
  walletAddress?: string; // e.g. "0x4d4a31...4f961b2"
  onUpgradePlan: () => void; // for "upgrade plan" button
  onAddEmail: () => void; // for "+ Add email"
  onEditEmail: () => void; // calls your "edit email" flow
  onDeleteEmail: () => void; // calls doRemoveAuthMethod('email')
  onDeleteWallet: () => void; // calls doRemoveAuthMethod('wallet')
  cardStyle: React.CSSProperties; // styling for the card container
};

// A little helper style for each row
const rowStyle: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "16px",
};

// Slightly smaller helper for the label (left column)
const labelStyle: React.CSSProperties = {
  width: "200px", // or adjust as needed for your design
  fontSize: "14px",
  fontWeight: 600,
  color: "#181432",
};

// For the center “value”
const valueStyle: React.CSSProperties = {
  flex: 1, // take remaining space in the middle
  marginLeft: "24px", // spacing from the label
  fontSize: "14px",
  fontWeight: 400,
  color: "#473D5C",
};

const actionsStyle: React.CSSProperties = {
  marginLeft: "24px",
  display: "flex",
  gap: "16px",
};

const MyAccountSection: React.FC<MyAccountSectionProps> = ({
  accountType,
  userEmail,
  hasEmail,
  hasWallet,
  walletAddress,
  onUpgradePlan,
  onAddEmail,
  onEditEmail,
  onDeleteEmail,
  onDeleteWallet,
  cardStyle,
}) => {
  const { connectAndAssociateWallet } = useGlobalState();

  const { showCannotConnectWalletModal } = useModalState();

  // Determine if user has *exactly* one auth method (email or wallet)
  const onlyOneAuthMethod =
    (hasEmail && !hasWallet) || (hasWallet && !hasEmail);

  return (
    <div style={cardStyle}>
      <h2
        style={{
          margin: 0,
          fontSize: "24px",
          fontWeight: 800,
          lineHeight: "36px",
        }}
      >
        My Account
      </h2>
      <p style={{ margin: "4px 0 24px", fontSize: "14px", color: "#756D85" }}>
        View and manage your account details.
      </p>

      {/* Divider */}
      <div
        style={{
          width: "100%",
          height: "1px",
          background: "#E4E4E7",
          margin: "16px 0",
        }}
      />

      {/* ROW 1: Account Type */}
      <div style={rowStyle}>
        {/* Left column: label */}
        <span style={labelStyle}>Account Type</span>

        {/* Center column: value */}
        <span style={valueStyle}>{accountType}</span>

        {/* Right column: "upgrade plan" */}
        <button
          onClick={onUpgradePlan}
          style={{
            background: "transparent",
            color: "#5061AC",
            fontSize: "14px",
            fontWeight: 600,
            textDecoration: "underline",
            border: "none",
            cursor: "pointer",
          }}
        >
          upgrade plan
        </button>
      </div>

      {/* ROW 2: Email Authentication */}
      <div style={rowStyle}>
        {/* Left column: label */}
        <span style={labelStyle}>Email Authentication</span>

        {/* Center column: email or "Add email" */}
        {hasEmail ? (
          <div
            style={{
              ...valueStyle,
              display: "flex",
              alignItems: "center",
              gap: "6px",
            }}
          >
            <img
              src="/external/envelope.svg"
              alt="mail"
              style={{ width: "18px", height: "18px" }}
            />
            <span>{userEmail}</span>
          </div>
        ) : (
          <button
            style={{
              ...valueStyle,
              background: "transparent",
              color: "#5061AC",
              fontSize: "14px",
              fontWeight: 600,
              textDecoration: "underline",
              border: "none",
              textAlign: "left",
              cursor: "pointer",
              padding: 0,
            }}
            onClick={onAddEmail}
          >
            + Add email
          </button>
        )}

        {/* Right column: if hasEmail => "edit" | "delete"
            But hide 'delete' if this is the only auth method. */}
        {hasEmail && (
          <div style={actionsStyle}>
            <button
              onClick={onEditEmail}
              style={{
                background: "transparent",
                color: "#5061AC",
                fontSize: "14px",
                fontWeight: 600,
                textDecoration: "underline",
                border: "none",
                cursor: "pointer",
              }}
            >
              edit
            </button>

            {/* show "delete" only if it's NOT the only auth method */}
            {!onlyOneAuthMethod && (
              <button
                onClick={onDeleteEmail}
                style={{
                  background: "transparent",
                  color: "#AC5050",
                  fontSize: "14px",
                  fontWeight: 600,
                  textDecoration: "underline",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                delete
              </button>
            )}
          </div>
        )}
      </div>

      {/* ROW 3: Wallet Authentication */}
      <div style={rowStyle}>
        {/* Left column: label */}
        <span style={labelStyle}>Wallet Authentication</span>

        {/* Center column: wallet or "Add wallet" */}
        {hasWallet ? (
          <div
            style={{
              ...valueStyle,
              display: "flex",
              alignItems: "center",
              gap: "6px",
            }}
          >
            <img
              src="/external/wallet2.svg"
              alt="wallet"
              style={{ width: "18px", height: "18px" }}
            />
            <span>{walletAddress}</span>
          </div>
        ) : (
          <button
            style={{
              ...valueStyle,
              background: "transparent",
              color: "#5061AC",
              fontSize: "14px",
              fontWeight: 600,
              textDecoration: "underline",
              border: "none",
              textAlign: "left",
              cursor: "pointer",
              padding: 0,
            }}
            onClick={async () => {
              try {
                await connectAndAssociateWallet();
              } catch (e) {
                showCannotConnectWalletModal();
              }
            }}
          >
            + Add wallet
          </button>
        )}

        {/* Right column: if hasWallet => "delete"
            But hide 'delete' if this is the only auth method. */}
        {hasWallet && (
          <div style={actionsStyle}>
            {!onlyOneAuthMethod && (
              <button
                onClick={onDeleteWallet}
                style={{
                  background: "transparent",
                  color: "#AC5050",
                  fontSize: "14px",
                  fontWeight: 600,
                  textDecoration: "underline",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                delete
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MyAccountSection;
