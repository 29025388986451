// GlobalStateProvider.tsx
import React, { useState, useEffect, createContext, useContext, useCallback } from "react";
import { ethers } from "ethers";
import axios from "axios";
import { apiUrls } from "../config";
import { useAccount, useDisconnect, useWalletClient } from "wagmi";
import { useNavigate, NavigateFunction } from "react-router-dom";
import { useConnectModal } from "@rainbow-me/rainbowkit";

// We import the modal hook so we can open modals (like stake) from within the global state logic
import { useModalState } from "./ModalProvider";
import { clearChatHistory, getLocalChatHistory, migrateLocalChatHistory } from "../views/chatbot/chatHistoryStorage";
import { useTheme } from "next-themes";

const getEnv = () => {
  const host = window.location.host;
  if (host.includes('localhost')) return 'development';
  else if (host.includes('staging')) return 'stage';
  else if (host.includes('coincap.io')) return 'production';
  else throw new Error("Unknown environment");
}
export const env = getEnv();

// TODO change this for prod when we have a real stripe portal api keys
export const STRIPE_CUSTOMER_PORTAL = env === 'production' ? 'https://billing.stripe.com/p/login/3cs2a3bL84xZ2JybII' : 'https://billing.stripe.com/p/login/test_9AQ8zh0NN9VB1R6aEE'

console.log('exported env:', env)

interface GlobalStateProviderProps {
  children: React.ReactNode;
}

export interface GlobalStateContextType {
  info: any;
  refreshAccountInfo: () => Promise<any | null>;
  stakeMorAndConnectWallet: (amount: number, isUnstake: boolean) => Promise<void>;
  connectAndAssociateWallet: () => Promise<void>;
  transactionDetails: any;
  setTransactionDetails: React.Dispatch<React.SetStateAction<any>>;
  chatHistory: any[];
  setChatHistory: React.Dispatch<React.SetStateAction<any[]>>;
  selectedChatId: number | null;
  setSelectedChatId: React.Dispatch<React.SetStateAction<number | null>>;
  messages: any[];
  setMessages: React.Dispatch<React.SetStateAction<any[]>>;
  registerWallet: () => Promise<void>;
  isSignedIn: () => boolean;
  handleLogout: () => Promise<void>;
  morRewards: any;
  bearerToken: string | null;
  setBearerToken: any;
  selectedChainId: number;
  setSelectedChainId: React.Dispatch<React.SetStateAction<number>>;
  checkNetwork: () => Promise<boolean | undefined>;
  arbitrumRewards: any;
  baseRewards: any;
  rewardContractInfo: any;
  isWithdrawRewards: boolean
  setIsWithdrawRewards: React.Dispatch<React.SetStateAction<boolean>>;
  totalPoints: number
  updatePointsAndRewards: any
}

const GlobalStateContext = createContext<GlobalStateContextType>({} as any);

export const GlobalStateProvider: React.FC<GlobalStateProviderProps> = ({
  children,
}) => {
  const navigate = useNavigate();
  const value = useProvideGlobalState(navigate);

  return (
    <GlobalStateContext.Provider value={value}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => {
  return useContext(GlobalStateContext);
};


function useProvideGlobalState(navigate: NavigateFunction) {
  const { data: walletClient, isError, isLoading } = useWalletClient();
  const { openConnectModal } = useConnectModal();
  const { disconnect } = useDisconnect();
  const {
    showErrorMessage,
    handleCloseStakeModal,
    setChainSelectionModalVisible,
    setStakeModalDetails
  } = useModalState();
  const [info, setInfo] = useState<any>(null);
  const [transactionDetails, setTransactionDetails] = useState<any>(null);
  const [intervalId, setIntervalId] = useState<any>(null);
  const [chatHistory, setChatHistory] = useState<any[]>(getLocalChatHistory());
  const [selectedChatId, setSelectedChatId] = useState<number | null>(null);
  const [messages, setMessages] = useState<any[]>([]);

  const { address, status } = useAccount();
  const [currentAddress, setCurrentAddress] = useState<string | undefined>();

  const [morRewards, setMorRewards] = useState<any>(0);
  const [arbitrumRewards, setArbitrumRewards] = useState<any>(0);
  const [baseRewards, setBaseRewards] = useState<any>(0);
  const [rewardContractInfo, setRewardContractInfo] = useState<any>(0);

  const [bearerToken, setBearerToken] = useState<string | null>(localStorage.getItem("bearerToken"));

  const [selectedChainId, setSelectedChainId] = useState<number>(8453)

  const [isWithdrawRewards, setIsWithdrawRewards] = useState<boolean>(false);

  const [totalPoints, setTotalPoints] = useState<number>(0);

  const checkNetwork = useCallback(async () => {
    if(!walletClient) return
    const provider = new ethers.BrowserProvider(walletClient);
    const network = await provider.getNetwork();
    if(network?.chainId.toString() !== selectedChainId.toString()) {
      const networkName = selectedChainId === 42161 ? 'Arbitrum One' : 'Base'
      showErrorMessage(`Switch your browser wallet to the ${networkName} Network`)
      handleCloseStakeModal();
      return false
    }
    return true
  }, [walletClient, selectedChainId])

  useEffect(() => {
    if (status === 'disconnected' && currentAddress) {
      console.log('disconnected')
      setCurrentAddress(undefined);
     handleLogout();
    } else if (status === 'connected' && address && address !== currentAddress && currentAddress !== undefined) {
      console.log('connected address changed')
      setCurrentAddress(address);

      if(info?.walletAddress && (info?.walletAddress.toLowerCase() !== address.toLowerCase())) {
        console.log('changed to a different address than the current logged in address')
        showErrorMessage(`This wallet is already associated with another account. Log out of your current account and log in again using this wallet to continue.`);
        handleLogout();
      } 
    } else if (status === 'connected' && address && !currentAddress) {
      console.log('connected')
      setCurrentAddress(address);
    }
  }, [status, address, currentAddress]);
  
  useEffect(() => {
    if (status === 'connecting') {
      console.log("Wallet is connecting...");
      setCurrentAddress(undefined);
    }
  }, [status]);

  const refreshAccountInfo = useCallback(async (from?: string) => {
    const bearerToken = localStorage.getItem("bearerToken");
    if (!bearerToken) {
      setInfo(null);
      return null;
    }

    try {
      const response = await axios.post(
        `${apiUrls[env]}/v3/apiKey/info`,
        {},
        { headers: { Authorization: `Bearer ${bearerToken}` } },
      );
      setInfo(response.data);
      return response.data;
    } catch (err) {
      // clear token on error
      localStorage.removeItem("bearerToken");
      setBearerToken(null);
      setInfo(null);
      return null;
    }
  }, [env, apiUrls, setInfo]);

  const handleLogout = useCallback(async () => {
    try {
      const bearerToken = localStorage.getItem("bearerToken");
      await axios.post(
        `${apiUrls[env]}/v3/apiKey/logout`,
        {},
        { headers: { Authorization: `Bearer ${bearerToken}` } },
      );
    } catch (e) {
      // showErrorMessage("Failed to log out1. Please try again.");
    }
    await refreshAccountInfo();
    localStorage.removeItem("bearerToken");
    setBearerToken(null);
    clearChatHistory()
    navigate("/signin");
  }, [env, apiUrls, refreshAccountInfo, navigate]);
  
  useEffect(() => {
    // This effect handles wallet setup and refresh, but NOT the interval.
    (async () => {
      try {
        if (isLoading) return;

        if (!walletClient) {
          await refreshAccountInfo();
        } else {
          await axios.post(`${apiUrls[env]}/v3/apiKey/setbalance`, {
            address: walletClient?.account?.address,
          });
        }
  
        // Always refresh account info after
        await refreshAccountInfo();
      } catch (err) {
        showErrorMessage("Check your internet connection");
      }
    })();
  
    // No interval logic here.
  }, [walletClient, isLoading, isError]); // keep the deps you actually need
  
  
  useEffect(() => {
    if (intervalId) return;
  
    const iid = setInterval(async () => {
      await refreshAccountInfo("interval");
    }, 10000);
    setIntervalId(iid);
    // Cleanup on unmount
    return () => {
      if (iid) {
        console.log("Clearing interval...");
        clearInterval(iid);
      }
    };
    // We only want this to run once or if we specifically reset intervalId
  }, []);

  /**
   * The actual "associate wallet" logic: sign a message, call backend, refresh info.
   */
  async function associateWallet() {
    const bearerToken = localStorage.getItem("bearerToken");
    if (!bearerToken) {
      console.log("No bearer token; cannot associate wallet");
      return;
    }
    if(!walletClient) {
      console.log("No wallet client; cannot associate wallet");
      return
    }
    const provider = new ethers.BrowserProvider(walletClient);
    // Make sure we have a provider from the newly connected wallet
    if (!provider) {
      console.log("No wallet provider available; cannot associate wallet");
      return;
    }

    // Prompt the user to sign a message
    const signer = await provider.getSigner();
    const message = `Sign This Message to authenticate with the coincap API. ${Date.now()}`;
    const signedMessage = await signer.signMessage(message);

    const response = await axios.post(
      `${apiUrls[env]}/v3/apiKey/associate_wallet`,
      { originalMessage: message, signedMessage },
      { headers: { Authorization: `Bearer ${bearerToken}` } },
    );

    console.log("Wallet associated successfully:", response.data);
    // Refresh info to pick up new walletAddress
    await refreshAccountInfo();
  }

  // Inside useProvideGlobalState (GlobalStateProvider.tsx):
  const [waitingForAssociation, setWaitingForAssociation] = useState(false);

  const [waitingForStakeModal, setWaitingForStakeModal] = useState(false);

  const [isUnstake, setIsUnstake] = useState(false);

  useEffect(() => {

    async function doit() {
      // If we're waiting to associate AND we now have a connected wallet and are logged in,
      // call associateWallet.
      if (waitingForAssociation && walletClient) {
        await associateWallet().catch((e) => {
          console.log('e is', e.toString())
          if(e.toString()?.includes('user rejected action')) {
            showErrorMessage(`Error associating wallet: User rejected request`);
          } else {
            showErrorMessage(`This wallet is already associated with another account. Log out of your current account and log in again using this wallet to continue.`);
          }
        })
        setWaitingForAssociation(false);
      }
      if(waitingForStakeModal && walletClient && bearerToken) {
        setChainSelectionModalVisible(true);
        setStakeModalDetails({ amount: 1, isUnstake });

        setWaitingForStakeModal(false);
      }
    }

    doit()

  }, [waitingForAssociation, walletClient, waitingForStakeModal, bearerToken]);

  const connectAndAssociateWallet = async () => {
    console.log('connectAndAssociateWallet')
    openConnectModal?.();
    if (!walletClient?.account?.address) {
      // only try to associate if we dont already have a wallet address
      if(!info?.walletAddress) setWaitingForAssociation(true);
    } else {
      // If already connected, just call associate immediately
      try {
        // only try to associate if we dont already have a wallet address
        if(!info?.walletAddress)  await associateWallet()
        if(waitingForStakeModal) {
          setStakeModalDetails({ amount: 1, isUnstake });
          setChainSelectionModalVisible(true);
          setWaitingForStakeModal(false);
        }
        setWaitingForAssociation(false);
      } catch(e: any) {
        console.log('ERROR IS:', e.toString())
        if(e.toString()?.includes('user rejected action')) {
          showErrorMessage(`Error associating wallet: User rejected request`);
        } else {
          showErrorMessage(`This wallet is already associated with another account. Log out of your current account and log in again using this wallet to continue.`);
        }
        setWaitingForAssociation(false)
      }

    }
  };

  /**
   * The actual "register wallet" logic: sign a message, call backend, store bearerToken, refresh info.
   */
  async function registerWallet() {
    console.log('registerWallet0')
    if(!walletClient) {
      console.log("No wallet provider available; cannot register wallet");
      return;
    }
    const provider = new ethers.BrowserProvider(walletClient);

    // Prompt the user to sign a message
    const signer = await provider.getSigner();
    const message = `Sign This Message to register an account with the coincap API. ${Date.now()}`;
    const signedMessage = await signer.signMessage(message);

    // Hit the new endpoint
    const response = await axios.post(
      `${apiUrls[env]}/v3/apiKey/wallet_register`,
      { originalMessage: message, signedMessage },
    );

    console.log("Wallet registered successfully:", response.data);

    // The server might return a brand new bearerToken for our new user
    // If so, store that in localStorage
    if (response.data?.bearerToken) {
      localStorage.setItem("bearerToken", response.data.bearerToken);
      setBearerToken(response.data.bearerToken);
      migrateLocalChatHistory();
    }

    // Optionally, refresh info to pick up this brand new user’s data
    await refreshAccountInfo();
  }

  /**
   * Called when user clicks "Stake MOR OR UNSTAKE MOR" from anywhere in the app
   * ensures they are connected and associated and then shows the stake modal
   */
  const stakeMorAndConnectWallet = async (
    amount: number,
    isUnstake: boolean,
  ) => {
    setIsUnstake(isUnstake);
    console.log('stakeMorAndConnectWallet')
    setWaitingForStakeModal(true);
    const bearerToken = localStorage.getItem("bearerToken");
    if (!bearerToken) {
      // If not signed in, go to /signin
      navigate("/signin");
      return;
    }

    // If user doesn't have a wallet address yet, connect & associate
    if (!info?.walletAddress || !walletClient?.account?.address) {
      try {
        await connectAndAssociateWallet();
      } catch (e) {
        disconnect();
        showErrorMessage(
          "Current Wallet associated with a different account. You must connect a new wallet with this account before staking.",
        );
        return navigate("/settings");
      }
    } else {
      setStakeModalDetails({ amount, isUnstake });
      setChainSelectionModalVisible(true);
      setWaitingForStakeModal(false);
    }
  };

  const isSignedIn = () => info?.walletAddress || info?.email

  const updatePointsAndRewards = useCallback(async () => {
    if(isSignedIn()) {
      try {
        const bearerToken = localStorage.getItem("bearerToken");
        const response = await axios.post(
          `${apiUrls[env]}/v3/apiKey/getMorRewards`,
          {},
          { headers: { Authorization: `Bearer ${bearerToken}` } },
        );
        setMorRewards(response?.data?.rewards)
        setArbitrumRewards(response?.data?.arbitrumRewards)
        setBaseRewards(response?.data?.baseRewards)

        console.log('mor rewards response:', response?.data)
        console.log('about to set tp:', response?.data?.totalPoints)
        setTotalPoints(response?.data?.totalPoints)
        setRewardContractInfo({baseRewardContractInfo: response?.data?.baseRewardContractInfo, arbitrumRewardContractInfo: response?.data?.arbitrumRewardContractInfo})
      } catch(e) {
        console.log('ERROR GETTING MOR REWARDS: ', e)
      }
    }
  }, [info?.walletAddress, info?.email]);

  useEffect(() => {
    updatePointsAndRewards()
  }, [updatePointsAndRewards]);

  const { setTheme } = useTheme()
  useEffect( () => {
    setTheme('light')
  }, [])

  return {
    info,
    refreshAccountInfo,
    stakeMorAndConnectWallet,
    connectAndAssociateWallet,
    transactionDetails,
    setTransactionDetails,
    chatHistory,
    setChatHistory,
    selectedChatId,
    setSelectedChatId,
    messages,
    setMessages,
    registerWallet,
    isSignedIn,
    handleLogout,
    morRewards,
    bearerToken,
    setBearerToken,
    selectedChainId,
    setSelectedChainId,
    checkNetwork,
    arbitrumRewards,
    baseRewards,
    rewardContractInfo,
    isWithdrawRewards,
    setIsWithdrawRewards,
    totalPoints,
    updatePointsAndRewards
  };
}

export default useProvideGlobalState;
