import { defineRecipe } from "@chakra-ui/react";

export const buttonRecipe = defineRecipe({
  base: {
    borderRadius: 'xl',
    _currentPage: {
      fontWeight: 'bold'
    }
  },
  variants: {
    variant: {
      primary: {
        background: 'radial-gradient(147.94% 123.5% at 94.03% -28.85%, #FD9503 11.5%, #F56CA6 90.79%)',
        color: 'white',
        _hover: {
          background: 'radial-gradient(147.94% 123.5% at 94.03% -28.85%, #FD9503 11.5%, #F56CA6 90.79%)',
        }
      },
      secondary: {
        background: '#5061AC',
        color: 'white',
        _hover: {
          background: '#5061AC',
        }
      }
    }
  }
})